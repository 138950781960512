import React, { useState } from "react";
import "./datatable.scss";
import { Space, Table, Input, Button, Popover, message, Tag } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useNavigate } from "react-router-dom";

interface Contentdata {
  key?: string;
  _id: string;
  full_name?: string;
  email: string;
  user_type: string;
  department: string;
}
// interface DataTableProps {
//     setshowModel: (value: boolean) => void,
//     setEditable: (value: boolean) => void,
//     deleteThought: (value: any) => void,
//     editContent:(value:any)=>void
//     isOpen: boolean,
//     contentData?: Contentdata[]
// }
function DataTable({
  email,
  setshowModel,
  setEditable,
  isOpen,
  contentData,
  editContent,
  deleteuser,
}: any) {
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();
  const [pagination, setPagination] = useState<any>({ page: 1, pageSize: 5 });

  const columns: ColumnsType<Contentdata> = [
    {
      title: "ID",
      dataIndex: "_id",
      key: "_id",
      filteredValue: [searchText],
      width: 150,
      sorter: (a: any, b: any) => a._id.localeCompare(b._id),
      render: (text) => <div className="truncate">{text}</div>,
      onFilter: (value, record) => {
        return (
          String(record.email).toLowerCase().includes(value.toLocaleString()) ||
          String(record.user_type)
            .toLowerCase()
            .includes(value.toLocaleString()) ||
          String(record._id).toLowerCase().includes(value.toLocaleString())
        );
      },
    },
    {
      title: "Name",
      dataIndex: "full_name",
      width: 150,
      key: "full_name",
      render: (text) => <div className="truncate">{text}</div>,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 250,
      render: (text) => <div className="truncate">{text}</div>,
    },
    // {
    //   title: "User Type",
    //   dataIndex: "user_type",
    //   key: "user_type",
    //   render: (text) => <div className="truncate">{text}</div>,
    // },
    {
      title: "Department",
      dataIndex: "department",
      key: "department",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={() => navigate(`/editemployees?uid=${record.email}`)}
            className="btn_edit_table"
          >
            Edit
          </Button>
          <Popover
            content={
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <p>Please Double click to delete.</p>
                <Button
                  style={{
                    backgroundColor: "green",
                    width: "100%",
                    color: "#FFF",
                  }}
                  onDoubleClick={() => deleteuser(record.email)}
                >
                  Yes
                </Button>
              </div>
            }
            title="Please Double click to delete."
          >
            <Button className="btn_edit_table">Delete</Button>
          </Popover>
        </Space>
      ),
    },
  ];

  return (
    <div className="dataTable">
      <div className="filterMenu">
        <div></div>
        <Input.Search
          onSearch={(value) => {
            setSearchText(value);
          }}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
          className="searchField"
          placeholder="Search here..."
        />
      </div>
      <Table
        className="dataGrid"
        columns={columns}
        dataSource={contentData}
        tableLayout="fixed"
        // scroll={{ x: 1000 }}
        pagination={{
          pageSize: pagination?.pageSize,
          onChange(page, pageSize) {
            setPagination(pageSize);
          },
          showSizeChanger: true,
        }}
      />
    </div>
  );
}

export default DataTable;
