import React, { useEffect, useState } from "react";
import moment from "moment";
import { authSVC } from "../../services";
import { useB2BContext } from "../../context/ContextB2B";
import { Button, Form, Input, message, DatePicker } from "antd";
import authstyle from "../../styles/auth.module.scss";
import type { DatePickerProps } from "antd";

const { TextArea } = Input;

function PaymentStatusPopup() {
  const { setAgreementId, getUserByID } = useB2BContext();

  return (
    <>
      <div className="payment_status_wrapper">
        <img src="/img/Group19356.png" alt="" width="100%" height="240px" />
        <div className="thank_you_content">
          <h2>THANK YOU FOR YOUR BUSINESS!</h2>
          <p>
            Your payment has been successfully received, and an invoice will be
            sent to your registered email address shortly. Your package
            activation is in progress, and we will notify you as soon as it is
            ready. Thank you for your patience!
          </p>
          <p className="help">
            Need help? We are here for you!{" "}
            <a
              href="https://www.soulsara.world/contact-us"
              target="_blank"
              rel="noreferrer"
            >
              contact us
            </a>{" "}
          </p>
        </div>
      </div>
    </>
  );
}

export default PaymentStatusPopup;
