import React, { useState } from "react";
import DataTable from "../../components/dataTable/DataTable";
import { Button, Typography, Modal, Input, message } from "antd";
import { timesSVC } from "../../services";
import "react-quill/dist/quill.snow.css";
import useSWR from "swr";
import axios from "axios";
import * as Cookie from "universal-cookie";

const cookie = new Cookie.default();
const cachedToken = cookie.get("userAuthTokenAdmin");

const { Text } = Typography;
const { TextArea } = Input;

interface Contentdata {
  id: number;
  content: string;
}

const fetcher = (url: any) =>
  axios
    .get(url, {
      headers: {
        Authorization: `Bearer ${cachedToken}`,
        "Content-Type": "application/json",
      },
    })
    .then((res) => res?.data);

function ThoughtForweek() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [editContent, setEditContent] = useState<any>({ id: "", content: "" });
  // const [contentData, setContentData] = useState<Contentdata[]>([]);
  const [loading, setloading] = useState<boolean>(false);
  const [btnDisable, setBtnDisable] = useState(false);
  const API_URL = process.env.REACT_APP_API_URL;

  console.log(cachedToken, "saaaa");

  const { data: contentData, mutate: getThoughts } = useSWR(
    API_URL + "/times/thoughts",
    fetcher,
    {
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
    }
  );

  // const getThoughts = async () => {
  //   timesSVC
  //     .getThoughts()
  //     .then((res: any) => {
  //       setContentData(res.data);
  //     })
  //     .catch((err: any) => {
  //       message.error(err.message);
  //     });
  // };

  // useEffect(() => {
  //   getThoughts();
  // }, []);

  const deleteThought = (id: string) => {
    alert();
    timesSVC
      .deleteThoughts(id)
      .then((res: any) => {
        message.success("Deleted Successfully");
        getThoughts();
      })
      .catch((err: any) => {
        // message.error(err.message);
      });
    // message.success("Can't Delete")
  };

  const updateThought = (id: string) => {
    setloading(true);
    timesSVC
      .updateThoughts(id, {
        content: editContent.content,
      })
      .then((res: any) => {
        setloading(false);
        getThoughts();
        message.success("Thought Updated Successfully");
        handleCancel();
      })
      .catch((err: any) => {
        message.error(err.message);
        setloading(false);
      });
  };
  const addThought = () => {
    setloading(true);
    timesSVC
      .addThoughts({
        content: editContent.content,
      })
      .then((res: any) => {
        setloading(false);
        getThoughts();
        message.success("Thought Added Successfully");
        setEditContent({ id: "", content: "" });
        handleCancel();
      })
      .catch((err: any) => {
        message.error(err.message);
        setloading(false);
      });
  };
  const editThought = (id: string) => {
    timesSVC
      .editThoughts(id)
      .then((res: any) => {
        setEditContent({ id: res.data.id, content: res.data.content });
      })
      .catch((err: any) => {
        message.error(err.message);
      });
  };

  const showModal = () => {
    setIsModalOpen(true);
    setIsEditable(false);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setEditContent({ id: "", content: "" });
    setloading(false);
  };
  const handleInputChange = (e: any) => {
    setBtnDisable(false);
    setEditContent((prevData: any) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <div className="contentBox">
      <div className="info">
        <Text className="title24">Thought For The Week</Text>
      </div>

      <DataTable
        deleteThought={deleteThought}
        editContent={editThought}
        setshowModel={setIsModalOpen}
        setEditable={setIsEditable}
        isOpen={isModalOpen}
        contentData={contentData}
        showModal={showModal}
      />

      <Modal
        footer={false}
        title={isEditable === true ? "Update  Thought" : "Add Thought"}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        {/* <ReactQuill
          value={editContent.content}
          onChange={handleInputChange}
          modules={{
            toolbar: [
              // [{ 'header': '1' }, { 'header': '2' }],
              ['bold', 'italic', 'underline', 'strike', 'blockquote'],
              [{ 'list': 'ordered' }, { 'list': 'bullet' }],
              ['link', 'image'],
              ['clean'],
            ],
          }}
        /> */}

        <TextArea
          // showCount
          name="content"
          maxLength={200}
          onChange={handleInputChange}
          size={"large"}
          value={editContent.content}
          placeholder={"Write here..."}
        />

        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            onClick={() => {
              if (editContent.content === "") {
                message.info("Please write a thought");
                setBtnDisable(true);
              } else {
                if (isEditable === true) {
                  updateThought(editContent.id);
                } else {
                  addThought();
                }
              }
            }}
            disabled={btnDisable ? true : false}
            className="button"
            loading={loading}
          >
            {isEditable === true ? "Update Thought" : "Add Thought"}
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default ThoughtForweek;
