import React, { useEffect, useState } from "react";
import {
  Button,
  Typography,
  Modal,
  Input,
  message,
  Select,
  Form,
  Image,
} from "antd";
import { authSVC, categorySVC } from "../../services";
import { Option } from "antd/es/mentions";
import { useB2BContext } from "../../context/ContextB2B";
import { useNavigate, useLocation } from "react-router-dom";

const { Text } = Typography;

interface Contentdata {
  _id: string;
  full_name: string;
  email: string;
  user_type: string;
  company: string;
  department: string;
}

const API_PATH = process.env.REACT_APP_API_URL;

function EditEmployees() {
  const [form] = Form.useForm();

  const { getUserByID } = useB2BContext();
  const [editContent, setEditContent] = useState<Contentdata>({
    _id: "",
    full_name: "",
    email: "",
    user_type: "",
    company: "",
    department: "",
  });
  const [contentData, setContentData] = useState<any[]>([]);
  const [loading, setloading] = useState<boolean>(false);
  const [disableAddBtn, setDisableAddBtn] = useState(false);
  const [disableAddEmp, setDisableAddEmp] = useState(false);
  const [userPackage, setUserPackage] = useState("");
  const [isLoding, setIsLoding] = useState(false);
  const [allDepartments, setAllDepartments] = useState<any>();
  const [csvFile, setCSVFile] = useState<any>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const editUserEmail = queryParams.get("uid");

  useEffect(() => {
    console.log(allDepartments, "allDepartments");
  }, [allDepartments]);

  useEffect(() => {
    getDepartments(getUserByID);
  }, [getUserByID]);

  const getDepartments = (getUserByID: any) => {
    if (getUserByID?.company) {
      authSVC
        .getCompanyDepartments(getUserByID?.company)
        .then((res: any) => {
          // message.success("Added Successfully");
          // setContentData(res.data?.data?.data);
          setAllDepartments(res.data);
          // if(res.data.data)
        })
        .catch((err: any) => {
          message.error(err.message);
        });
    }
  };

  useEffect(() => {
    // console.log(getUserByID, "jjjj");
    setUserPackage(getUserByID?.package_name);
  }, [getUserByID]);

  useEffect(() => {
    if (getUserByID?.email) {
      authSVC
        .getCompanyuser({ email: getUserByID?.email })
        .then((res: any) => {
          setContentData(res.data?.data?.data);
          res.data?.data?.data.map((data: any) => {
            form.setFields([
              { name: "full_name", value: data?.full_name },
              { name: "user_type", value: data?.department },
            ]);
          });

          // form.setFields([
          //   { name: "full_name", value: editDetails?.full_name },
          //   { name: "department", value: editDetails?.department },
          // ]);
        })
        .catch((err: any) => {
          message.error(err.message);
        });
    }
  }, [getUserByID?.email]);

  const editEmployees = () => {
    setloading(true);
    authSVC
      .updateUser(editUserEmail, {
        full_name: editContent.full_name,
        department: editContent?.department,
      })
      .then((res: any) => {
        setloading(false);
        message.success("User Successfully Updated");
        form.resetFields();
        navigate("/manage_exployees");
      })
      .catch((err: any) => {
        message.error(err.response?.data?.error);
        setloading(false);
      });
  };

  const handleInputChange = (e: any) => {
    setEditContent((prevData: any) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSelectedItem = (value: any) => {
    setEditContent((prevData: any) => ({
      ...prevData,
      user_type: value,
    }));
  };

  const handleSelectedDepratment = (value: any) => {
    setEditContent((prevData: any) => ({
      ...prevData,
      department: value,
    }));
  };

  const filteredContents = contentData?.filter((item: any) => {
    return item?.email === getUserByID?.email ? null : item;
  });

  useEffect(() => {
    if (filteredContents.length === 10 && userPackage === "Gold") {
      setDisableAddBtn(true);
    } else if (filteredContents.length === 100 && userPackage === "Diamond") {
      setDisableAddBtn(true);
    } else if (filteredContents.length >= 100 && userPackage === "Platinum") {
      setDisableAddBtn(true);
    } else {
      setDisableAddBtn(false);
    }
  }, [filteredContents, userPackage]);

  useEffect(() => {
    if (allDepartments?.message?.length === 0) {
      setDisableAddEmp(true);
    } else {
      setDisableAddEmp(false);
    }
  }, [allDepartments]);

  const onFinish = () => {
    editEmployees();
  };

  const nameValidation = (_: any, value: any) => {
    const regex = /^[A-Za-z\s]+$/;

    if (!value || regex.test(value)) {
      return Promise.resolve();
    }

    return Promise.reject(
      new Error("Please enter a valid name with only letters.")
    );
  };

  return (
    <div className="add_form_wrapper">
      <h1>Edit Employee</h1>
      <Form
        name="normal_login"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        form={form}
      >
        <Form.Item
          name="full_name"
          rules={[
            {
              required: true,
              message: "Please Enter Employee Full Name!",
            },
            {
              validator: nameValidation,
            },
          ]}
          style={{ marginBottom: "16px" }}
        >
          <Input
            name="full_name"
            className="input"
            value={editContent?.full_name}
            onChange={handleInputChange}
            type="text"
            placeholder="Please Enter Employee Full Name"
          />
        </Form.Item>

        <Form.Item style={{ marginBottom: "16px" }} name={"user_type"}>
          <Select
            style={{ width: "100%", borderRadius: "8px" }}
            placeholder="Please Select The Department"
            optionFilterProp="children"
            onChange={handleSelectedDepratment}
          >
            {allDepartments?.data?.map((item: any, index: any) => {
              return (
                <Option value={item.name} key={index}>
                  {item.name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        <Button
          className="button_add_emp"
          htmlType="submit"
          loading={loading}
          disabled={disableAddEmp ? true : false}
          icon={<Image src="/img/new/add.svg" alt="" />}
        >
          Update
        </Button>
      </Form>
    </div>
  );
}

export default EditEmployees;
