import React, { useEffect, useState } from "react";
import Text from "antd/lib/typography/Text";
import { Button, Image, Progress } from "antd";
import PieChart from "../../components/PieChart";
import styles from "../../styles/dashboard.module.scss";
import overview from "../../data/overview.json";
import barchart from "../../data/barchart.json";
import departmentsProgress from "../../data/department.json";
import piechart from "../../data/piechart.json";
import BarChart from "../../components/StackedBar";
import BarChartHappiness from "../../components/StackedBarHappiness";
import PieChartHappiness from "../../components/PieChartHappiness";
import { useB2BContext } from "../../context/ContextB2B";
import moment from "moment";
import { authSVC } from "../../services";
import { useNavigate } from "react-router-dom";

const levelsData = [
  {
    level: 0,
    color: "#FFDAEE",
  },
  {
    level: 1,
    color: "#FCD6E9",
  },
  {
    level: 2,
    color: "#F9BCDC",
  },
  {
    level: 3,
    color: "#F69FCC",
  },
  {
    level: 4,
    color: "#F168B1",
  },
  {
    level: 5,
    color: "#F04BA1",
  },
  {
    level: 6,
    color: "#EC3192",
  },
  {
    level: 7,
    color: "#EB1481",
  },
  {
    level: 8,
    color: "#CE1273",
  },
];
function InsightsSingle() {
  // const [tapIndex1, setTapIndex1] = useState<number>(0);
  // const [tapIndex2, setTapIndex2] = useState<number>(0);
  // const [tapIndex3, setTapIndex3] = useState<number>(0);
  const [pieChartData, setpieChartData] = useState<any[]>([]);
  const [departmentLevel, setdepartmentLevel] = useState<any[]>([]);
  const [overallLevel, setoverallLevel] = useState<any>();
  const { getUserByID } = useB2BContext();
  const navigate = useNavigate();

  useEffect(() => {
    console.log(overallLevel, "overallLevel");
    console.log(getUserByID._id, "getUserByID");
    // alert("dd");
  }, [overallLevel, getUserByID]);

  useEffect(() => {
    const month: any = moment().format("M");
    const year: any = moment().format("yyyy");
    if (getUserByID) {
      console.log(getUserByID, "getUserByID");
      authSVC
        .getOverallWellnesspieChartLevels(getUserByID?.company, Number(month))
        .then((res) => {
          setpieChartData(res?.data?.data);
        })
        .catch((err) => {
          console.log(err);
        });

      authSVC
        .getDepartmentLevels(getUserByID?.company, Number(month), Number(year))
        .then((res) => {
          setdepartmentLevel(res?.data?.data);
        })
        .catch((err) => {
          console.log(err);
        });

      authSVC
        .getoverAllViewLevels(getUserByID?.company, Number(month), Number(year))
        .then((res) => {
          setoverallLevel(res?.data?.data);
          console.log(res?.data?.data, "ddd");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [getUserByID]);

  const pieChartWellness = pieChartData?.filter(
    (item: any) => item?.type === "wellness"
  );

  const pieChartHappiness = pieChartData?.filter(
    (item: any) => item?.type === "happiness"
  );
  const barChartWellness = barchart?.filter(
    (item) => item?.type === "wellness"
  );

  const barChartHappiness = barchart?.filter(
    (item) => item?.type === "happiness"
  );

  return (
    <div className={styles.wellness_report_main_wrapper}>
      <div className={"row"}>
        <Image
          src="/img/new/back_arrow.svg"
          preview={false}
          onClick={() => navigate("/insights")}
        />
        <p>Test</p>
      </div>

      <div className={styles.col_2_div}>
        {/* Left Container */}
        <div className={styles.data_container}>
          <div className={styles.white_bg_wrapper}>
            <div className={styles.info_title}>
              <Text className={styles.title_profile}>
                WELLNESS<span>PROFILE</span> Engagement
              </Text>
              <div className={styles.title_grp1}>
                <Image
                  className={styles.img}
                  src="/img/new/download_icon.svg"
                  preview={false}
                />
                {/* <Text className={styles.subtitle1}>21% from last month</Text> */}
              </div>
            </div>
            <div className={styles.engagement_info}>
              <p className={styles.title}>
                {overallLevel?.wpengagement_percentage}%
              </p>
              <div className={styles.subtitle}>
                <Progress
                  strokeColor={"#9EC756"}
                  trailColor="#FDECED"
                  percent={overallLevel?.wpengagement_percentage}
                  status="active"
                  showInfo={false}
                />
              </div>
            </div>
          </div>
        </div>
        {/* Right Container */}
        {/* <div className={styles.data_container}>
          <div className={styles.white_bg_wrapper}>
            <div className={styles.info_title}>
              <Text className={styles.title}>Summary</Text>
              <div className={styles.summery}>
                <p>
                  {overallLevel?.summary_report}
                </p>
              </div>
            </div>
          </div>
        </div> */}
      </div>
      <div className={styles.border}></div>
      <div className={styles.col_2_div}>
        {/* Left Container */}
        <div className={styles.data_container}>
          <div className={styles.white_bg_wrapper}>
            <div className={styles.info_title}>
              <div className={styles.title_grp}>
                <Text className={styles.title}>Wellness Index</Text>
              </div>
              <div className={styles.title_grp1}>
                <Image
                  className={styles.img}
                  src="/img/new/download_icon.svg"
                  preview={false}
                />
                {/* <Text className={styles.subtitle1}>21% from last month</Text> */}
              </div>
              <div className={styles.graph}>
                <BarChart chartData={barChartWellness} />
              </div>
            </div>
          </div>
        </div>
        {/* Right Container */}
        <div className={styles.data_container}>
          <div className={styles.white_bg_wrapper}>
            <div className={styles.info_title}>
              <div className={styles.title_grp}>
                <Text className={styles.title}>Happiness Index</Text>
              </div>
              <div className={styles.title_grp1}>
                <Image
                  className={styles.img}
                  src="/img/new/download_icon.svg"
                  preview={false}
                />
                {/* <Text className={styles.subtitle1}>21% from last month</Text> */}
              </div>
              <div className={styles.graph}>
                <BarChartHappiness chartData={barChartHappiness} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InsightsSingle;
