import React, { useEffect, useState } from "react";
import Text from "antd/lib/typography/Text";
import { Button, Image, Progress } from "antd";
import styles from "../../styles/dashboard.module.scss";
import { useNavigate } from "react-router-dom";
import { useB2BContext } from "../../context/ContextB2B";
import { authSVC } from "../../services";
import moment from "moment";

export default function DepartmentHappinessIndex() {
  const navigate = useNavigate();
  const { getUserByID } = useB2BContext();

  const [departmentHappinessLevel, setdepartmentHappinessLevel] = useState<
    any[]
  >([]);

  useEffect(() => {
    const year: any = moment().format("yyyy");
    const month: any = moment().format("M");
    authSVC
      .getDepartmentHappinessLevels(
        getUserByID?.company,
        Number(month),
        Number(year)
      )
      .then((res) => {
        setdepartmentHappinessLevel(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [getUserByID]);
  return (
    <div className={styles.white_bg_wrapper}>
      <div className={styles.info_title}>
        <div className={styles.title_grp}>
          <Text className={styles.title}>Happiness Index</Text>
        </div>
      </div>
      <div className={styles.content_box}>
        {departmentHappinessLevel?.map((item: any, index: number) => {
          return item?.type === "happiness" ? (
            <div
              className={styles.listTitleWrapper}
              key={index}
              onClick={() => navigate("/insights_single")}
            >
              <div className={styles.listTitle}>
                <div className={styles.titleInfo}>
                  <div
                    style={{ backgroundColor: item?.departmentColor }}
                    className={styles.round}
                  ></div>
                  <Text className={styles.title}>{item?.department}</Text>
                </div>
                <Image
                  className={styles.img}
                  src="/img/new/arrow_forward1.svg"
                  preview={false}
                />
              </div>
              <div className={styles.listProgress}>
                <Text className={styles.title}>
                  {item?.percentage?.toFixed(1)}%
                </Text>
                <Progress
                  strokeColor={item?.departmentColor}
                  trailColor="#FDECED"
                  percent={item?.percentage}
                  status="active"
                  showInfo={false}
                />
              </div>
            </div>
          ) : (
            ""
          );
        })}
      </div>
    </div>
  );
}
