import React, { useEffect, useState } from "react";
import "./newsonwellness.scss";
import {
  Button,
  Typography,
  Modal,
  Input,
  message,
  Form,
  DatePicker,
  DatePickerProps,
} from "antd";
import DataTableOtherContent from "../../components/dataTable/DataTableOtherContent";
import { otherContentsSVC } from "../../services";
import ImageUploader from "./ImageUploader";
import ReactQuill from "react-quill";
import moment from "moment";
import dayjs from "dayjs";

const { Text } = Typography;

interface Contentdata {
  key?: string;
  id: number;
  title: string;
  type: string;
  contend: string;
  link: string;
  image: string;
  sheduledDate: string;
}

function NewsonWellness() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [editContent, setEditContent] = useState<any>({
    id: "",
    title: "",
    contend: "",
    link: "",
    image: "",
    sheduledDate: null,
  });
  const [contentData, setContentData] = useState<Contentdata[]>([]);
  const [uploadedImage, setUploadedImage] = useState<any>(null);
  const [loading, setloading] = useState<boolean>(false);
  const [btnDisable, setBtnDisable] = useState(false);

  const getOtherContent = async () => {
    otherContentsSVC
      .getOtherContent()
      .then((res: any) => {
        setContentData(res.data);
      })
      .catch((err: any) => {
        message.error(err.message);
      });
  };

  const filteredData = contentData.filter(
    (item) => item.type === "newsonwellness"
  );
  useEffect(() => {
    getOtherContent();
  }, []);

  const deleteOtherContent = (id: string) => {
    otherContentsSVC
      .deleteOtherContent(id)
      .then((res: any) => {
        getOtherContent();
        message.success("Deleted Successfully");
      })
      .catch((err: any) => {
        message.error(err.message);
      });
  };

  const updateOtherContent = (id: string) => {
    setloading(true);
    const formData: any = new FormData();
    formData.append("title", editContent.title);
    formData.append("type", "newsonwellness");
    formData.append("contend", editContent.contend);
    formData.append("link", editContent.link);
    formData.append("image", editContent.image);
    if (editContent.sheduledDate !== null) {
      formData.append("sheduledDate", editContent.sheduledDate);
    } else {
      formData.append("sheduledDate", moment().format("YYYY-MM-DD"));
    }
    otherContentsSVC
      .updateOtherContent(id, formData)
      .then((res: any) => {
        setloading(false);
        getOtherContent();
        message.success("Updated Successfully");
        handleCancel();
      })
      .catch((err: any) => {
        message.error(err.message);
        setloading(false);
      });
  };
  const addOtherContent = () => {
    setloading(true);
    const formData: any = new FormData();
    formData.append("title", editContent.title);
    formData.append("type", "newsonwellness");
    formData.append("contend", editContent.contend);
    formData.append("link", editContent.link);
    formData.append("image", editContent.image);
    if (editContent.sheduledDate !== null) {
      formData.append("sheduledDate", editContent.sheduledDate);
    } else {
      formData.append("sheduledDate", moment().format("YYYY-MM-DD"));
    }
    otherContentsSVC
      .addOtherContent(formData)
      .then((res: any) => {
        setloading(false);
        getOtherContent();
        message.success("Added Successfully");
        setEditContent({
          id: "",
          title: "",
          image: "",
          contend: "",
          link: "",
          sheduledDate: null,
        });
        setUploadedImage(null);
        handleCancel();
      })
      .catch((err: any) => {
        message.error(err.message);
        setloading(false);
      });
  };
  const editOtherContent = (id: string) => {
    otherContentsSVC
      .editOtherContent(id)
      .then((res: any) => {
        setEditContent({
          id: res.data.id,
          title: res.data.title,
          contend: res.data.contend,
          link: res.data.link,
          image: res.data.image,
          sheduledDate: res.data.sheduledDate,
        });
      })
      .catch((err: any) => {
        message.error(err.message);
      });
  };

  const showModal = () => {
    setIsModalOpen(true);
    setIsEditable(false);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setEditContent({
      id: "",
      title: "",
      image: "",
      contend: "",
      link: "",
      sheduledDate: null,
    });
    setUploadedImage(null);
    setloading(false);
  };

  const handleFileUpload = async (event: any) => {
    event.preventDefault();
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setUploadedImage(reader.result);
    };
    setBtnDisable(false);
    setEditContent((preData: any) => ({
      ...preData,
      image: file,
    }));

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleInputChange = (event: any) => {
    setBtnDisable(false);
    const { name, value } = event.target;
    setEditContent((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleDescription = (value: any) => {
    setBtnDisable(false);
    setEditContent((prevData: any) => ({
      ...prevData,
      contend: value,
    }));
  };
  const onChange: DatePickerProps["onChange"] = (date, dateString) => {
    setBtnDisable(false);
    setEditContent((prevData: any) => ({
      ...prevData,
      sheduledDate: date,
    }));
  };
  return (
    <div className="contentBox">
      <div className="info">
        <Text className="title24">News On Wellness</Text>
      </div>
      <DataTableOtherContent
        deleteOtherContent={deleteOtherContent}
        editContent={editOtherContent}
        setshowModel={setIsModalOpen}
        setEditable={setIsEditable}
        isOpen={isModalOpen}
        contentData={filteredData}
      />
      <Modal
        footer={false}
        title={
          isEditable === true
            ? "Update  News On Wellness"
            : "Add News On Wellness"
        }
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Input
          className="input"
          name="title"
          onChange={handleInputChange}
          size={"large"}
          style={{ marginBottom: "16px" }}
          value={editContent.title}
          placeholder={"Enter Title"}
        />
        {editContent.title === "" ? null : (
          <>
            <DatePicker
              value={
                editContent.sheduledDate
                  ? dayjs(editContent.sheduledDate)
                  : null
              }
              disabledDate={(current) => {
                return current && current < moment().startOf("day");
              }}
              style={{ width: "100%", marginBottom: "16px" }}
              onChange={onChange}
            />
          </>
        )}
        {editContent.title === "" ? null : (
          <>
            <ReactQuill
              value={editContent.contend}
              onChange={handleDescription}
              modules={{
                toolbar: [
                  // [{ 'header': '1' }, { 'header': '2' }],
                  ["bold", "italic", "underline", "strike", "blockquote"],
                  [{ list: "ordered" }, { list: "bullet" }],
                  ["link"],
                  ["clean"],
                ],
              }}
              style={{ marginBottom: "16px" }}
            />
          </>
        )}
        {editContent.title === "" ? null : (
          <>
            <Input
              type="url"
              name="link"
              onChange={handleInputChange}
              size={"large"}
              style={{ marginBottom: "16px" }}
              value={editContent.link}
              placeholder={"Enter Link"}
            />
          </>
        )}
        {editContent.link === "" ? null : (
          <>
            <ImageUploader
              image={editContent?.image}
              uploadedImage={uploadedImage}
              handleFileUpload={handleFileUpload}
            />
          </>
        )}
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            onClick={() => {
              if (editContent.title === "") {
                setBtnDisable(true);
                message.info("Please Enter Title");
              } else if (editContent.sheduledDate === null) {
                setBtnDisable(true);
                message.info("Please Enter Date");
              } else if (editContent.contend === "") {
                setBtnDisable(true);
                message.info("Please Enter Content");
              } else if (editContent.link === "") {
                setBtnDisable(true);
                message.info("Please Enter link");
              } else {
                if (isEditable === true) {
                  updateOtherContent(editContent.id ?? "");
                } else {
                  if (editContent.image === "") {
                    setBtnDisable(true);
                    message.info("Please upload a image");
                  } else {
                    addOtherContent();
                  }
                }
              }
            }}
            style={{
              marginTop: "16px",
            }}
            className="button"
            loading={loading}
            disabled={btnDisable ? true : false}
          >
            {isEditable === true ? "Update " : "Add"}
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default NewsonWellness;
