import React, { useEffect, useState } from "react";
import Text from "antd/lib/typography/Text";
import { Button, Image, Progress } from "antd";
import styles from "../../styles/dashboard.module.scss";
import { useNavigate } from "react-router-dom";
import { useB2BContext } from "../../context/ContextB2B";
import moment from "moment";
import { authSVC } from "../../services";

export default function DepartmentsWellnessProgressBar() {
  const navigate = useNavigate();
  const { getUserByID } = useB2BContext();

  const [departmentWellnessLevel, setdepartmentWellnessLevel] = useState<any[]>(
    []
  );

  useEffect(() => {
    const month: any = moment().format("M");
    const year: any = moment().format("yyyy");
    if (getUserByID) {
      authSVC
        .getDepartmentLevels(getUserByID?.company, Number(month), Number(year))
        .then((res) => {
          setdepartmentWellnessLevel(res?.data?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [getUserByID]);

  return (
    <div className={styles.white_bg_wrapper}>
      <div className={styles.info_title}>
        <div className={styles.title_grp}>
          <Text className={styles.title}>Wellness Index</Text>
        </div>
      </div>
      <div className={styles.content_box}>
        {departmentWellnessLevel?.map((item: any, index: number) => {
          return item?.type === "wellness" ? (
            <div
              className={styles.listTitleWrapper}
              key={index}
              onClick={() => navigate("/insights_single")}
            >
              <div className={styles.listTitle}>
                <div className={styles.titleInfo}>
                  <div
                    style={{ backgroundColor: item?.departmentColor }}
                    className={styles.round}
                  ></div>
                  <Text className={styles.title}>{item?.department}</Text>
                </div>
                <Image
                  className={styles.img}
                  src="/img/new/arrow_forward1.svg"
                  preview={false}
                />
              </div>
              <div className={styles.listProgress}>
                <Text className={styles.title}>
                  {item?.percentage?.toFixed(1)}%
                </Text>
                <Progress
                  strokeColor={item?.departmentColor}
                  trailColor="#FDECED"
                  percent={item?.percentage}
                  status="active"
                  showInfo={false}
                />
              </div>
            </div>
          ) : (
            ""
          );
        })}
      </div>
    </div>
  );
}
