import React, { useState } from "react";
import "./datatable.scss";
import { Space, Table, Input, Button, Image, Tag, Popover } from "antd";
import type { ColumnsType } from "antd/es/table";
import moment from "moment";

interface Contentdata {
  key?: string;
  id: number;
  title?: string;
  image?: string;
  label?: string;
  link?: string;
  sheduledDate?: any;
}

interface DataTableProps {
  setshowModel: (value: boolean) => void;
  setEditable: (value: boolean) => void;
  deleteFeatured: (value: any) => void;
  editContent: (value: any) => void;
  isOpen: boolean;
  contentData: Contentdata[];
  showModal: any;
}
function DataTableFeatured({
  setshowModel,
  setEditable,
  isOpen,
  contentData,
  deleteFeatured,
  editContent,
  showModal,
}: DataTableProps) {
  const [searchText, setSearchText] = useState("");
  const [pagination, setPagination] = useState<any>({ page: 1, pageSize: 5 });

  const columns: ColumnsType<Contentdata> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 100,
      filteredValue: [searchText],
      defaultSortOrder: "descend",
      sorter: (a, b) => a.id - b.id,
      onFilter: (value, record) => {
        return (
          String(record.id).toLowerCase().includes(value.toLocaleString()) ||
          String(record.title).toLowerCase().includes(value.toLocaleString()) ||
          String(record.label).toLowerCase().includes(value.toLocaleString())
        );
      },
    },
    {
      title: "Title",
      dataIndex: "label",
      key: "label",
      width: 250,
      render: (text) => (
        <>
          <p dangerouslySetInnerHTML={{ __html: text.slice(0, 100) }} />
        </>
      ),
    },
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      render: (_, record) => (
        <Space size="middle">
          <Image src={record.image} width={60} height={60} alt={"image"} />
        </Space>
      ),
    },
    {
      title: "Label",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Link",
      dataIndex: "link",
      key: "link",
      render: (_, record) => (
        <Space size="middle">
          <a
            href={record.link}
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "underline", color: "#1677ff" }}
          >
            Click
          </a>
        </Space>
      ),
    },
    {
      title: "Status",
      dataIndex: "sheduledDate",
      key: "sheduledDate",
      render: (_, record) => (
        <Space size="middle">
          {record.sheduledDate === null ? (
            <Tag color={"green"}>Published</Tag>
          ) : moment().format("YYYY-MM-DD") >= record.sheduledDate ? (
            <Tag color={"green"}>Published</Tag>
          ) : (
            <Tag color={"red"}>Not Published</Tag>
          )}
        </Space>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={() => {
              setshowModel(!isOpen);
              setEditable(true);
              editContent(record.id);
            }}
            className="btn_edit_table"
          >
            Edit
          </Button>
          <Popover
            content={
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "10px",
                }}
              >
                <Button
                  style={{
                    backgroundColor: "green",
                    width: "100%",
                    color: "#fff",
                  }}
                  onDoubleClick={() => deleteFeatured(record.id)}
                >
                  Yes
                </Button>
              </div>
            }
            title="Please Double click to delete."
          >
            <Button className="btn_edit_table">Delete</Button>
          </Popover>
        </Space>
      ),
    },
  ];

  return (
    <div className="dataTable">
      <div className="filterMenu">
        <Button onClick={showModal} className="add_button">
          Add Featured Items
        </Button>
        <Input.Search
          onSearch={(value) => {
            setSearchText(value);
          }}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
          className="searchField"
          placeholder="Search here..."
        />
      </div>
      <Table
        scroll={{ y: 500 }}
        className="dataGrid"
        columns={columns}
        dataSource={contentData}
        pagination={{
          pageSize: pagination?.pageSize,
          onChange(page, pageSize) {
            setPagination(pageSize);
          },
          showSizeChanger: true,
        }}
      />
    </div>
  );
}

export default DataTableFeatured;
