import React, { useEffect, useState } from "react";
import "./article.scss";
import { Select } from "antd";
import { Button, Typography, Modal, Input, message, Form, Image } from "antd";
import DataTableArticle from "../../components/dataTable/DataTableArticle";
import { articlesSVC } from "../../services";
import ImageUploader from "./ImageUploader";
import ReactQuill from "react-quill";
import useSWR from 'swr';
import axios from 'axios';
import * as Cookie from "universal-cookie";

const cookie = new Cookie.default();
const cachedToken = cookie.get("userAuthTokenAdmin");

const { Text } = Typography;

interface Contentdata {
  key?: string;
  id: string;
  title: string;
  image: string;
  content: string;
  hash: string;
  themeId: string;
}
interface Theme {
  id: string;
  name: string;
}

const fetcher = (url: any) => axios.get(url, {
  headers: {
    'Authorization': `Bearer ${cachedToken}`,
    'Content-Type': 'application/json'
  }
}).then(res => res?.data);

function Article() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [editContent, setEditContent] = useState<Contentdata>({
    id: "",
    title: "",
    image: "",
    content: "",
    hash: "",
    themeId: "",
  });
  // const [contentData, setContentData] = useState<Contentdata[]>([]);
  // const [themes, setthemes] = useState<Theme[]>([]);
  const [uploadedImage, setUploadedImage] = useState<any>(null);
  const [loading, setloading] = useState<boolean>(false);
  const [btnDisable, setBtnDisable] = useState(false);

  const API_URL = process.env.REACT_APP_API_URL;

  const { data: contentData, mutate: getOtherContent } = useSWR(API_URL + '/themeposts', fetcher, {
    revalidateOnFocus: true,
    revalidateOnReconnect: true
  });

  const { data: themes, mutate: getThemes } = useSWR(API_URL + '/themes/', fetcher, {
    revalidateOnFocus: true,
    revalidateOnReconnect: true
  });

  // const getOtherContent = async () => {
  //   articlesSVC
  //     .getThemeposts()
  //     .then((res: any) => {
  //       setContentData(res.data);
  //     })
  //     .catch((err: any) => {
  //       message.error(err.message);
  //     });
  // };

  // const getThemes = async () => {
  //   articlesSVC
  //     .getthemes()
  //     .then((res: any) => {
  //       setthemes(res.data);
  //     })
  //     .catch((err: any) => {
  //       message.error(err.message);
  //     });
  // };

  // useEffect(() => {
  //   getOtherContent();
  //   getThemes();
  // }, []);

  const deleteArticle = (id: string) => {
    articlesSVC
      .deleteThemeposts(id)
      .then((res: any) => {
        getOtherContent();
        getThemes();
        message.success("Deleted Successfully");
      })
      .catch((err: any) => {
        message.error(err.message);
      });
  };

  const updateArticle = (id: string) => {
    setloading(true);
    const formData: any = new FormData();
    formData.append("title", editContent.title);
    formData.append("image", editContent.image);
    formData.append("content", editContent.content);
    formData.append("hash", editContent.hash);
    formData.append("themeId", editContent.themeId);

    articlesSVC
      .updateThemeposts(id, formData)
      .then((res: any) => {
        setloading(false);
        getOtherContent();
        getThemes();
        message.success("Updated Successfully");
        handleCancel();
      })
      .catch((err: any) => {
        message.error(err.message);
        setloading(false);
      });
  };
  const addArticle = () => {
    setloading(true);
    const formData: any = new FormData();
    formData.append("title", editContent.title);
    formData.append("image", editContent.image);
    formData.append("content", editContent.content);
    formData.append("hash", editContent.hash);
    formData.append("themeId", editContent.themeId);

    articlesSVC
      .addThemeposts(formData)
      .then((res: any) => {
        setloading(false);
        getOtherContent();
        getThemes();
        message.success("Added Successfully");
        setEditContent({
          id: "",
          title: "",
          image: "",
          content: "",
          hash: "",
          themeId: "",
        });
        handleCancel();
        setUploadedImage(null);
      })
      .catch((err: any) => {
        message.error(err.message);
        setloading(false);
      });
  };
  const editArticle = (id: string) => {
    articlesSVC
      .editThemeposts(id)
      .then((res: any) => {
        setEditContent({
          id: res.data.post.id,
          title: res.data.post.title,
          content: res.data.post.content,
          hash: res.data.post.hash,
          image: res.data.post.image,
          themeId: res.data.post.themeId,
        });
      })
      .catch((err: any) => {
        message.error(err.message);
      });
  };

  const showModal = () => {
    setIsModalOpen(true);
    setIsEditable(false);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setEditContent({
      id: "",
      title: "",
      image: "",
      content: "",
      hash: "",
      themeId: "",
    });
    setUploadedImage(null);
    setloading(false);
  };

  const handleFileUpload = async (event: any) => {
    event.preventDefault();
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setUploadedImage(reader.result);
    };
    setBtnDisable(false);
    setEditContent((prevData) => ({
      ...prevData,
      image: file,
    }));

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleInputChange = (event: any) => {
    setBtnDisable(false);
    const { name, value } = event.target;
    setEditContent((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSelectedItem = (value: string) => {
    setBtnDisable(false);
    setEditContent((prevData) => ({
      ...prevData,
      themeId: value,
    }));
  };

  const handleDescription = (value: any) => {
    setBtnDisable(false);
    setEditContent((prevData) => ({
      ...prevData,
      content: value,
    }));
  };
  return (
    <div className="contentBox">
      <div className="info">
        <Text className="title24">Articles</Text>
        <Button onClick={showModal} className="button">
          Add New Article
        </Button>
      </div>
      <DataTableArticle
        deleteArticle={deleteArticle}
        editContent={editArticle}
        setshowModel={setIsModalOpen}
        setEditable={setIsEditable}
        isOpen={isModalOpen}
        contentData={contentData}
      />
      <Modal
        footer={false}
        title={isEditable === true ? "Update  Articles" : "Add Articles"}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        {editContent.themeId === "" ? (
          <Select
            showSearch
            style={{ width: "100%", borderRadius: "24px" }}
            placeholder="Select a Theme"
            optionFilterProp="children"
            onChange={handleSelectedItem}
            filterOption={(input, option:any) =>
              (option?.label ?? "")?.toLowerCase()?.includes(input.toLowerCase())
            }
            options={themes?.map((option: any) => ({
              value: option.id,
              label: option.name,
            }))}
          />
        ) : (
          <Select
            value={editContent?.themeId}
            showSearch
            style={{ width: "100%", borderRadius: "24px" }}
            placeholder="Select a Theme"
            optionFilterProp="children"
            onChange={handleSelectedItem}
            filterOption={(input, option:any) =>
              (option?.label ?? "")?.toLowerCase().includes(input.toLowerCase())
            }
            options={themes?.map((option:any) => ({
              value: option.id,
              label: option.name,
            }))}
          />
        )}
        {editContent?.themeId === "" ? null : (
          <>
            <br />
            <br />
            <Input
              className="input"
              name="title"
              onChange={handleInputChange}
              size={"large"}
              style={{ borderRadius: "10px" }}
              value={editContent.title}
              placeholder={"Enter Title"}
            />
          </>
        )}
        {editContent.title === "" ? null : (
          <>
            <br />
            <br />
            <ReactQuill
              value={editContent.content}
              onChange={handleDescription}
              modules={{
                toolbar: [
                  // [{ 'header': '1' }, { 'header': '2' }],
                  ["bold", "italic", "underline", "strike", "blockquote"],
                  [{ list: "ordered" }, { list: "bullet" }],
                  ["link"],
                  ["clean"],
                ],
              }}
            />
          </>
        )}
        {editContent.content === "" ? null : (
          <>
            <br />
            <Input
              type="text"
              name="hash"
              onChange={handleInputChange}
              size={"large"}
              style={{ borderRadius: "10px" }}
              value={editContent.hash}
              placeholder={"Enter Hash"}
            />
          </>
        )}
        {editContent.hash === "" ? null : (
          <>
            <br />
            <br />
            <ImageUploader
              image={editContent?.image}
              uploadedImage={uploadedImage}
              handleFileUpload={handleFileUpload}
            />
          </>
        )}
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            onClick={() => {
              if (editContent.themeId === "") {
                setBtnDisable(true);
                message.info("Please Selected a Theme");
              } else if (editContent.title === "") {
                setBtnDisable(true);
                message.info("Please Select Title");
              } else if (editContent.content === "") {
                setBtnDisable(true);
                message.info("Please Enter Content");
              } else if (editContent.hash === "") {
                setBtnDisable(true);
                message.info("Please Enter hash");
              } else {
                if (isEditable === true) {
                  updateArticle(editContent.id);
                } else {
                  if (editContent.image === "") {
                    setBtnDisable(true);
                    message.info("Please upload a image");
                  } else {
                    addArticle();
                  }
                }
              }
            }}
            style={{
              marginTop: "10px",
            }}
            className="button"
            loading={loading}
            disabled={btnDisable ? true : false}
          >
            {isEditable === true ? "Update Article" : "Add Article"}
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default Article;
