import React, { useEffect, useState } from "react";
import moment from "moment";
import { authSVC } from "../../services";
import { useB2BContext } from "../../context/ContextB2B";
import { Button, Form, Input, message, DatePicker } from "antd";
import authstyle from "../../styles/auth.module.scss";
import type { DatePickerProps } from "antd";

const { TextArea } = Input;

interface props {
  RetryPayment: any;
}

function PaymentStatusFail({ RetryPayment }: props) {
  const { setAgreementId, getUserByID } = useB2BContext();

  return (
    <>
      <div className="payment_status_wrapper">
        <img src="/img/faildPay.png" alt="" width="100%" height="240px" />
        <div className="thank_you_content">
          <h2>Your payment has failed!</h2>
          <p>
            Please retry to complete your transaction. We will notify you once
            your package is activated. Thank you for your patience!
          </p>
          <button className="retry" onClick={RetryPayment}>
            Retry Payment
          </button>
          <p className="help">
            Need help? We are here for you!{" "}
            <a
              href="https://www.soulsara.world/contact-us"
              target="_blank"
              rel="noreferrer"
            >
              contact us
            </a>{" "}
          </p>
        </div>
      </div>
    </>
  );
}

export default PaymentStatusFail;
