import React, { useState } from "react";
import "./datatable.scss";
import {
  Space,
  Table,
  Input,
  Button,
  Image,
  Tag,
  Dropdown,
  Popover,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import moment from "moment";

interface Contentdata {
  key?: string;
  _id: string;
  author?: {
    _id: string;
    email: string;
    profile_picture: string;
  };
  title: string;
  bodytext: string;
  image: string;
  adminStatus: string;
  createdAt: string;
  updatedAt: string;
}

// interface DataTableProps {
//     setshowModel: (value: boolean) => void,
//     setEditable: (value: boolean) => void,
//     deleteThought: (value: any) => void,
//     editContent:(value:any)=>void
//     isOpen: boolean,
//     contentData?: Contentdata[]
// }

function DataTableFourm({
  editFourm,
  setuserId,
  isLoading,
  updateFourm,
  setshowModel,
  setEditable,
  isOpen,
  contentData,
  deleteOtherContent,
  editContent,
}: any) {
  const [searchText, setSearchText] = useState("");
  const [pagination, setPagination] = useState<any>({ page: 1, pageSize: 5 });

  const addDaysToDate = (date: any, daysToAdd: any) => {
    return moment(date).add(daysToAdd, "days").format("YYYY-MM-DD HH:mm:ss");
  };
  const columns: ColumnsType<Contentdata> = [
    {
      title: "ID",
      dataIndex: "_id",
      key: "_id",
      filteredValue: [searchText],
      defaultSortOrder: "descend",
      sorter: (a, b) => a._id.localeCompare(b._id),
      onFilter: (value, record) => {
        return (
          String(record.adminStatus)
            .toLowerCase()
            .includes(value.toLocaleString()) ||
          String(record.author?.email)
            .toLowerCase()
            .includes(value.toLocaleString()) ||
          String(record?._id).toLowerCase().includes(value.toLocaleString()) ||
          String(record?.adminStatus)
            .toLowerCase()
            .includes(value.toLocaleString())
        );
      },
    },
    {
      title: "Email",
      dataIndex: "author",
      key: "email",
      render: (author) => author?.email,
    },

    {
      title: "Post Details",
      key: "seepost",
      width: 120,
      render: (_, record) => (
        <Space>
          {
            <Button
              onClick={() => {
                setEditable(true);
                editContent(record?._id);
                setuserId(record?._id);
              }}
              className="btn_edit_table"
            >
              See More
            </Button>
          }
        </Space>
      ),
    },

    {
      title: "Status",
      dataIndex: "poststatus",
      key: "poststatus",
      width: 120,
      render: (_, record) => (
        <Space size="middle">
          {record.adminStatus === "REJECTED" ? (
            <Tag color={"red"}>{record.adminStatus}</Tag>
          ) : record.adminStatus === "APPROVED" ? (
            <Tag color={"green"}>{record.adminStatus}</Tag>
          ) : record.adminStatus === "PENDING" ? (
            <Tag color={"orange"}>{record.adminStatus}</Tag>
          ) : null}
        </Space>
      ),
    },
    {
      title: "Action",
      key: "action",
      width: 150,
      render: (_, record) => (
        <Space size="middle">
          <Popover
            content={
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <Button
                  style={{ backgroundColor: "green", color: "#fff" }}
                  onClick={() => updateFourm(record._id, "APPROVED")}
                >
                  APPROVED
                </Button>
                <Button
                  style={{ backgroundColor: "orange", color: "#fff" }}
                  onClick={() => updateFourm(record._id, "REJECTED")}
                >
                  REJECTED
                </Button>
              </div>
            }
            title="Update Status"
          >
            <Button className="btn_edit_table">Update Status</Button>
          </Popover>
          {moment().format("YYYY-MM-DD HH:mm:ss") >
            addDaysToDate(record.updatedAt, 15) &&
          record.adminStatus === "REJECTED" ? (
            <Popover
              content={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "10px",
                  }}
                >
                  <Button
                    style={{ backgroundColor: "green", width: "100%" }}
                    onDoubleClick={() => deleteOtherContent(record._id)}
                  >
                    Yes
                  </Button>
                </div>
              }
              title="Please Double click to delete."
            >
              <Button className="btn_edit_table">Delete</Button>
            </Popover>
          ) : (
            ""
          )}
        </Space>
      ),
    },
  ];

  return (
    <div className="dataTable">
      <div className="filterMenu">
        <div></div>
        <Input.Search
          onSearch={(value) => {
            setSearchText(value);
          }}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
          className="searchField"
          placeholder="Search here..."
        />
      </div>
      <Table
        scroll={{ y: 500 }}
        className="dataGrid"
        columns={columns}
        dataSource={contentData}
        pagination={{
          pageSize: pagination?.pageSize,
          onChange(page, pageSize) {
            setPagination(pageSize);
          },
          showSizeChanger: true,
        }}
      />
    </div>
  );
}

export default DataTableFourm;
