import React, { useEffect, useState } from "react";
import moment from "moment";

import {
  Typography,
  Modal,
  Input,
  message,
  Form,
  Select,
  DatePickerProps,
  DatePicker,
} from "antd";
import { commentsSVC, authSVC } from "../../services";
import DemoUserTable from "../../components/dataTable/b2b/DemoUserTable";
import { Checkbox, Col, Row } from "antd";
import "./HelpLine.scss";
import { TimePicker } from "antd";
import type { Dayjs } from "dayjs";
import timeslotData from "./timeslot.json";
import TimeSlot from "./TimeSlot";

const { Text } = Typography;
function HelpLinePage() {
  const [selectedDate, setSelectedDate] = useState<any>();
  const [timeSlot, setTimeSlot] = useState<number>();
  const [getAllTimeSlot, setGetAllTimeSlot] = useState<any>();
  const [existTimeSlot, setExistTimeSlot] = useState<any>([]);

  const onChange: DatePickerProps["onChange"] = (date: any, dateString) => {
    const dateStd: any = moment(dateString).format();
    setGetAllTimeSlot("");
    setSelectedDate(dateStd);
  };

  const disabledDate = (current: any) => {
    return current && current < moment().startOf("day");
  };

  const AddToTimeSlot = (id: any) => {
    CreateSession(id);
    console.log(id);
  };

  useEffect(() => {
    getAllTimeSlot &&
      getAllTimeSlot.map((data: any, index: any) => {
        setExistTimeSlot([...data.timeSlot]);
      });
  }, [getAllTimeSlot]);

  useEffect(() => {
    console.log(selectedDate, "ccccc");
    if (selectedDate) {
      getAllSession(selectedDate);
    }
  }, [selectedDate]);

  const getAllSession = (selectedDate: any) => {
    authSVC
      .GetAllSessionV1(selectedDate)
      .then((res: any) => {
        setGetAllTimeSlot(res.data.data);
      })
      .catch((err: any) => {
        message.error("No any timeslot");
      });
  };

  const CreateSession = (id: any) => {
    authSVC
      .CreateSession({
        date: selectedDate,
        timeSlot: id,
      })
      .then((res) => {
        if (res.data.success === true) {
          getAllSession(selectedDate);
        }
        message.success("Time Slot Added Successfully");
      })
      .catch((err) => {
        message.success("Can't add this timeslot, duplicate data");
      });
  };

  // useEffect(() => {
  //   console.log(getAllTimeSlot);
  // }, [getAllTimeSlot]);

  return (
    <div className="contentBox">
      <div className="info">
        <Text className="title24">Help Line</Text>
      </div>
      <div className="picker_wrapper">
        <DatePicker
          placeholder="SELECT A DATE"
          className="date"
          disabledDate={disabledDate}
          onChange={onChange}
        />
      </div>

      <div className="timeslot">
        <ul>
          {selectedDate &&
            timeslotData &&
            timeslotData.slot?.map((time: any, index: number) => {
              return (
                <TimeSlot
                  time={time}
                  AddToTimeSlot={AddToTimeSlot}
                  getAllTimeSlot={getAllTimeSlot}
                  selectedDate={selectedDate}
                  existTimeSlot={existTimeSlot}
                />
              );
            })}
        </ul>

        {/* {selectedDate && (
          <ul>
            {selectedTimeSlots ? (
              selectedTimeSlots?.map((time: any, index: number) => {
                return (
                  <li
                    onClick={() => navigate(index)}
                    className={active == index ? "active times" : "times"}
                    key={index}
                  >
                    {time}
                  </li>
                );
              })
            ) : (
              <li className="no_data">
                Not available time slots. Please choose another date.
              </li>
            )}
          </ul>
        )} */}
        {/* <button className="btn">Update Time Slot</button> */}
      </div>
    </div>
  );
}

export default HelpLinePage;
