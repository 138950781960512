import React, { useEffect, useState } from "react";
import {
  Button,
  Typography,
  Modal,
  Input,
  message,
  Select,
  Form,
  Image,
} from "antd";
import { authSVC, categorySVC } from "../../services";
import { Option } from "antd/es/mentions";
import { useB2BContext } from "../../context/ContextB2B";
import { useNavigate } from "react-router-dom";

const { Text } = Typography;

interface Contentdata {
  _id: string;
  full_name: string;
  email: string;
  user_type: string;
  company: string;
  department: string;
  occupation: string;
  marital_Status: string;
  package_id: string;
  company_id: string;
  package_name: string;
  gender: string;
}

const API_PATH = process.env.REACT_APP_API_URL;

function AddEmployees() {
  const [form] = Form.useForm();

  const { getUserByID } = useB2BContext();
  const [editContent, setEditContent] = useState<Contentdata>({
    _id: "",
    full_name: "",
    email: "",
    user_type: "",
    company: "",
    department: "",
    occupation: "",
    package_id: "",
    company_id: "",
    package_name: "",
    marital_Status: "",
    gender: "",
  });
  const [contentData, setContentData] = useState<any[]>([]);
  const [loading, setloading] = useState<boolean>(false);
  const [disableAddBtn, setDisableAddBtn] = useState(false);
  const [disableAddEmp, setDisableAddEmp] = useState(false);
  const [userPackage, setUserPackage] = useState("");
  const [isLoding, setIsLoding] = useState(false);
  const [allDepartments, setAllDepartments] = useState<any>();
  const [csvFile, setCSVFile] = useState<any>(null);
  const navigate = useNavigate();

  useEffect(() => {
    console.log(allDepartments, "allDepartments");
  }, [allDepartments]);

  useEffect(() => {
    getDepartments(getUserByID);
  }, [getUserByID]);

  const getDepartments = (getUserByID: any) => {
    if (getUserByID?.company) {
      authSVC
        .getCompanyDepartments(getUserByID?.company)
        .then((res: any) => {
          // message.success("Added Successfully");
          // setContentData(res.data?.data?.data);
          setAllDepartments(res.data);
          // if(res.data.data)
        })
        .catch((err: any) => {
          message.error(err.message);
        });
    }
  };

  useEffect(() => {
    // console.log(getUserByID, "jjjj");
    setUserPackage(getUserByID?.package_name);
  }, [getUserByID]);

  useEffect(() => {
    if (getUserByID?.email) {
      authSVC
        .getCompanyuser({ email: getUserByID?.email })
        .then((res: any) => {
          setContentData(res.data?.data?.data);
        })
        .catch((err: any) => {
          message.error(err.message);
        });
    }
  }, [getUserByID?.email]);

  const addEmployees = () => {
    setloading(true);

    authSVC
      .adduser({
        full_name: editContent.full_name,
        email: editContent.email,
        user_type: "Company_member",
        company: getUserByID?.company,
        adminId: getUserByID?._id,
        department: editContent?.department,
        occupation: editContent.occupation,
        marital_Status: editContent.marital_Status,
        package_id: editContent.package_id,
        company_id: getUserByID?.company_id?._id,
        package_name: editContent.package_name,
        gender: editContent.gender,
      })
      .then((res: any) => {
        setloading(false);
        message.success("Added User Successfully");
        form.resetFields();
      })
      .catch((err: any) => {
        message.error(err.response?.data?.error);
        setloading(false);
      });
  };

  const handleInputChange = (e: any) => {
    setEditContent((prevData: any) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSelectedItem = (value: any) => {
    setDisableAddEmp(false);
    setEditContent((prevData: any) => ({
      ...prevData,
      user_type: value,
    }));
  };

  const handleSelectedMarital = (value: any) => {
    console.log(value, "handleSelectedMarital");
    setDisableAddEmp(false);
    setEditContent((prevData: any) => ({
      ...prevData,
      marital_Status: value,
    }));
  };

  const handleSelectedGender = (value: any) => {
    console.log(value, "handleSelectedGender");
    setDisableAddEmp(false);
    setEditContent((prevData: any) => ({
      ...prevData,
      gender: value,
    }));
  };

  const handleSelectedDepratment = (value: any) => {
    if (value) {
      setDisableAddEmp(false);
    }

    setEditContent((prevData: any) => ({
      ...prevData,
      department: value,
    }));
  };

  const handleSelectedPackage = (packageValue: any) => {
    console.log(`Value for package ${JSON.parse(packageValue)}`);
    const value = JSON.parse(packageValue);
    if (value) {
      if (value) {
        setDisableAddEmp(false);
      }
      console.log(`Setting ${value._id} and ${value.package}`);
      setEditContent((prevData: any) => ({
        ...prevData,
        package_id: value._id,
      }));

      setEditContent((prevData: any) => ({
        ...prevData,
        package_name: value.package,
      }));
    }
  };

  const filteredContents = contentData?.filter((item: any) => {
    return item?.email === getUserByID?.email ? null : item;
  });

  useEffect(() => {
    if (filteredContents?.length === 10 && userPackage === "Gold") {
      setDisableAddBtn(true);
    } else if (filteredContents?.length === 100 && userPackage === "Diamond") {
      setDisableAddBtn(true);
    } else if (filteredContents?.length >= 100 && userPackage === "Platinum") {
      setDisableAddBtn(true);
    } else {
      setDisableAddBtn(false);
    }
  }, [filteredContents, userPackage]);

  useEffect(() => {
    if (allDepartments?.message?.length === 0) {
      setDisableAddEmp(true);
    } else {
      setDisableAddEmp(false);
    }
  }, [allDepartments]);

  const onFinish = () => {
    if (editContent.marital_Status === "") {
      setDisableAddEmp(true);
      message.info("Please select marital status");
    } else if (editContent.gender === "") {
      setDisableAddEmp(true);
      message.info("Please select gender");
    } else if (editContent.department === "") {
      setDisableAddEmp(true);
      message.info("Please select a  department");
    } else if (editContent.user_type === "") {
      message.info("Please select user type");
      setDisableAddEmp(true);
    } else {
      addEmployees();
    }
  };

  const nameValidation = (_: any, value: any) => {
    // Use a regular expression to validate that the input contains only letters
    const regex = /^[A-Za-z\s]+$/;

    if (!value || regex.test(value)) {
      return Promise.resolve();
    }

    return Promise.reject(
      new Error("Please enter a valid name with only letters.")
    );
  };

  const [packageList, setPackageList] = useState([]);
  useEffect(() => {
    console.log(`Packagessss list`, getUserByID?.company_id?.package);
    setPackageList(getUserByID?.company_id?.package);
  }, []);

  return (
    <div className="add_form_wrapper">
      <h1>Add Employee</h1>
      <Form
        name="normal_login"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        form={form}
      >
        <Form.Item
          name={"selected_package"}
          rules={[
            {
              required: true,
              message: "Please select employee package!",
            },
          ]}
        >
          <Select
            style={{ width: "100%", borderRadius: "24px" }}
            placeholder="Select employee package"
            optionFilterProp="children"
            onChange={handleSelectedPackage}
          >
            {packageList.map((packageDetail: any) => (
              <option value={JSON.stringify(packageDetail.package_id)}>
                {packageDetail.package_id.package}
              </option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="full_name"
          rules={[
            {
              required: true,
              message: "Please Enter Employee Full Name!",
            },
            {
              validator: nameValidation,
            },
          ]}
        >
          <Input
            name="full_name"
            className="input"
            value={editContent?.full_name}
            onChange={handleInputChange}
            type="text"
            placeholder="Please Enter Employee Full Name"
          />
        </Form.Item>

        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              type: "email",
              message: "Please enter valid Email!",
            },
          ]}
        >
          <Input
            className="input"
            name="email"
            onChange={handleInputChange}
            value={editContent.email}
            placeholder={"Please Enter Employee Email ID"}
          />
        </Form.Item>

        <Form.Item
          name="occupation"
          rules={[
            {
              required: true,
              message: "Please input occupation",
            },
          ]}
        >
          <Input
            className="input"
            name="occupation"
            placeholder={"Occupation"}
            onChange={handleInputChange}
          />
        </Form.Item>

        <Form.Item name={"marital_Status"}>
          <Select
            style={{ width: "100%", borderRadius: "24px" }}
            placeholder="Select a Marital Status"
            optionFilterProp="children"
            onChange={handleSelectedMarital}
          >
            <Option value="Married">Married</Option>
            <Option value="UnMarried">UnMarried</Option>
          </Select>
        </Form.Item>

        <Form.Item name={"gender"}>
          <Select
            style={{ width: "100%", borderRadius: "24px" }}
            placeholder="Select a gender"
            optionFilterProp="children"
            onChange={handleSelectedGender}
          >
            <Option value="Male">Male</Option>
            <Option value="Female">Female</Option>
            <Option value="Other">Other</Option>
          </Select>
        </Form.Item>

        <Form.Item name={"user_type"}>
          <Select
            style={{ width: "100%", borderRadius: "8px" }}
            placeholder="Please Select The Department"
            optionFilterProp="children"
            onChange={handleSelectedDepratment}
          >
            {allDepartments?.data?.map((item: any, index: any) => {
              return (
                <Option value={item.name} key={index}>
                  {item.name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item name={"department"}>
          <Select
            showSearch
            style={{ width: "100%", borderRadius: "24px" }}
            placeholder="Select a User Type"
            optionFilterProp="children"
            onChange={handleSelectedItem}
          >
            <Option value="Company_member">Company Member</Option>
          </Select>
        </Form.Item>
        <p>
          User Will Soon Receive An Invitation Email Containing Your Login
          Credentials. For Security Purposes, We Recommend That You Change Your
          Password Upon Your First Login.
        </p>
        <p>
          If You Encounter Any Difficulties During The Login Process Or Have Any
          Questions, Please Do Not Hesitate To Contact Our Support Team.*
        </p>
        <Button
          className="button_add_emp"
          htmlType="submit"
          loading={loading}
          disabled={disableAddEmp ? true : false}
          icon={<Image src="/img/new/add.svg" preview={false} alt="" />}
        >
          Add
        </Button>

        {disableAddEmp && (
          <p style={{ color: "#EB228A" }}>
            Not Created Any department. Please create a department before adding
            employees.
          </p>
        )}
      </Form>
    </div>
  );
}

export default AddEmployees;
