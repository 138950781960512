import React, { useEffect, useState } from "react";
import moment from "moment";

import {
  Typography,
  Modal,
  Input,
  message,
  Form,
  Select,
  DatePickerProps,
  DatePicker,
} from "antd";
import { commentsSVC, authSVC } from "../../services";
import DemoUserTable from "../../components/dataTable/b2b/DemoUserTable";
import { Checkbox, Col, Row } from "antd";
import "./HelpLine.scss";
import { TimePicker } from "antd";
import type { Dayjs } from "dayjs";
import timeslotData from "./timeslot.json";

const { Text } = Typography;

interface Contentdata {
  time: any;
  AddToTimeSlot: (value: any) => void;
  getAllTimeSlot: any;
  selectedDate: any;
  existTimeSlot: any;
}
function TimeSlot({
  time,
  AddToTimeSlot,
  getAllTimeSlot,
  existTimeSlot,
}: Contentdata) {
  const [isItemInCart, setIsItemInCart] = useState(false);

  useEffect(() => {
    if (getAllTimeSlot !== "") {
      const foundIndexProducts = getAllTimeSlot?.findIndex(
        (item: any) => item.timeSlot === time
      );
      if (foundIndexProducts > -1) {
        setIsItemInCart(true);
      }
    } else {
      setIsItemInCart(false);
    }
  }, [time, getAllTimeSlot]);
  return (
    <li>
      <Checkbox
        onChange={() => AddToTimeSlot(time)}
        checked={isItemInCart ? true : false}
      >
        {time}
      </Checkbox>
    </li>
  );
}

export default TimeSlot;
