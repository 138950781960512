import React, { useState } from "react";
import "./datatable.scss";
import { Space, Table, Input, Button, Image, Popover, Tag } from "antd";
import type { ColumnsType } from "antd/es/table";

interface Contentdata {
  key?: string;
  _id: string;
  title: string;
  date?: string;
  hour: string;
  video?: string;
  start_date: string;
  end_date: string;
  meetingLink?: string;
  meeting_id?: string;
  passcode?: string;
  cover_img: string;
}

interface DataTableProps {
  setshowModel: (value: boolean) => void;
  setEditable: (value: boolean) => void;
  deleteWebinar: (value: any) => void;
  editContent: (value: any) => void;
  isOpen: boolean;
  contentData: Contentdata[];
  showModal: any;
}
function DataTableWebinar({
  setshowModel,
  setEditable,
  isOpen,
  contentData,
  deleteWebinar,
  editContent,
  showModal,
}: DataTableProps) {
  const [searchText, setSearchText] = useState("");
  const [pagination, setPagination] = useState<any>({ page: 1, pageSize: 5 });

  const columns: ColumnsType<Contentdata> = [
    {
      title: "ID",
      dataIndex: "_id",
      key: "_id",
      width: 120,
      filteredValue: [searchText],
      defaultSortOrder: "descend",
      sorter: (a: any, b: any) => a._id.localeCompare(b._id),
      ellipsis: true,
      onFilter: (value, record) => {
        return (
          String(record._id).toLowerCase().includes(value.toLocaleString()) ||
          String(record.title).toLowerCase().includes(value.toLocaleString()) ||
          String(record.start_date)
            .toLowerCase()
            .includes(value.toLocaleString()) ||
          String(record.end_date).toLowerCase().includes(value.toLocaleString())
        );
      },
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: 150,
      responsive: ["sm"],
      // ellipsis: true,
    },
    // {
    //     title: 'Date',
    //     dataIndex: 'date',
    //     key: 'date',
    // },
    // {
    //     title: 'Hour',
    //     dataIndex: 'hour',
    //     key: 'hour',
    // },
    {
      title: "Video",
      dataIndex: "video",
      key: "video",
      width: 120,
      render: (video) => {
        return video ? (
          <a href={video} target="_blank" rel="noreferrer">
            <Tag color="green">Open Video</Tag>
          </a>
        ) : (
          <Tag color="red">Not Available</Tag>
        );
      },
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "start_date",
      width: 120,
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      key: "end_date",
      width: 120,
    },
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      width: 120,
      render: (_, record) => (
        <Space size="middle">
          <Image src={record.cover_img} width={60} height={60} alt={"image"} />
        </Space>
      ),
    },

    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space>
          <Button
            onClick={() => {
              editContent(record._id);
              setshowModel(!isOpen);
              setEditable(true);
            }}
            className="btn_edit_table"
          >
            Edit
          </Button>
          <Popover
            content={
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "10px",
                }}
              >
                <Button
                  style={{
                    backgroundColor: "green",
                    width: "100%",
                    color: "white",
                  }}
                  onDoubleClick={() => deleteWebinar(record._id)}
                >
                  Delete Webinar
                </Button>
              </div>
            }
            title="Please Double click to delete."
          >
            <Button className="btn_edit_table">Delete</Button>
          </Popover>
        </Space>
      ),
    },
  ];

  return (
    <div className="dataTable">
      <div className="filterMenu">
        <Button onClick={showModal} className="add_button">
          Add New Webinar
        </Button>
        <Input.Search
          onSearch={(value) => {
            setSearchText(value);
          }}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
          className="searchField"
          placeholder="Search here..."
        />
      </div>
      <Table
        scroll={{ y: 300 }}
        className="dataGrid"
        columns={columns}
        dataSource={contentData}
        pagination={{
          pageSize: pagination?.pageSize,
          onChange(page, pageSize) {
            setPagination(pageSize);
          },
          showSizeChanger: true,
        }}
      />
    </div>
  );
}

export default DataTableWebinar;
