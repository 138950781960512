import React, { useEffect } from "react";
import * as echarts from "echarts";

interface DepartmentData {
  name: string;
  value: number;
  color: string;
}

interface ChartItem {
  month: string;
  departments: DepartmentData[];
}

interface BarChartProps {
  chartData: ChartItem[];
}

const BarChart = ({ chartData }: any) => {
  useEffect(() => {
    const data = chartData || [];

    // Create a bar chart
    const chart = echarts.init(
      document.getElementById("bar-chart") as HTMLDivElement
    );

    // Extract department names dynamically
    const departmentNames =
      data[0]?.departments.map((department: any) => department.name) || [];

    const options = {
      xAxis: {
        type: "category",
        data: data.map((item: ChartItem) => item.month),
        axisLabel: {
          rotate: 45,
        },
      },
      yAxis: {
        type: "value",
        min: 1,
        max: 8, // Set the maximum value of the y-axis as needed
        interval: 1,
      },
      series: departmentNames.map((department: any) => ({
        name: department,
        type: "bar",
        data: data.map((item: ChartItem) => {
          const depData = item.departments.find(
            (dep) => dep.name === department
          );
          return depData ? depData.value : 0;
        }),
        itemStyle: {
          color: (item: any) => {
            const depData = data
              .find((d: any) => d.month === item.name)
              ?.departments.find((dep: any) => dep.name === department);
            return depData ? depData.color : "#000000"; // Default color if no color is found
          },
        },
      })),
    };

    // Set chart options
    chart.setOption(options);

    // Cleanup on unmount
    return () => {
      chart.dispose();
    };
  }, [chartData]);

  return (
    <div
      id="bar-chart"
      style={{
        width: "100%",
        height: "400px",
        margin: "0 !important",
        cursor: "not-allowed !important",
      }}
    ></div>
  );
};

export default BarChart;
