import React, { useState } from "react";
import "./datatable.scss";
import { Space, Table, Input, Button, Image, Tag, Popover } from "antd";
import type { ColumnsType } from "antd/es/table";

interface Contentdata {
  key?: string;
  _id: string;
  title: string;
  content: string;
  image: string;
  ngo_id: string;
}

interface DataTableProps {
  setshowModel: (value: boolean) => void;
  setEditable: (value: boolean) => void;
  deleteArticle: (value: any) => void;
  editContent: (value: any) => void;
  isOpen: boolean;
  contentData: Contentdata[];
}
function DataTableArticleNGO({
  setshowModel,
  setEditable,
  isOpen,
  contentData,
  deleteArticle,
  editContent,
}: DataTableProps) {
  const [searchText, setSearchText] = useState("");
  const [pagination, setPagination] = useState<any>({ page: 1, pageSize: 5 });

  const columns: ColumnsType<Contentdata> = [
    {
      title: "ID",
      dataIndex: "_id",
      key: "_id",
      filteredValue: [searchText],
      defaultSortOrder: "descend",
      sorter: (a: any, b: any) => a._id.localeCompare(b._id),
      onFilter: (value, record) => {
        return (
          String(record._id).toLowerCase().includes(value.toLocaleString()) ||
          String(record.title).toLowerCase().includes(value.toLocaleString())
        );
      },
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },

    {
      title: "Content",
      dataIndex: "content",
      key: "content",
      // render: text => (<>
      //     <p dangerouslySetInnerHTML={{ __html: text.slice(0, 100) }} />
      // </>)
    },

    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      render: (_, record) => (
        <Space size="middle">
          <Image src={record.image} width={60} height={60} alt={"image"} />
        </Space>
      ),
    },

    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={() => {
              editContent(record._id);
              setshowModel(!isOpen);
              setEditable(true);
            }}
          >
            Edit
          </Button>
          <Popover
            content={
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "10px",
                }}
              >
                <Button
                  style={{ backgroundColor: "green", width: "100%" }}
                  onDoubleClick={() => deleteArticle(record._id)}
                >
                  Yes
                </Button>
              </div>
            }
            title="Please Double click to delete."
          >
            <Button>Delete</Button>
          </Popover>
        </Space>
      ),
    },
  ];

  return (
    <div className="dataTable">
      <div className="filterMenu">
        <div></div>
        <Input.Search
          onSearch={(value) => {
            setSearchText(value);
          }}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
          className="searchField"
          placeholder="Search here..."
        />
      </div>
      <Table
        scroll={{ y: 300 }}
        className="dataGrid"
        columns={columns}
        dataSource={contentData}
        pagination={{
          pageSize: pagination?.pageSize,
          onChange(page, pageSize) {
            setPagination(pageSize);
          },
          showSizeChanger:true
        }}
      />
    </div>
  );
}

export default DataTableArticleNGO;
