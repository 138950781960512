import React, { useState } from "react";
import "./datatable.scss";
import { Space, Table, Input, Button, Image, Tag, Popover } from "antd";
import type { ColumnsType } from "antd/es/table";

interface Contentdata {
  key?: string;
  id: string;
  name: string;
  category: string;
  price: string;
  vendor_name: string;
  status: number;
}

interface DataTableProps {
  setshowModel: (value: boolean) => void;
  setEditable: (value: boolean) => void;
  editContent: (value: any) => void;
  isOpen: boolean;
  contentData: Contentdata[];
}
function DataTableApproveProducts({
  setshowModel,
  setEditable,
  isOpen,
  contentData,
  editContent,
}: DataTableProps) {
  const [searchText, setSearchText] = useState("");
  const [pagination, setPagination] = useState<any>({ page: 1, pageSize: 5 });

  const columns: ColumnsType<Contentdata> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      filteredValue: [searchText],
      defaultSortOrder: "descend",
      sorter: (a: any, b: any) => a.id - b.id,
      onFilter: (value, record) => {
        return (
          String(record.id).toLowerCase().includes(value.toLocaleString()) ||
          String(record.name).toLowerCase().includes(value.toLocaleString()) ||
          String(record.category)
            .toLowerCase()
            .includes(value.toLocaleString()) ||
          String(record.status).toLowerCase().includes(value.toLocaleString())
        );
      },
    },
    {
      title: "Product",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (_, { price }) => (
        <>
          <p>₹{price}</p>
        </>
      ),
    },
    {
      title: "Sold By",
      dataIndex: "vendor_name",
      key: "vendor_name",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, { status }) => (
        <>
          <Tag color={status === 1 ? "green" : "red"} key={1}>
            {status === 1 ? "Approved" : "Pending"}
          </Tag>
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={() => {
              editContent(record.id);
              setshowModel(!isOpen);
              setEditable(true);
            }}
          >
            Update
          </Button>
          {/* <Popover
                        content={
                            <div style={{ display: 'flex', justifyContent: 'space-between', gap: '10px' }}>
                                <Button style={{ backgroundColor: 'green', width: '100%' }} onDoubleClick={() => deleteArticle(record.id)}>Approved</Button>
                                <Button style={{ backgroundColor: 'green', width: '100%' }} onDoubleClick={() => deleteArticle(record.id)}>Rejected</Button>
                            </div>
                        }
                        title="Please Double click to delete."
                    >
                        <Button>Action</Button>
                    </Popover> */}
        </Space>
      ),
    },
  ];

  return (
    <div className="dataTable">
      <div className="filterMenu">
        <div></div>
        <Input.Search
          onSearch={(value) => {
            setSearchText(value);
          }}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
          className="searchField"
          placeholder="Search here..."
        />
      </div>
      <Table
        scroll={{ y: 600 }}
        className="dataGrid"
        columns={columns}
        dataSource={contentData}
        pagination={{
          pageSize: pagination?.pageSize,
          onChange(page, pageSize) {
            setPagination(pageSize);
          },
          showSizeChanger:true

        }}
      />
    </div>
  );
}

export default DataTableApproveProducts;
