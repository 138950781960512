import React, { useState } from "react";
import "./datatable.scss";
import {
  Space,
  Table,
  Input,
  Button,
  Popover,
  message,
  Tag,
  Form,
  Select,
} from "antd";
import { authSVC } from "../../../services";
import type { ColumnsType } from "antd/es/table";
import { Modal } from "antd";

interface Contentdata {
  demoRequestApproved: any;
  key?: string;
  _id: string;
  bodyText: string;
  user_id: string;
  video_id: string;
  business_name: string;
  business_email: string;
}

function DemoUserTable({ contentData }: any) {
  const [searchText, setSearchText] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [disabledButtons, setDisabledButtons] = useState<any>({});
  const [recordId, setRecordId] = useState<any>();
  const [pagination, setPagination] = useState<any>({ page: 1, pageSize: 5 });
  const [open, setOpen] = useState(false);
  const [dataRecodeId, setDataRecodeId] = useState<any>(false);
  const [visible, setVisible] = useState(true);

  const hide = () => {
    setOpen(false);
  };
  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const SentPassword = (record: any) => {
    setVisible(false);
    setRecordId(record._id);
    setDisabledButtons((prevState: any) => ({
      ...prevState,
      [record._id]: true,
    }));

    authSVC
      .addHruser({
        full_name: record.name,
        email: record.business_email,
        username: record.business_email,
        company: record.business_name,
        user_type: "Company_admin",
      })
      .then((res: any) => {
        message.success("Added User Successfully");
        setIsModalOpen(false);
        window.location.reload();
      })
      .catch((err: any) => {
        message.error(err.response?.data?.error);

        // if (contentData.find((source: any) => source._id === record._id)) {
        //   setBtnDisable(true);
        // }

        // setloading(false);
      });
  };

  const shouldDisableButton = (key: any) => {
    // alert();
    // Replace this with your condition to disable the button based on the key
    // For example, disable the button if the key is '2'
    console.log(key, "ccccc");

    return key === recordId;
  };

  console.log(contentData);

  const onFinish = (values: any) => {
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const actions = (
    <div>
      <p className="disc">
        Click ‘Confirm Client’ to add to the list and proceed with payment.
      </p>
      <Button
        onClick={() => SentPassword(dataRecodeId)}
        className="confirm"
        disabled={visible ? false : true}
      >
        Confirm Client
      </Button>
      {/* <Button onClick={() => alert("Delete action!")} className="archive">
        Archive
      </Button> */}
    </div>
  );

  const columns: ColumnsType<Contentdata> = [
    {
      title: "ID",
      dataIndex: "_id",
      key: "_id",
      width: 120,
      filteredValue: [searchText],
      render: (text) => <div className="truncate">{text}</div>,
      sorter: (a: any, b: any) => a._id.localeCompare(b._id),
      onFilter: (value, record) => {
        return (
          String(record.business_email)
            .toLowerCase()
            .includes(value.toLocaleString()) ||
          String(record.business_name)
            .toLowerCase()
            .includes(value.toLocaleString()) ||
          String(record._id).toLowerCase().includes(value.toLocaleString())
        );
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      width: 120, // Set a fixed wid
      key: "name",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Company Name",
      dataIndex: "business_name",
      width: 140, // Set a fixed wid
      key: "business_name",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Company Email",
      dataIndex: "business_email",
      key: "business_email",
      width: 240, // Set a fixed wid
      sortDirections: ["descend"],
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Phone",
      dataIndex: "phone_number",
      key: "phone_number",
      render: (text) => <div>+{text}</div>,
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) =>
        !record.demoRequestApproved ? (
          <Space size="middle">
            <Popover
              content={actions}
              title="Actions"
              trigger="click"
              className="table_pop_over"
            >
              <Button
                disabled={disabledButtons[record._id]}
                onClick={() => setDataRecodeId(record)}
                className="btn_edit_table"
              >
                Confirm
              </Button>
            </Popover>
          </Space>
        ) : (
          <Button
            disabled={true}
            onClick={() => setDataRecodeId(record)}
            className="btn_edit_table"
          >
            Confirmed
          </Button>
        ),
    },
  ];
  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
    // setCompanySize(value);
  };

  return (
    <div className="dataTable">
      <div className="filterMenu">
        <Input.Search
          onSearch={(value) => {
            setSearchText(value);
          }}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
          className="searchField"
          placeholder="Search here..."
        />
      </div>
      <Table
        className="dataGrid"
        tableLayout="fixed"
        // scroll={{ x: 1000 }}
        columns={columns}
        dataSource={contentData}
        pagination={{
          pageSize: pagination?.pageSize,
          onChange(page, pageSize) {
            setPagination(pageSize);
          },
          showSizeChanger: true,
        }}
      />
      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        className="checkout_popup"
        // closable={false}
        // maskClosable={false}
      >
        <>
          <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout="vertical"
            style={{ width: "400px" }}
          >
            <Form.Item
              label="Package Name"
              name="package_name"
              rules={[
                { required: true, message: "Please input your username!" },
              ]}
            >
              <Select
                onChange={handleChange}
                options={[
                  { value: "Gold", label: "Gold" },
                  { value: "Diamond", label: "Diamond" },
                  { value: "Platinum", label: "Platinum" },
                ]}
              />
            </Form.Item>
            <Form.Item
              label="Package Price"
              name="package_price"
              rules={[
                { required: true, message: "Please input your username!" },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </>
      </Modal>
    </div>
  );
}

export default DemoUserTable;
