import React, { useState } from "react";
import "./datatable.scss";
import { Table, Input } from "antd";
import type { ColumnsType } from "antd/es/table";
import moment from "moment";

interface Contentdata {
  key?: string;
  _id: string;
  first_Name: string;
  second_Name: string;
  preferred_Name: string;
  corporate_Email: string;
  organization_Size: string;
  createdAt: string;
  status: string;
}

interface DataTableProps {
  setshowModel: (value: boolean) => void;
  setEditable: (value: boolean) => void;
  // deleteWebinar: (value: any) => void,
  // editContent: (value: any) => void,
  isOpen: boolean;
  contentData: Contentdata[];
}
function DataTableOCA({
  setshowModel,
  setEditable,
  isOpen,
  contentData,
}: DataTableProps) {
  const [searchText, setSearchText] = useState("");
  const [pagination, setPagination] = useState<any>({ page: 1, pageSize: 5 });
  const columns: ColumnsType<Contentdata> = [
    {
      title: "ID",
      dataIndex: "_id",
      key: "_id",
      filteredValue: [searchText],
      sorter: (a: any, b: any) => a._id.localeCompare(b._id),
      ellipsis: true,
      onFilter: (value, record) => {
        return (
          String(record._id).toLowerCase().includes(value.toLocaleString()) ||
          String(record.first_Name)
            .toLowerCase()
            .includes(value.toLocaleString()) ||
          String(record.second_Name)
            .toLowerCase()
            .includes(value.toLocaleString()) ||
          String(record.preferred_Name)
            .toLowerCase()
            .includes(value.toLocaleString()) ||
          String(record.corporate_Email)
            .toLowerCase()
            .includes(value.toLocaleString()) ||
          String(record.organization_Size)
            .toLowerCase()
            .includes(value.toLocaleString()) ||
          String(record.status).toLowerCase().includes(value.toLocaleString())
        );
      },
    },
    {
      filteredValue: [searchText],
      title: "First Name",
      dataIndex: "first_Name",
      key: "first_Name",
    },
    {
      title: "Second Name",
      dataIndex: "second_Name",
      key: "second_Name",
    },
    {
      title: "Preferred Name",
      dataIndex: "preferred_Name",
      key: "preferred_Name",
    },
    {
      title: "Corporate Email",
      dataIndex: "corporate_Email",
      key: "corporate_Email",
    },
    {
      title: "Organization Size",
      dataIndex: "organization_Size",
      key: "organization_Size",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (value) => moment(value).fromNow(),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    // {
    //     title: 'Action',
    //     key: 'action',
    //     render: (_, record) => (
    //         <Space size="middle">
    //             <Button onClick={() => {
    //                 editContent(record._id)
    //                 setshowModel(!isOpen);
    //                 setEditable(true);
    //             }}>Edit</Button>
    //             <Popover
    //                 content={
    //                     <div style={{ display: 'flex', justifyContent: 'space-between', gap: '10px' }}>
    //                         <Button style={{ backgroundColor: 'green', width: '100%' }} onDoubleClick={() => deleteWebinar(record._id)}>Yes</Button>
    //                     </div>
    //                 }
    //                 title="Please Double click to delete."
    //             >
    //                 <Button>Delete</Button>
    //             </Popover>
    //         </Space>
    //     ),
    // },
  ];

  return (
    <div className="dataTable">
      <div className="filterMenu">
        <div></div>
        <Input.Search
          onSearch={(value) => {
            setSearchText(value);
          }}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
          className="searchField"
          placeholder="Search here..."
        />
      </div>
      <Table
        scroll={{ y: 300 }}
        className="dataGrid"
        columns={columns}
        dataSource={contentData}
        pagination={{
          pageSize: pagination?.pageSize,
          onChange(page, pageSize) {
            setPagination(pageSize);
          },
          showSizeChanger: true
        }}
      />
    </div>
  );
}

export default DataTableOCA;
