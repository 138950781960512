import { AxiosPromise } from 'axios';

const API_PATH = process.env.REACT_APP_API_URL;

type API = {
  post: (url: string, payload: any) => AxiosPromise
  put: (url: string, payload: any) => AxiosPromise
  get: (url: string) => AxiosPromise
  delete: (id: string) => AxiosPromise
}

const ngo = (api: API) => {
  const addorganization = (payload: any) => api.post(API_PATH + '/ngo_organization/create/', payload)
  const getorganization = () => api.get(API_PATH + '/ngo_organization/all/')
  const deleteorganization = (id: string) => api.delete(API_PATH + `/ngo_organization/delete/${id}`)
  const updateorganization = (id: string, payload: any) => api.put(API_PATH + `/ngo_organization/updateCover/${id}`, payload)

  return {
    addorganization,
    getorganization,
    deleteorganization,
    updateorganization,
  }
}

export default ngo
