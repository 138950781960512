import React, { useState } from "react";
import "./datatable.scss";
import { Table, Input, Tag } from "antd";
import { Modal } from "antd";
import type { ColumnsType } from "antd/es/table";
import moment from "moment";
interface Contentdata {
  key?: string;
  _id: string;
  date: string;
  timeSlot: string;
  isPaid: string;
  icpAnswersPdf: string;
  user: string;
}

function HelplineDataTable({ contentData }: any) {
  const [searchText, setSearchText] = useState("");
  const [pagination, setPagination] = useState<any>({ page: 1, pageSize: 5 })

  const columns: ColumnsType<Contentdata> = [
    {
      title: "ID",
      dataIndex: "_id",
      key: "_id",
      filteredValue: [searchText],
      sorter: (a: any, b: any) => a._id.localeCompare(b._id),
      sortDirections: ["descend","ascend"],
      onFilter: (value, record) => {
        return (
          String(record._id).toLowerCase().includes(value.toLocaleString()) ||
          String(record.date).toLowerCase().includes(value.toLocaleString()) ||
          String(record.timeSlot)
            .toLowerCase()
            .includes(value.toLocaleString()) ||
          String(record.isPaid)
            .toLowerCase()
            .includes(value.toLocaleString()) ||
          String(record.user).toLowerCase().includes(value.toLocaleString())
        );
      },
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (item) => moment(item).format("YYYY-MMM-DD"),
    },
    {
      title: "Time Slot",
      dataIndex: "timeSlot",
      key: "timeSlot",
    },
    {
      title: "Payment Status",
      dataIndex: "isPaid",
      key: "isPaid",
      render: (item) =>
        item === true ? (
          <Tag color="green">Paid</Tag>
        ) : (
          <Tag color="red">Not Paid</Tag>
        ),
    },
    {
      title: "Session Status",
      dataIndex: "sessionStatus",
      key: "sessionStatus",
    },
    {
      title: "ICP Q/A",
      dataIndex: "icpAnswersPdf",
      key: "icpAnswersPdf",
      render: (item) =>
        item === "" || item === null ? (
          <Tag color="red">Not Found</Tag>
        ) : (
          <a href={item} target="_blank" rel="noreferrer">
            Open PDF
          </a>
        ),
    },
    {
      title: "User ID",
      dataIndex: "user",
      key: "user",
    },
    // {
    //   title: "Action",
    //   key: "action",
    //   render: (_, record) => (
    //     <Space size="middle">
    //       {/* <Button onClick={() => deleteComments(record.video_id, record._id)}>
    //         Delete
    //       </Button> */}
    //       <Button onClick={() => addPaymentDetails(record._id, record.email)}>
    //         <CreditCardOutlined />
    //       </Button>
    //     </Space>
    //   ),
    // },
  ];

  return (
    <div className="dataTable">
      <div className="filterMenu">
        <div></div>
        <Input.Search
          onSearch={(value) => {
            setSearchText(value);
          }}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
          className="searchField"
          placeholder="Search here..."
        />
      </div>
      <Table
        className="dataGrid"
        columns={columns}
        dataSource={contentData}
        pagination={{
          pageSize: pagination?.pageSize, onChange(page, pageSize) {
            setPagination(pageSize)
          },
          showSizeChanger: true
        }}
      />
    </div>
  );
}

export default HelplineDataTable;
