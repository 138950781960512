import React, { useEffect, useState } from "react";
import Text from "antd/lib/typography/Text";
import { Button, Image, Progress } from "antd";
import styles from "../../styles/dashboard.module.scss";

export default function WellnessEngagementResult({ overallLevel }: any) {
  useEffect(() => {
    console.log(overallLevel);
  }, [overallLevel]);
  return (
    <div className={styles.white_bg_wrapper}>
      <div className={styles.info_title}>
        <Text className={styles.title_profile}>Team Engagement</Text>
        <div className={styles.title_grp1}>
          {/* <Image
            className={styles.img}
            src="/img/new/download_icon.svg"
            preview={false}
          /> */}
          {/* <Text className={styles.subtitle1}>21% from last month</Text> */}
        </div>
      </div>
      <div className={styles.engagement_info}>
        <p className={styles.title}>
          {overallLevel?.wpengagement_percentage?.toFixed(0) ?? 0}%
        </p>
        <div className={styles.subtitle}>
          <Progress
            strokeColor={"#9EC756"}
            trailColor="#FDECED"
            percent={overallLevel?.wpengagement_percentage}
            status="active"
            showInfo={false}
          />
        </div>
      </div>
    </div>
  );
}
