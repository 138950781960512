import React, { useState } from 'react'
import './datatable.scss'
import { Space, Table, Input, Button, Popover } from 'antd';
import type { ColumnsType } from 'antd/es/table';



interface Contentdata {
    key?: string;
    id: number
    name: string
}
// interface DataTableProps {
//     setshowModel: (value: boolean) => void,
//     setEditable: (value: boolean) => void,
//     deleteThought: (value: any) => void,
//     editContent:(value:any)=>void
//     isOpen: boolean,
//     contentData?: Contentdata[]
// }
function DataTableTheme({ setshowModel, setEditable, isOpen, contentData, deleteThemes, editContent }: any) {

    const [searchText, setSearchText] = useState("");
    const [pagination, setPagination] = useState<any>({ page: 1, pageSize: 5 })

    const columns: ColumnsType<Contentdata> = [

        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            filteredValue: [searchText],
            onFilter: (value, record) => {
                return String(record.name).toLowerCase()
                    .includes(value.toLocaleString()) || String(record.id).toLowerCase()
                        .includes(value.toLocaleString())
            }
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: text => text.length > 15 ? `${text.slice(0, 100)}...` : text,
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Button onClick={() => {
                        setshowModel(!isOpen);
                        setEditable(true);
                        editContent(record.id)
                    }}>Edit</Button>
                    <Popover
                        content={
                            <div style={{ display: 'flex', justifyContent: 'space-between', gap: '10px' }}>
                                <Button style={{ backgroundColor: 'green', width: '100%' }} onDoubleClick={() => deleteThemes(record.id)}>Yes</Button>
                            </div>
                        }
                        title="Please Double click to delete."
                    >
                        <Button>Delete</Button>
                    </Popover>
                </Space>
            ),
        },
    ];


    return (
        <div className="dataTable">
            <div className="filterMenu">
                <div>

                </div>
                <Input.Search onSearch={(value) => {
                    setSearchText(value)
                }}
                    onChange={(e) => {
                        setSearchText(e.target.value)
                    }}
                    className='searchField' placeholder='Search here...' />
            </div>
            <Table className='dataGrid' columns={columns} dataSource={contentData} pagination={{
                pageSize: pagination?.pageSize, onChange(page, pageSize) {
                    setPagination(pageSize)
                },
                showSizeChanger: true
            }} />
        </div>
    )
}

export default DataTableTheme