import React from "react";
import styles from "../styles/dashboard.module.scss";
import Text from "antd/lib/typography/Text";
import { useB2BContext } from "../context/ContextB2B";

function Footer() {
  const { getUserByID } = useB2BContext();

  return (
    <div className={"footer"}>
      <Text className={"title"}>
        © <b>SOULSARA</b>{" "}
        {getUserByID?.user_type == "Company_admin" ? "HR" : "Admin"} Dashboard
      </Text>
    </div>
  );
}

export default Footer;
