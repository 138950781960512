
import React from 'react'

function ComingSoon() {
    return (
        <div
            className='comingsoon_wrapper'
        >
            <h1>Coming Soon</h1>
        </div>
    )
}

export default ComingSoon