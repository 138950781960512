import React, { useEffect, useState } from "react";
import Text from "antd/lib/typography/Text";
import PieChart from "../../components/PieChart";
import styles from "../../styles/dashboard.module.scss";
import { authSVC } from "../../services";
import { useB2BContext } from "../../context/ContextB2B";
import moment from "moment";

export default function PieChartData({ levelsData }: any) {
  const [tapIndex1, setTapIndex1] = useState<number>(0);
  const { getUserByID } = useB2BContext();

  const [overallWellnessLevel, setoverallWellnessLevel] = useState<any[]>([]);
  const [overallHappinessLevel, setoverallHappinessLevel] = useState<any[]>([]);
  const [isNoData, setIsNoData] = useState(false);

  useEffect(() => {
    console.log(overallWellnessLevel, "overallWellnessLevel");
    console.log(overallHappinessLevel, "overallHappinessLevel");
    console.log(levelsData, "levelsData");
  }, [overallWellnessLevel, overallHappinessLevel, levelsData]);

  useEffect(() => {
    const month: any = moment().format("M");
    if (getUserByID) {
      authSVC
        .getOverallWellnesspieChartLevels(getUserByID?.company, Number(month))
        .then((res) => {
          setoverallWellnessLevel(res?.data?.data);
        })
        .catch((err) => {
          console.log(err);
        });
      authSVC
        .getOverallHappinesspieChartLevels(getUserByID?.company, Number(month))
        .then((res) => {
          setoverallHappinessLevel(res?.data?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [getUserByID]);

  useEffect(() => {
    const hasData = overallWellnessLevel.some(
      (item: any) => item.percentage !== null
    );
    setIsNoData(hasData);
  }, [overallWellnessLevel]);

  return (
    <div className={styles.white_bg_wrapper}>
      <div className={styles.info_title}>
        <div className={styles.title_grp}>
          <Text
            style={{
              color: tapIndex1 === 0 ? "" : "#2E3191",
              opacity: tapIndex1 === 0 ? "" : "0.3",
            }}
            className={styles.title}
            onClick={() => setTapIndex1(0)}
          >
            Wellness Index
          </Text>
          <Text
            style={{
              color: tapIndex1 === 1 ? "" : "#2E3191",
              opacity: tapIndex1 === 1 ? "" : "0.3",
            }}
            className={styles.title}
            onClick={() => setTapIndex1(1)}
          >
            Happiness Index
          </Text>
        </div>
        <Text className={styles.subtitle}>
          A lower Level indicates a less confused mental state
        </Text>
      </div>
      {isNoData ? (
        <>
          <div className={styles.graph}>
            <PieChart
              chartData={
                tapIndex1 === 0
                  ? overallWellnessLevel
                  : tapIndex1 === 1
                  ? overallHappinessLevel
                  : null
              }
            />
          </div>
          <div className={styles.levels_wrapper}>
            {levelsData?.map((item: any, index: number) => (
              <div className={styles.level} key={index}>
                <div
                  style={{ backgroundColor: item?.color }}
                  className={styles.round}
                />
                <Text className={styles.title}>Level {item?.level}</Text>
              </div>
            ))}
          </div>
        </>
      ) : (
        <div className="no_data_yet">
          <img src="/img/User_empty.png" alt="" width="56" height="52" />
          <p>No data yet</p>
        </div>
      )}
    </div>
  );
}
