import { AxiosPromise } from 'axios';

const API_PATH = process.env.REACT_APP_API_URL;


type API = {
  post: (url: string, payload: any) => AxiosPromise
  put: (url: string, payload: any) => AxiosPromise
  get: (url: string) => AxiosPromise
  delete: (id: string) => AxiosPromise
}

const oca = (api: API) => {

 
  const getOCAData = () => api.get(API_PATH + '/oca/all/')
  const getRoidata = () => api.get(API_PATH + '/roi/all/')

   

  return {
    getOCAData,
    getRoidata
  }
}

export default oca
