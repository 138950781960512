import React from "react";
import { Image } from "antd";

interface ImageUploaderProps {
  uploadedImage: any;
  image: any;
  handleFileUpload: (value: any) => void;
  borderRadius?: string;
  width?: string;
  height?: string;
}
const ImageUploaderOne = ({
  uploadedImage,
  image,
  handleFileUpload,
  borderRadius,
  width = "200px",
  height = "180px",
}: ImageUploaderProps) => {
  return (
    <div className="imageWrapper">
      {uploadedImage === null || uploadedImage === "" ? (
        <Image
          style={{
            width: width,
            height: height,
            borderRadius: borderRadius,
          }}
          src={"/image.png"}
          alt="Avatar"
          preview={false}
        />
      ) : (
        <Image
          style={{
            width: width,
            height: height,
            borderRadius: borderRadius,
          }}
          src={uploadedImage}
          alt="Avatar"
          preview={false}
        />
      )}
      {/* <div className='upload_icon'>
        <Image
            className='icon'
            src={'/img/new/camera.svg'}
            alt="Avatar"
            preview={false}
        />
    </div> */}

      <input
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          opacity: 0,
          cursor: "pointer",
        }}
        type="file"
        accept="image/*"
        title="Please upload a image"
        onChange={handleFileUpload}
      />
    </div>
  );
};

export default ImageUploaderOne;
