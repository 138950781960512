import { AxiosPromise } from 'axios';

const API_PATH = process.env.REACT_APP_API_URL;

type API = {
  post: (url: string, payload: any) => AxiosPromise
  put: (url: string, payload: any) => AxiosPromise
  get: (url: string) => AxiosPromise
  delete: (id: string) => AxiosPromise
}

const otherContents = (api: API) => {

   // other content
   const addOtherContent = (payload: any) => api.post(API_PATH + '/times/other-contents/', payload)
   const getOtherContent = () => api.get(API_PATH + '/times/other-contents/')
   const deleteOtherContent = (id: string) => api.delete(API_PATH + `/times/other-contents/${id}`)
   const editOtherContent = (id: string) => api.get(API_PATH + `/times/other-contents/${id}`)
   const updateOtherContent = (id: string, payload: any) => api.put(API_PATH + `/times/other-contents/${id}`, payload)
   const TFStoryCreate = (payload: any) => api.post(API_PATH + '/featured/create', payload)
 

  return {
    addOtherContent,
    getOtherContent,
    deleteOtherContent,
    editOtherContent,
    updateOtherContent,
    TFStoryCreate
  }
}

export default otherContents
