import { AxiosPromise } from 'axios';

const API_PATH = process.env.REACT_APP_API_URL;

type API = {
  post: (url: string, payload: any) => AxiosPromise
  put: (url: string, payload: any) => AxiosPromise
  get: (url: string) => AxiosPromise
  delete: (id: string) => AxiosPromise
}

const categoeries = (api: API) => {

   // other content
   const addCategories = (payload: any) => api.post(API_PATH + '/api/category', payload)
   const getCategories = () => api.get(API_PATH + '/api/category')
   const updateCategories = (id: string, payload: any) => api.put(API_PATH + `/api/category/${id}`, payload)
 

  return {
    addCategories,
    getCategories,
    updateCategories,
  }
}

export default categoeries
