import React, { useEffect, useState } from "react";
import "./datatable.scss";
import { Space, Table, Input, Button, Image, Tag, Popover } from "antd";
import type { ColumnsType } from "antd/es/table";
import moment from "moment";

interface Contentdata {
  key?: string;
  _id: number;
  title: string;
  type: string;
  content: string;
  link: string;
  image: string;
  sheduledDate: string;
}
// interface DataTableProps {
//     setshowModel: (value: boolean) => void,
//     setEditable: (value: boolean) => void,
//     deleteThought: (value: any) => void,
//     editContent:(value:any)=>void
//     isOpen: boolean,
//     contentData?: Contentdata[]
// }
function TimesFeaturedStoryTableContent({
  setshowModel,
  setEditable,
  isOpen,
  contentData,
  deleteOtherContent,
  editContent,
  showModal,
}: any) {
  const [searchText, setSearchText] = useState("");

  const columns: ColumnsType<Contentdata> = [
    {
      title: "ID",
      dataIndex: "_id",
      key: "id",
      filteredValue: [searchText],
      defaultSortOrder: "descend",
      sorter: (a: any, b: any) => a._id.localeCompare(b._id),
      onFilter: (value, record) => {
        return (
          String(record.title).toLowerCase().includes(value.toLocaleString()) ||
          String(record._id).toLowerCase().includes(value.toLocaleString()) ||
          String(record.content).toLowerCase().includes(value.toLocaleString())
        );
      },
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Content",
      dataIndex: "content",
      key: "content",
      render: (text) => (
        <>
          {/* <p>{text}</p> */}
          <p dangerouslySetInnerHTML={{ __html: text?.slice(0, 100) }} />
        </>
      ),
    },

    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      render: (_, record) => (
        <Space size="middle">
          <Image src={record.image} width={60} height={60} alt={"image"} />
        </Space>
      ),
    },
    // {
    //   title: "Status",
    //   dataIndex: "createdAt",
    //   key: "createdAt",
    //   render: (_, record) => (
    //     <Space size="middle">
    //       {record.sheduledDate === null ? (
    //         <Tag color={"green"}>Published</Tag>
    //       ) : moment().format("YYYY-MM-DD") >= record.sheduledDate ? (
    //         <Tag color={"green"}>Published</Tag>
    //       ) : (
    //         <Tag color={"red"}>Not Published</Tag>
    //       )}
    //     </Space>
    //   ),
    // },

    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={() => {
              setshowModel(!isOpen);
              setEditable(true);
              editContent(record._id);
            }}
            className="btn_edit_table"
          >
            Edit
          </Button>
          <Popover
            content={
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "10px",
                }}
              >
                <Button
                  style={{ backgroundColor: "green", width: "100%" }}
                  onDoubleClick={() => deleteOtherContent(record._id)}
                >
                  Yes
                </Button>
              </div>
            }
            title="Please Double click to delete."
          >
            <Button className="btn_edit_table">Delete</Button>
          </Popover>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    console.log(contentData, "contentData");
  }, [contentData]);
  return (
    <div className="dataTable">
      <div className="filterMenu">
        <Button onClick={showModal} className="add_button">
          Featured Story
        </Button>
        <Input.Search
          onSearch={(value) => {
            setSearchText(value);
          }}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
          className="searchField"
          placeholder="Search here..."
        />
      </div>
      <Table
        scroll={{ y: 500 }}
        className="dataGrid"
        columns={columns}
        dataSource={contentData}
        pagination={{ pageSize: 5 }}
      />
    </div>
  );
}

export default TimesFeaturedStoryTableContent;
