import React, { useState } from "react";
import "./datatable.scss";
import { Space, Table, Input, Button, Image, Popover } from "antd";
import type { ColumnsType } from "antd/es/table";

interface Contentdata {
  key?: string;
  _id: string;
  organization_name: string;
  logo: string;
  established_in: string;
  global_presence: any;
  description: string;
  cover_image: string;
  web_url: any;
}

interface DataTableProps {
  setshowModel: (value: boolean) => void;
  setEditable: (value: boolean) => void;
  deleteArticle: (value: any) => void;
  editContent: (value: any) => void;
  isOpen: boolean;
  contentData: Contentdata[];
}
function DataTableNGO({
  setshowModel,
  setEditable,
  isOpen,
  contentData,
  editContent,
  deleteArticle,
}: DataTableProps) {
  const [searchText, setSearchText] = useState("");
  const [pagination, setPagination] = useState<any>({ page: 1, pageSize: 5 });

  const columns: ColumnsType<Contentdata> = [
    {
      title: "ID",
      dataIndex: "_id",
      key: "_id",
      filteredValue: [searchText],
      defaultSortOrder: "descend",
      sorter: (a: any, b: any) => a._id.localeCompare(b._id),
      onFilter: (value, record) => {
        return (
          String(record._id).toLowerCase().includes(value.toLocaleString()) ||
          String(record.organization_name)
            .toLowerCase()
            .includes(value.toLocaleString()) ||
          String(record.global_presence)
            .toLowerCase()
            .includes(value.toLocaleString())
        );
      },
    },
    {
      title: "Organization Name",
      dataIndex: "organization_name",
      key: "organization_name",
    },
    {
      title: "Logo",
      dataIndex: "logo",
      key: "logo",
      render: (_, record) => (
        <Space size="middle">
          <Image src={record.logo} width={60} height={60} alt={"image"} />
        </Space>
      ),
    },
    {
      title: "Established In",
      dataIndex: "established_in",
      key: "established_in",
      width:110,
    },
    {
      title: "Global Presence",
      dataIndex: "global_presence",
      key: "global_presence",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width:100,
      render: (_, record) => (
        <p dangerouslySetInnerHTML={{ __html: record.description }} />
      ),
    },
    {
      title: "Cover Image",
      dataIndex: "cover_image",
      key: "cover_image",
      render: (_, record) => (
        <Space size="middle">
          <Image
            src={record.cover_image}
            width={50}
            height={50}
            alt={"image"}
          />
        </Space>
      ),
    },
    {
      title: "Web Url",
      dataIndex: "web_url",
      key: "web_url",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={() => {
              editContent(record._id);
              setshowModel(!isOpen);
              setEditable(true);
            }}
          >
            Update
          </Button>
          <Popover
            content={
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "10px",
                }}
              >
                <Button
                  style={{ backgroundColor: "green", width: "100%" }}
                  onDoubleClick={() => deleteArticle(record._id)}
                >
                  Delete
                </Button>
              </div>
            }
            title="Please Double click to delete."
          >
            <Button>Delete</Button>
          </Popover>
        </Space>
      ),
    },
  ];

  return (
    <div className="dataTable">
      <div className="filterMenu">
        <div></div>
        <Input.Search
          onSearch={(value) => {
            setSearchText(value);
          }}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
          className="searchField"
          placeholder="Search here..."
        />
      </div>
      <Table
        scroll={{ y: 300 }}
        className="dataGrid"
        columns={columns}
        dataSource={contentData}
        pagination={{
          pageSize: pagination?.pageSize,
          onChange(page, pageSize) {
            setPagination(pageSize);
          },
          showSizeChanger:true
        }}
      />
    </div>
  );
}

export default DataTableNGO;
