import { AxiosPromise } from 'axios';

const API_PATH = process.env.REACT_APP_API_URL;


type API = {
  post: (url: string, payload: any) => AxiosPromise
  put: (url: string, payload: any) => AxiosPromise
  get: (url: string) => AxiosPromise
  delete: (id: string) => AxiosPromise
}

const times = (api: API) => {
  // thought
  const addThoughts = (payload: any) => api.post(API_PATH + '/times/thoughts', payload)
  const getThoughts = () => api.get(API_PATH + '/times/thoughts')
  const getAllfeaturedStory = () => api.get(API_PATH + '/featured/all')
  const deleteThoughts = (id: string) => api.delete(API_PATH + `/times/thoughts/${id}`)
  const editThoughts = (id: string) => api.get(API_PATH + `/times/thoughts/${id}`)
  const updateThoughts = (id: string, payload: any) => api.put(API_PATH + `/times/thoughts/${id}`, payload)


 
 
  return {
    addThoughts,
    getThoughts,
    deleteThoughts,
    editThoughts,
    updateThoughts,
    getAllfeaturedStory
  }
}

export default times
