import React, { useEffect, useState } from "react";
import styles from "../../styles/dashboard.module.scss";
import { useB2BContext } from "../../context/ContextB2B";
import moment from "moment";
import { authSVC } from "../../services";
// import { useNavigate } from "react-router-dom";
import WellnessEngagementResult from "./WellnessEngagementResult";
import Summary from "./Summary";
import DepartmentsWellnessProgressBar from "./DepartmentsWellnessProgressBar";
import OverallWellnessIndexPieChart from "./OverallWellnessIndexPieChart";
import HappinessBarchart from "./HappinessBarchart";
import DepartmentHappinessIndex from "./DepartmentHappinessIndex";
import OverallHappinessIndex from "./OverallHappinessIndex";
import WellnessBarchart from "./WellnessBarchart";

const levelsData = [
  {
    level: 0,
    color: "#FFDAEE",
  },
  {
    level: 1,
    color: "#FCD6E9",
  },
  {
    level: 2,
    color: "#F9BCDC",
  },
  {
    level: 3,
    color: "#F69FCC",
  },
  {
    level: 4,
    color: "#F168B1",
  },
  {
    level: 5,
    color: "#F04BA1",
  },
  {
    level: 6,
    color: "#EC3192",
  },
  {
    level: 7,
    color: "#EB1481",
  },
  {
    level: 8,
    color: "#CE1273",
  },
];
function Insights() {
  const [overallLevel, setoverallLevel] = useState<any>();
  const { getUserByID } = useB2BContext();
  // const navigate = useNavigate();

  useEffect(() => {
    const month: any = moment().format("M");
    const year: any = moment().format("yyyy");

    authSVC
      .getoverAllViewLevels(getUserByID?.company, Number(month), Number(year))
      .then((res) => {
        setoverallLevel(res?.data?.data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [getUserByID]);


  return (
    <div className={styles.wellness_report_main_wrapper}>
      <div className={styles.col_2_div}>
        {/* Left Container */}
        <div className={styles.data_container}>
          <WellnessEngagementResult overallLevel={overallLevel} />
        </div>
        {/* Right Container */}
        <div className={styles.data_container}>
          <Summary overallLevel={overallLevel} />
        </div>
      </div>
      <div className={styles.border}></div>
      <div className={styles.col_2_div}>
        {/* Left Container */}
        <div className={styles.data_container}>
          <WellnessBarchart />
          <DepartmentsWellnessProgressBar />
          <OverallWellnessIndexPieChart levelsData={levelsData} />
        </div>
        {/* Right Container */}
        <div className={styles.data_container}>
          <HappinessBarchart />

          <DepartmentHappinessIndex />

          <OverallHappinessIndex levelsData={levelsData} />
        </div>
      </div>
    </div>
  );
}

export default Insights;
