import React, { useEffect, useState } from "react";
import { Button, Typography, Input, message, ColorPicker, Form } from "antd";
import { authSVC } from "../../services";
import { useB2BContext } from "../../context/ContextB2B";
import type { ColorPickerProps } from "antd";

// const { Text } = Typography;

const departmmentColors = [
  "#479751",
  "#CB2E89",
  "#9EC756",
  "#D476AA",
  "#F5EE56",
  "#65529C",
  "#EBB949",
  "#84559D",
  "#E6893C",
  "#233264",
  "#D56135",
  "#9891C2",
  "#D94834",
  "#3170B5",
  "#B93347",
  "#4FA1A5",
];

function AddDepartments() {
  const { getUserByID } = useB2BContext();
  const [loading, setloading] = useState<boolean>(false);
  const [allDepartments, setAllDepartments] = useState<any>();
  const [departmentName, setDepartmentName] = useState<any>("");
  const [form] = Form.useForm();

  const [colorValue, setColorValue] = useState<ColorPickerProps["value"]>("");

  const getDepartments = () => {
    authSVC
      .getCompanyDepartments(getUserByID?.company)
      .then((res: any) => {
        setAllDepartments(res.data.data);
      })
      .catch((err: any) => {
        message.error(err.message);
      });
  };

  const onFinish = () => {
    setloading(true);
    if (getUserByID?.company) {
      const existingDepartment = allDepartments?.find(
        (dept: any) => dept.name === departmentName
      );

      if (existingDepartment) {
        message.info(
          "Department already exists. Please choose a different name."
        );
        setloading(true);
      } else {
        if (colorValue === "") {
          message.info("Please Select Department Color");
          setloading(true);
        } else {
          authSVC
            .addCompanyDepartments({
              companyName: getUserByID?.company,
              departments: [
                {
                  name: departmentName,
                  color: colorValue,
                },
              ],
            })
            .then((res: any) => {
              message.success("Added Successfully");
              form.resetFields();
              setColorValue("");
              getDepartments();
              setloading(false);
            })
            .catch((err: any) => {
              message.error(err.message);
            });
        }
      }
    }
  };

  const nameValidation = (_: any, value: any) => {
    // Use a regular expression to validate that the input contains only letters
    const regex = /^[A-Za-z\s]+$/;

    if (!value || regex.test(value)) {
      return Promise.resolve();
    }

    return Promise.reject(
      new Error("Please enter a valid name with only letters.")
    );
  };

  const changeColor = (item: any) => {
    console.log(item);
    setColorValue(item);
    setloading(false);
    if (colorValue !== item) {
      message.info(`Selected Department color is ${item}`);
    }
  };

  return (
    <div className="add_form_wrapper">
      <h1>Create Department</h1>
      <Form
        name="normal_login"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        form={form}
      >
        <Form.Item
          name="name"
          rules={[
            {
              required: true,
              message: "Please Enter Department Name",
            },
            {
              validator: nameValidation,
            },
          ]}
          style={{ marginBottom: "16px" }}
        >
          <Input
            name="department"
            className="input"
            onChange={(e: any) => {
              setDepartmentName(e?.target?.value);
              setloading(false);
            }}
            type="text"
            placeholder="Please Enter Department Name"
          />
        </Form.Item>

        <Form.Item name={"color"} style={{ marginBottom: "16px" }}>
          <div className="departmentColors">
            {departmmentColors?.map((item, index) => (
              <div
                key={index}
                onClick={() => changeColor(item)}
                // onClick={() => {
                //   setColorValue(item);
                //   message.info(`Selected Department color is ${item}`);
                // }}
                style={{ backgroundColor: item }}
                className="colorBox"
              />
            ))}
            <ColorPicker
              value={colorValue}
              onChangeComplete={(color) => {
                setColorValue(color.toHexString());
                message.info(
                  `Selected Department color is ${color.toHexString()}`
                );
              }}
            />
          </div>
        </Form.Item>
        <p style={{ marginBottom: "16px" }}>
          Before Adding Employees, It’s Essential To Create Departments. This
          Enhances The Wellness Tracking Experience.{" "}
        </p>
        <Button
          className="cn_btn"
          htmlType="submit"
          disabled={loading ? true : false}
        >
          Create Department
        </Button>
      </Form>
    </div>
  );
}

export default AddDepartments;
