import { AxiosPromise } from 'axios';

const API_PATH = process.env.REACT_APP_API_URL;

type API = {
  post: (url: string, payload: any) => AxiosPromise
  put: (url: string, payload: any) => AxiosPromise
  get: (url: string) => AxiosPromise
  delete: (id: string) => AxiosPromise
}

const fourm = (api: API) => {

   // other content
   const getfourm = () => api.get(API_PATH + '/post/all')
   const deletefourm = (id: string) => api.delete(API_PATH + `/post/delete/${id}`)
   const editfourm = (id: string) => api.get(API_PATH + `/post/${id}`)
   const updatefourm = (id: any, payload: any) => api.put(API_PATH + `/post/update-status/${id}`, payload)
 

  return {
    getfourm,
    deletefourm,
    editfourm,
    updatefourm,
  }
}

export default fourm
