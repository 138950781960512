import React, { useEffect, useRef } from "react";
import "./styles/global.scss";
import styles from "./styles/dashboard.module.scss";

import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  Navigate,
  RouteObject,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Navbar from "./components/Navbar";
import Menu from "./components/Menu";
import Footer from "./components/Footer";
import ThoughtForweek from "./pages/thoughtforweek/ThoughtForweek";
import FeaturedItems from "./pages/featureditems/FeaturedItems";
import LatestVideos from "./pages/latestvideos/LatestVideos";
import NewsonWellness from "./pages/newsonwellness/NewsonWellness";
import Takeaway from "./pages/takeaway/Takeaway";
import WomeninSports from "./pages/womeninsports/WomeninSports";
import Article from "./pages/article/Article";
import Login from "./pages/auth/Login";
import helper from "./utils/helper";
import Themes from "./pages/themes/Themes";
import CrisisManagementTools from "./pages/crisismanagementtools/CrisisManagementTools";
import FourmPage from "./pages/forum/fourm";
import RegisterNGO from "./pages/register_ngo/RegisterNGO";
import NGOArticle from "./pages/ngo_article/NGOArticle";
import Blog from "./pages/blog/Blog";
import ApproveProducts from "./pages/approve_products/ApproveProducts";
import Employees from "./pages/employees/Employees";
import Audit from "./pages/employees-audit/Audit";
import Plan from "./pages/Plan/Plan";
import ComingSoon from "./pages/comingsoon/ComingSoon";
import Profile from "./pages/Profile/Profile";
import { B2BContextProvider } from "./context/ContextB2B";
import Clients from "./pages/clients/Clients";
import SoulsaraEventVideos from "./pages/soulsaraeventsvideos/SoulsaraEventsVideos";
import SoulsaraShortVideos from "./pages/soulsaraeventshortvideos/SoulsaraEventsShortVideos";
import Forbidden from "./pages/forbidden/ComingSoon";
import DemoSchedule from "./pages/demo-schedule/DemoSchedule";
// import B2bAgreement from "./pages/subscription_agreement/B2bAgreement";
import SoulsaraAgreement from "./pages/subscription_agreement/SoulsaraAgreement";
import HelpLinePage from "./pages/helpline/HelpLine";
import CSVDownload from "./pages/download_data/DownloadCsv";
import TimesFeaturedStory from "./pages/timesfeaturedstory/TimesFeaturedStory";
import LinkGenerate from "./pages/linkgenerate/link_generate";

import Overview from "./pages/overview/Overview";
import Insights from "./pages/insights/Insights";
import Text from "antd/lib/typography/Text";
import { Image, message } from "antd";
import Departments from "./pages/departments/Departments";
import InsightsSingle from "./pages/insights_single/InsightsSingle";
import AddEmployees from "./pages/addemployees/AddEmployees";
import EditEmployees from "./pages/editemployees/EditEmployees";
import AddDepartments from "./pages/adddepartments/AddDepartments";
import HelpLineHistory from "./pages/helpline_history/HelplineHistory";
import AdminOverview from "./pages/adminoverview/AdminOverview";
import Webinar from "./pages/webinar/Webinar";
import OCA from "./pages/oca/OCA";
import EditDepartments from "./pages/editdepartments/EditDepartments";
import { authSVC } from "./services";
import { useB2BContext } from "./context/ContextB2B";

const restrictedRoutesForUsers = [
  "/forum",
  "/linkgenerate",
  "/thought",
  "/womeninsports",
  "/takeaway",
  "/times-featured-story",
  "/newsonwellness",
  "/latestvideos",
  "/soulsaraeventvideos",
  "/soulsarashortvideos",
  "/featureditems",
  "/crisismanagementtools",
  "/article",
  "/soulsaraeventvideos",
  "/soulsarashortvideos",
  "/register_ngo",
  "/blog",
  "/approve-products",
  "/clients",
  // "/wellness",
  "/analytics_overview",
  "/analytics_growth",
  "/analytics_engagement",
  "/analytics_revenue",
  "/profile_setting",
  "/preference_setting",
  "/notification",
  "/security",
  "/download-csv",
  "/helpline_history",
  "/admin_overview",
  "/webinar",
  "/oca",
];

function App() {
  const { getUserByID } = useB2BContext();

  // useEffect(() => {
  //   const time = setInterval(() => {
  //     if (!helper.isLogedin()) {
  //       return <Navigate to="/login" />;
  //     } else if (helper.isLogedin()) {
  //       return <Navigate to="/" />;
  //     }
  //   }, 50000);

  //   return () => clearInterval(time);
  // });

  const windowSize = useRef([window.innerWidth, window.innerHeight]);

  const Layout = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const userType: any = localStorage.getItem("user_type");
    const userPackage: any = localStorage.getItem("userPackage");

    useEffect(() => {
      if (userType !== "Super_admin" && location.pathname === "/") {
        if (userPackage === "null") {
          navigate("/subscription_agreement");
        } else {
          navigate("/overview");
        }
      }
      if (userType === "Super_admin" && location.pathname === "/") {
        navigate("/demo-requests");
      }
    }, [userType]);

    return (
      <div
        className={
          userType !== "Super_admin"
            ? `${styles.main_wrapper} ${styles.hr_dashborad}`
            : styles.main_wrapper
        }
      >
        <div className={styles.content_wrapper}>
          <Navbar />
          <div className={styles.body_wrapper}>
            <div
              className={styles.menuContainer}
              style={{ height: `${windowSize.current[1]}` }}
            >
              <Menu />
              <div className={styles.listTitle_wrapper}>
                {/* <div
                  className={styles.listTitle}
                  onClick={() =>
                    window.open("https://www.soulsara.world/contact-us")
                  }
                >
                  <Text className={styles.title}>Contact Us</Text>{" "}
                  <Image src="/img/new/arrow1.svg" preview={false} />
                </div> */}
                <div
                  className={styles.listTitle}
                  onClick={() => window.open("https://soulsara.world/")}
                >
                  <Text className={styles.title}>Employee Dashboard</Text>
                  <Image src="/img/new/arrow1.svg" preview={false} />
                </div>
                <div
                  className={styles.listTitle}
                  onClick={() => {
                    helper.logOut();
                    localStorage.removeItem("accessToken");
                    localStorage.removeItem("user_type");
                    message.success("Logout Successfully");
                    navigate("/login");
                  }}
                >
                  <Text className={styles.title}>Logout</Text>
                </div>
              </div>
              <Footer />
            </div>
            <div className={styles.contentContainer}>
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    );
  };
  const ProtectedRoute: any = ({ path, element }: RouteObject) => {
    const location = useLocation();
    const userType: any = localStorage.getItem("user_type");

    if (!helper.isLogedin() && location.pathname !== "/login") {
      return <Navigate to="/login" />;
    } else if (helper.isLogedin() && location.pathname === "/login") {
      return <Navigate to="/" />;
    } else if (
      helper.isLogedin() &&
      userType === "Company_admin" &&
      restrictedRoutesForUsers.includes(location.pathname)
    ) {
      return <Navigate to="/" />;
    }

    return element;
  };

  const router: any = createBrowserRouter([
    {
      path: "/",
      element: <ProtectedRoute element={<Layout />} />,
      children: [
        {
          path: "/oca",
          element: <OCA />,
        },
        {
          path: "/admin_overview",
          element: <AdminOverview />,
        },
        {
          path: "/overview",
          element: <Overview />,
        },
        {
          path: "/subscription_agreement",
          element: <SoulsaraAgreement />,
        },

        {
          path: "/addemployees",
          element: <AddEmployees />,
        },
        {
          path: "/editemployees",
          element: <EditEmployees />,
        },
        {
          path: "/adddepartments",
          element: <AddDepartments />,
        },
        {
          path: "/editdepartments",
          element: <EditDepartments />,
        },
        {
          path: "/departments",
          element: <Departments />,
        },
        {
          path: "/insights",
          element: <Insights />,
        },
        {
          path: "/insights_single",
          element: <InsightsSingle />,
        },
        {
          path: "/demo-requests",
          element: <DemoSchedule />,
        },
        {
          path: "/help-line",
          element: <HelpLinePage />,
        },
        {
          path: "/forum",
          element: <FourmPage />,
        },
        {
          path: "/thought",
          element: <ThoughtForweek />,
        },
        {
          path: "/womeninsports",
          element: <WomeninSports />,
        },
        {
          path: "/takeaway",
          element: <Takeaway />,
        },
        {
          path: "/times-featured-story",
          element: <TimesFeaturedStory />,
        },
        {
          path: "/newsonwellness",
          element: <NewsonWellness />,
        },
        {
          path: "/latestvideos",
          element: <LatestVideos />,
        },
        {
          path: "/soulsaraeventvideos",
          element: <SoulsaraEventVideos />,
        },
        {
          path: "/soulsarashortvideos",
          element: <SoulsaraShortVideos />,
        },
        {
          path: "/featureditems",
          element: <FeaturedItems />,
        },
        {
          path: "/crisismanagementtools",
          element: <CrisisManagementTools />,
        },
        {
          path: "/article",
          element: <Article />,
        },
        {
          path: "/themes",
          element: <Themes />,
        },
        {
          path: "/ngo_article",
          element: <NGOArticle />,
        },
        {
          path: "/webinar",
          element: <Webinar />,
        },
        {
          path: "/register_ngo",
          element: <RegisterNGO />,
        },
        {
          path: "/blog",
          element: <Blog />,
        },
        {
          path: "/helpline_history",
          element: <HelpLineHistory />,
        },
        {
          path: "/approve-products",
          element: <ApproveProducts />,
        },
        { path: "/manage_exployees", element: <Employees /> },
        { path: "/add_department", element: <Departments /> },
        { path: "/employees-audit", element: <Audit /> },
        { path: "/plan", element: <Plan /> },
        { path: "/wellness_audit", element: <ComingSoon /> },
        { path: "/wellness_tracker", element: <ComingSoon /> },
        { path: "/reports", element: <ComingSoon /> },
        { path: "/download-csv", element: <CSVDownload /> },
        { path: "/hrprofile", element: <Profile /> },
        { path: "/notifications", element: <ComingSoon /> },
        { path: "/language", element: <ComingSoon /> },
        { path: "/contactus", element: <ComingSoon /> },
        {
          path: "/clients",
          element: <Clients />,
        },
        {
          path: "/plan",
          element: <Plan />,
        },
        // {
        //   path: "/wellness",
        //   element: <ComingSoon />,
        // },
        {
          path: "/analytics_overview",
          element: <ComingSoon />,
        },
        {
          path: "/analytics_growth",
          element: <ComingSoon />,
        },
        {
          path: "/analytics_engagement",
          element: <ComingSoon />,
        },
        {
          path: "/analytics_revenue",
          element: <ComingSoon />,
        },
        {
          path: "events",
          element: <Profile />,
        },
        {
          path: "/preference_setting",
          element: <ComingSoon />,
        },
        {
          path: "/notification",
          element: <ComingSoon />,
        },
        {
          path: "/security",
          element: <ComingSoon />,
        },
        {
          path: "/Forbidden",
          element: <Forbidden />,
        },
        {
          path: "/linkgenerate",
          element: <LinkGenerate />,
        },
      ],
    },
    {
      path: "/login",
      element: <ProtectedRoute element={<Login />} />,
    },
  ]);

  return (
    <B2BContextProvider>
      <RouterProvider router={router} />
    </B2BContextProvider>
  );
}

export default App;
