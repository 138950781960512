import React, { useEffect, useState } from "react";
import { Button, Typography, Modal, message } from "antd";
import { useB2BContext } from "../../context/ContextB2B";
import moment from "moment";
import { authSVC } from "../../services";
import { useNavigate } from "react-router-dom";
const { Text } = Typography;

function Plan() {
  const { paymentDetails, getUserByID, setPaymentDetails, allPackageList } =
    useB2BContext();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenaddons, setIsModalOpenaddons] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [viewAllModules, setViewAllModules] = useState<any>();
  const navigate = useNavigate();

  useEffect(() => {
    console.log(allPackageList, "allPackageList");
  }, [allPackageList]);

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleOkAddons = () => {
    setIsModalOpenaddons(false);
  };

  const handleCancelAddons = () => {
    setIsModalOpenaddons(false);
  };

  const openPaymentUpdate = () => {
    setIsModalOpen(true);
  };

  const ClickUpdate = () => {
    authSVC
      .MembershipUpdates({
        user: getUserByID._id,
        package: selectedOption,
      })
      .then((res: any) => {
        message.success("Updated Successfully");
        setIsModalOpen(false);
        navigate("/plan");
      })
      .catch((err: any) => {
        message.error(err.message);
      });
  };

  useEffect(() => {
    if (getUserByID?.is_payment === true) {
      authSVC
        .GetModules(
          getUserByID?.company_id?._id,
          getUserByID?.company_id?.package[0]?.package_id?._id
        )
        .then((res: any) => {
          setViewAllModules(res.data);
          console.log(res.data, "setViewAllModules");
        })
        .catch((err: any) => {
          message.error(err.message);
        });
    }
  }, [getUserByID]);

  return (
    <div className="manage_plan_wrapper">
      <div className="plan_info">
        <Text className="title24">Subscription Plan</Text>
        <div className="content_wrapper subscription">
          {paymentDetails ? (
            <>
              <div className="plan_card">
                <div className="cardHead">
                  <h1>{paymentDetails?.subscription}</h1>
                  <div className="border"></div>
                  <p className="next">Active Plan</p>
                  <p className="price">₹{paymentDetails?.purchase_price}</p>
                  <p className="date">
                    Expires On{" "}
                    {moment(paymentDetails?.date_of_purchase).format(
                      "DD MMM YYYY"
                    )}
                  </p>
                  <button
                    className="view_addons"
                    onClick={() => setIsModalOpenaddons(true)}
                  >
                    View add-ons
                    <img src="/img/view_addons.svg" alt="" />
                  </button>
                </div>
                <div className="cardFooter">
                  <Button className="btn" onClick={openPaymentUpdate}>
                    Change Plan
                  </Button>
                </div>
              </div>
            </>
          ) : (
            <p
              style={{
                color: "red",
                fontSize: "14px",
                fontWeight: "bold",
                marginTop: "24px",
              }}
            >
              Your subscription plan not activated yet.
            </p>
          )}
        </div>
      </div>
      <Modal
        footer={false}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        className="subscribe_popup"
      >
        <div className="top_box">
          <img src="/Group14181.svg" alt="" />
          <h3>Confirm Membership Plan</h3>
        </div>
        <div className="plan_choos_wrapper">
          <div className="line">
            <p>Current Plan</p>
            <h3>{paymentDetails?.subscription}</h3>
          </div>
          <div className="line">
            <p>Change To</p>
            {/* {allPackageList && (
              <Form.Item
                label="Package Name"
                name="package_name"
                rules={[
                  { required: true, message: "Please input Package Name!" },
                ]}
                className="form_item_blue"
              >
                <Select
                  onChange={handleChange}
                  options={allPackageList.map((option: any) => ({
                    value: option._id,
                    label: option.package,
                  }))}
                />
              </Form.Item>
            )} */}
            <select
              name=""
              id=""
              onChange={(e) => setSelectedOption(e.target.value)}
            >
              {allPackageList &&
                allPackageList.map((option: any) => {
                  return (
                    paymentDetails?.subscription !== option.package && (
                      <option value={option._id}>{option.package}</option>
                    )
                  );
                })}
            </select>
          </div>
        </div>
        <button className="continue_btn" onClick={ClickUpdate}>
          Continue
        </button>
      </Modal>
      <Modal
        footer={false}
        open={isModalOpenaddons}
        onOk={handleOkAddons}
        onCancel={handleCancelAddons}
        className="subscribe_popup"
      >
        <div className="top_box">
          <img src="img/plan_icon01.svg" alt="" />
          <p className="sub_text">Subscription Plan</p>
          <h2>{paymentDetails?.subscription}</h2>
        </div>

        <div className="view_addons_item_wrapper">
          {viewAllModules &&
            viewAllModules?.modules.map((module: any) => {
              return (
                <>
                  <h3>{module.moduleName}</h3>
                  <div className="plan_item_set">
                    <ul>
                      {module.moduleItem.map((data: any) => {
                        return (
                          <li>
                            <img src="/img/Group18838.svg" alt="" />
                            <span>{data.label}</span>
                            {/* <img src="/img/Group13323.svg" alt="" /> */}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </>
              );
            })}

          <div className="plan_item_set">
            {/* <ul> */}
            {/* {viewAllModules &&
                viewAllModules?.modules.map((module: any) => {
                  <></>
                  <h2>>{module.moduleName}</h2>
                  <li>
                    <img src="/img/Group18838.svg" alt="" />
                    <span>
                      Wellness Psychometric Profile Summary (02/04 per month)
                    </span>
                    <img src="/img/Group13323.svg" alt="" />
                  </li>;
                })} */}
            {/* <li>
                <img src="/img/Group18838.svg" alt="" />
                <span>
                  Wellness Psychometric Profile (Psychometric Assessment)
                </span>
                <img src="/img/Group13323.svg" alt="" />
              </li>
               */}
            {/* </ul> */}
          </div>
          {/* {viewAllModules &&
            viewAllModules.map((module: any) => (
              <div key={module.moduleName} className="checkbox_list_wrappe">
                <h4>{module.moduleName}</h4>
                <div className="checkbox_set">
                  {module.moduleItem.map((item: any) => (
                    <div key={item._id}></div>
                  ))}
                </div>
              </div>
            ))} */}
        </div>
      </Modal>
    </div>
  );
}

export default Plan;
