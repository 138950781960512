import React, { useEffect, useState } from "react";
import "./datatable.scss";
import { Space, Table, Input, Button, message, Form, Select } from "antd";
import { Checkbox } from "antd";
import { Modal, Popover } from "antd";
import type { ColumnsType } from "antd/es/table";
import { authSVC } from "../../../services";
import { useB2BContext } from "../../../context/ContextB2B";
// import membermodules from "../../../data/membermodules.json";
import CheckboxList from "./CheckboxList";
import PaymentHDFC from "../../../pages/subscription_agreement/PaymentHDFC";
interface Contentdata {
  payment: any;
  key?: string;
  _id: string;
  bodyText: string;
  user_id: string;
  video_id: string;
  email: string;
  full_name: string;
  company: string;
  package_name: string;
  // company_id?: {
  //   package?: Array<any>;
  // };
  company_id: any;
}

export interface ModuleChild {
  _id: string;
  moduleCode: string;
  label: string;
  category: string;
  allowAccess: boolean;
  child: ModuleChild[];
}

export interface ModuleAccess {
  module_id: string;
  enable: boolean | undefined;
}

export interface ModulesData {
  modules: ModuleChild[];
}

export interface CheckboxListProps {
  items: string[]; // The list of items for checkboxes
  preSelected?: string[]; // Optional list of pre-selected items
}

function CommentsDataTable({ deleteComments, contentData }: any) {
  const {
    setAllPackageList,
    allPackageList,
    getUserByID,
    selectedModulesList,
  } = useB2BContext();
  const [searchText, setSearchText] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userId, setUserId] = useState<any>();
  const [userData, setUserData] = useState<any>();
  const [btnDisable, setBtnDisable] = useState(true);
  const [pagination, setPagination] = useState<any>({ page: 1, pageSize: 5 });
  const [memberModules, setMemberModules] = useState<any>([]);
  const [selectedModules, setSelectedModules] = useState<ModuleAccess[]>([]);
  const [packageId, setPackageId] = useState<any>();
  const [companyId, setCompanyId] = useState<any>();
  const [preSelectPackages, setPreSelectPackages] = useState<any>([]);
  const [mapModuleAccessList, setMapModuleAccessList] = useState();
  const [paymentDuration, setPaymentDuration] = useState("");
  const [isAmount, setIsAmount] = useState<any>(0);
  const [isPricePerPerson, setPricePerPerson] = useState<any>(0);
  const [isEmployees, setEmployees] = useState<any>(0);
  const [taxAmount, setTaxAmount] = useState<any>(0);
  const [amountWithOutTax, setAmountWithOutTax] = useState<any>(0);
  const [cgst, setCgst] = useState<any>(0);
  const [sgst, setSgst] = useState<any>(0);
  const [packagePricingBreakdown, setPackagePricingBreakDown] = useState<any>(
    {}
  );
  // const onChangeAmount = (e: any) => {
  //   setIsAmount(e.target.value);
  // };

  const onChangePackageAmount = (e: any) => {
    console.log(e, "amount changes");
    setIsAmount(e.target.value);
  };

  useEffect(() => {
    setPackagePricingBreakDown({});
    console.log(
      `Exsisting breakdown ${JSON.stringify(packagePricingBreakdown)}`
    );
    let tempPackagePricing = packagePricingBreakdown;
    const packageAmount = isAmount;
    tempPackagePricing.packageAmount = packageAmount;
    tempPackagePricing.igstTax = packageAmount * 0.18;
    tempPackagePricing.cgstTax = packageAmount * 0.09;
    tempPackagePricing.sgstTax = tempPackagePricing.packageAmount * 0.09;
    tempPackagePricing.subTotalAmount =
      parseInt(tempPackagePricing.packageAmount) +
      parseInt(tempPackagePricing.igstTax);

    setPackagePricingBreakDown(tempPackagePricing);
    console.log(`New breakdowen ${JSON.stringify(packagePricingBreakdown)}`);
  }, [isAmount, packagePricingBreakdown]);

  const onChangePricePerPerson = (e: any) => {
    setPricePerPerson(e.target.value);
  };
  const onChangeEmployees = (e: any) => {
    setEmployees(e.target.value);
  };

  const handleChange = (value: string) => {
    setPackageId(value);
  };

  useEffect(() => {
    const reduction = (isAmount * 18) / 100; // Calculate 18% of the amount
    const finalAmount = isAmount - reduction; // Subtract reduction from the original amount
    setAmountWithOutTax(finalAmount.toFixed(2));
    setTaxAmount((isAmount - amountWithOutTax).toFixed(2));
    setCgst((taxAmount / 2).toFixed(2));
    setSgst((taxAmount / 2).toFixed(2));
  }, [isAmount, amountWithOutTax, taxAmount]);

  const VerifiedPackage = (event: any) => {
    if (event.target.checked === true) {
      setBtnDisable(false);
    } else {
      setBtnDisable(true);
    }
  };

  useEffect(() => {
    if (selectedModulesList && packageId) {
      let mapModuleAccess = [] as any;
      selectedModulesList.forEach((value: any) => {
        let tempPayload = {} as {
          package_id: string;
          module_id: string;
          enable: boolean;
        };
        tempPayload.package_id = packageId;
        tempPayload.module_id = value;
        tempPayload.enable = true;
        mapModuleAccess.push(tempPayload);
      });
      setMapModuleAccessList(mapModuleAccess);
    }
  }, [selectedModulesList, packageId]);

  useEffect(() => {
    if (packageId && userData) {
      setTimeout(() => {
        authSVC
          .GetModules(userData?.company_id?._id, packageId)
          .then((res: any) => {
            setPreSelectPackages(res.data.pre_select_packages);
          })
          .catch((err: any) => {
            message.error(err.message);
          });
      }, 1000);
    }
  }, [packageId, userData]);

  // useEffect(() => {
  //   if (selectedModules.length !== 0) {
  //     setBtnDisable(false);
  //   } else {
  //     setBtnDisable(true);
  //   }
  // }, [selectedModules]);

  const handleChangeDuration = (value: any) => {
    setPaymentDuration(value);
    console.log(value);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    window.location.reload();
  };

  const onFinish = (values: {
    package_name: string;
    package_price: string;
    unitPrice: string;
  }) => {
    let pkgDetails: any = {};
    pkgDetails = {
      company_id: userData.company_id._id,
      package: {
        package_id: values.package_name,
        price: values.package_price,
        unit_price_per_employee: isPricePerPerson,
        total_unit_employee: isEmployees,
        package_duration: paymentDuration,
      },
      mapModuleAccess: mapModuleAccessList,
    };
    authSVC
      .postMapModules(pkgDetails)
      .then((res: any) => {
        message.success("Payment Details Added");
        setIsModalOpen(false);
      })
      .catch((err: any) => {
        message.error(err.message);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const getUserData = async (uemail: string) => {
    authSVC
      .getByUEmail(uemail)
      .then((res: any) => {
        setUserData(res.data.data);
        console.log(res.data.data.company_id.package.length, "User Data");
      })
      .catch((err: any) => {
        message.error(err.message);
      });
  };

  const AllModule = async () => {
    authSVC
      .getAllModule()
      .then((res: any) => {
        setMemberModules(res.data.modules);
      })
      .catch((err: any) => {
        message.error(err.message);
      });
  };

  const AllPackage = async () => {
    authSVC
      .getAllPackage()
      .then((res: any) => {
        setAllPackageList(res.data.package);
      })
      .catch((err: any) => {
        message.error(err.message);
      });
  };

  const addPaymentDetails = (
    vid: string,
    uemail: string,
    company_id: string
  ) => {
    setIsModalOpen(true);
    setUserId(vid);
    setCompanyId(company_id);
    getUserData(uemail);
    AllModule();
    AllPackage();
    console.log(userData, "userData");
  };

  const findItemById = (id: any) => {
    return contentData.find((item: any) => item._id === id);
  };
  const AdminApprovePayment = (vid: string, email: string, record: any) => {
    const result = findItemById(vid);
    authSVC
      .approvePayment({
        user_id: result.payment[0]._id,
        company_id: result.payment[0].company_id,
      })
      .then((res: any) => {
        getUserData(email);
        window.location.reload();
        message.success("Payment Approved");
      })
      .catch((err: any) => {
        message.error(
          "Map module is not done for this account, please map module"
        );
      });
  };

  //   UpdatePackegeData

  const columns: ColumnsType<Contentdata> = [
    {
      title: "ID",
      dataIndex: "_id",
      key: "_id",
      width: 150,
      filteredValue: [searchText],
      render: (text) => <div className="truncate">{text}</div>,
      sorter: (a: any, b: any) => a._id.localeCompare(b._id),
      onFilter: (value, record) => {
        return (
          String(record.email).toLowerCase().includes(value.toLocaleString()) ||
          String(record.full_name)
            .toLowerCase()
            .includes(value.toLocaleString()) ||
          String(record._id).toLowerCase().includes(value.toLocaleString())
        );
      },
    },
    {
      sorter: (a: any, b: any) => a.full_name.localeCompare(b.full_name),
      title: "Name",
      width: 150,
      dataIndex: "full_name",
      key: "full_name",
    },
    {
      title: "Company Name",
      width: 150,
      dataIndex: "company",
      key: "company",
    },
    {
      title: "Company Email",
      dataIndex: "email",
      width: 250,
      key: "email",
    },
    // {
    //   title: "Package Count",
    //   key: "packageCount",
    //   render: (_, record: Contentdata) => {
    //     console.log("Record:", record); // Debugging
    //     return record.company_id?.package?.length || 0;
    //   },
    // },
    {
      title: "Package",
      key: "action",
      render: (_, record: any) => (
        <Space size="middle">
          <Button
            onClick={() =>
              addPaymentDetails(record._id, record.email, record.company_id)
            }
            className="btn_edit_table"
          >
            Assign
          </Button>
        </Space>
      ),
    },
    {
      title: "Payment",
      key: "action",
      render: (_, record) =>
        record.payment.length > 0 && !record.payment[0].is_payment ? (
          <Space size="middle">
            <Popover
              content={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <p style={{ color: "#2E3191", fontSize: "12px" }}>
                    Are You Confirm to Approve?
                  </p>
                  <Button
                    style={{ backgroundColor: "green", color: "#fff" }}
                    onClick={() =>
                      AdminApprovePayment(record._id, record.email, record)
                    }
                  >
                    Yes
                  </Button>
                </div>
              }
              title="Update Status"
            >
              <Button className="btn_edit_table">Approve</Button>
            </Popover>
          </Space>
        ) : record.payment.length > 0 ? (
          <Button className="btn_edit_table" disabled={true}>
            Approved
          </Button>
        ) : (
          ""
        ),
    },
  ];

  return (
    <div className="dataTable">
      <div className="filterMenu">
        <div></div>
        <Input.Search
          onSearch={(value) => {
            setSearchText(value);
          }}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
          className="searchField"
          placeholder="Search here..."
        />
      </div>
      <Table
        className="dataGrid"
        tableLayout="fixed"
        scroll={{ x: 1000 }}
        columns={columns}
        dataSource={contentData}
        pagination={{
          pageSize: pagination?.pageSize,
          onChange(page, pageSize) {
            setPagination(pageSize);
          },
          showSizeChanger: true,
        }}
      />
      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        className="checkout_popup"
        // closable={false}
        // maskClosable={false}
      >
        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
        >
          {allPackageList && (
            <Form.Item
              label="Package Name"
              name="package_name"
              rules={[
                { required: true, message: "Please input Package Name!" },
              ]}
              className="form_item_blue"
            >
              <Select
                onChange={handleChange}
                options={allPackageList.map((option: any) => ({
                  value: option._id,
                  label: option.package,
                }))}
              />
            </Form.Item>
          )}

          <Form.Item
            label="Package Amount (18% GST Tax excluded)"
            name="package_price"
            rules={[
              { required: true, message: "Please input Package Price!" },
              {
                pattern: new RegExp(/^[0-9\b]+$/),
                message: "Please enter valid number!",
              },
            ]}
            className="form_item_blue"
          >
            <Input onChange={onChangePackageAmount} />
          </Form.Item>
          <Form.Item
            label="Employees (Units)"
            name="employees"
            rules={[
              {
                required: true,
                message: "Please Enter the number of employees",
              },
              {
                pattern: new RegExp(/^[0-9\b]+$/),
                message: "Please enter valid number!",
              },
            ]}
            className="form_item_blue"
          >
            <Input onChange={onChangeEmployees} />
          </Form.Item>
          <Form.Item
            label="Price Per Person, Per Month (18% GST Tax excluded)"
            name="unitPrice"
            rules={[
              { required: true, message: "Please input Package Price!" },
              {
                pattern: new RegExp(/^[0-9\b]+$/),
                message: "Please enter valid number!",
              },
            ]}
            className="form_item_blue"
          >
            <Input onChange={onChangePricePerPerson} />
          </Form.Item>
          <div className="yellow_tip_box">
            <p style={{ marginBottom: "16px" }}>
              Enter the <strong>Package Amount </strong> in the form after
              calculating it manually. For invoicing purposes, please also
              provide the <strong>Price Per Person, Per Month</strong>{" "}
              separately.
            </p>
            <p>Note: This will not affect the total package amount entered.</p>
          </div>
          <Form.Item
            label="Duration"
            name="duration"
            rules={[
              { required: true, message: "Please select duration!" },
              // {
              //   pattern: new RegExp(/^[0-9\b]+$/),
              //   message: "Please enter valid number!",
              // },
            ]}
            className="form_item_blue"
          >
            <Select onChange={handleChangeDuration}>
              <option value="6-month">6 Months</option>
              <option value="1-year">12 Months</option>
            </Select>
          </Form.Item>
          <CheckboxList
            preSelectPackages={preSelectPackages}
            modules={memberModules}
          />
          {packagePricingBreakdown && (
            <div className="breakdown_wrapper">
              <h3>Breakdown</h3>
              <div className="price_box">
                <ul>
                  <li>
                    <div>
                      <p>Membership Charges</p>
                    </div>
                    <div>
                      <p>₹{packagePricingBreakdown.packageAmount}</p>
                    </div>
                  </li>
                  {isEmployees !== 0 && (
                    <li>
                      <div>
                        <p>Unit</p>
                      </div>
                      <div>
                        <p>{isEmployees}</p>
                      </div>
                    </li>
                  )}
                  {isPricePerPerson !== 0 && (
                    <li>
                      <div>
                        <p>Price Per Person, Per Month</p>
                      </div>
                      <div>
                        <p>₹{isPricePerPerson}</p>
                      </div>
                    </li>
                  )}

                  <li className="tax_details">
                    <div className="line">
                      <div>
                        <p>Tax / (IGST 18%)</p>
                      </div>
                      <div>
                        <p>₹{packagePricingBreakdown.igstTax}</p>
                      </div>
                    </div>

                    <div className="line tax" style={{ marginTop: "8px" }}>
                      <div className="gray">
                        <p>CGST (9%)</p>
                      </div>
                      <div>
                        <p>₹{packagePricingBreakdown.cgstTax}</p>
                      </div>
                    </div>
                    <div className="line tax">
                      <div className="gray">
                        <p>SGST/UTGST (9%)</p>
                      </div>
                      <div>
                        <p>₹{packagePricingBreakdown.sgstTax}</p>
                      </div>
                    </div>
                  </li>
                  <li className="border">
                    <div>
                      <p>Subtotal</p>
                    </div>
                    <div>
                      <p>₹{packagePricingBreakdown.subTotalAmount}.00</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          )}

          <div className="verifi_checkbox">
            <Checkbox onChange={VerifiedPackage}></Checkbox>
            <p>I have thoroughly verified all the details.</p>
          </div>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              disabled={btnDisable ? true : false}
              className="cn_btn"
              style={{ margin: "24px auto 0", width: "100%" }}
            >
              Confirm & Proceed
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default CommentsDataTable;
