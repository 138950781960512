import React, { useEffect, useState } from "react";
import Text from "antd/lib/typography/Text";
import { Button, Image, Progress } from "antd";
import StackedBarChart from "../../components/StackedBar";
import styles from "../../styles/dashboard.module.scss";
import moment from "moment";
import { authSVC } from "../../services";
import { useB2BContext } from "../../context/ContextB2B";

function BarChartOverview() {
  const [tapIndex3, setTapIndex3] = useState<number>(0);
  const { getUserByID } = useB2BContext();
  const [isNoData, setIsNoData] = useState(false);
  const [departmentLevelWellness, setdepartmentLevelWellness] = useState<any[]>(
    []
  );
  const [departmentLevelhappiness, setdepartmentLevelHappiness] = useState<
    any[]
  >([]);

  useEffect(() => {
    const year: any = moment().format("yyyy");
    if (getUserByID) {
      authSVC
        .getbarChartWellnessLevels(getUserByID?.company, Number(year))
        .then((res) => {
          setdepartmentLevelWellness(res?.data?.data);
          console.log(res?.data?.data, "01");
        })
        .catch((err) => {
          console.log(err);
        });
      authSVC
        .getbarChartHappinessLevels(getUserByID?.company, Number(year))
        .then((res) => {
          setdepartmentLevelHappiness(res?.data?.data);
          console.log(res?.data?.data, "02");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [getUserByID]);

  // useEffect(() => {
  //   console.log(departmentLevelWellness, "departmentLevelWellness");
  //   // const hasData = departmentLevelWellness.some(
  //   //   (item: any) => item.percentage !== null
  //   // );
  //   // setIsNoData(hasData);
  // }, [departmentLevelWellness]);

  return (
    <div className={styles.white_bg_wrapper}>
      <div className={styles.info_title}>
        <div className={styles.title_grp}>
          <Text
            style={{
              color: tapIndex3 === 0 ? "" : "#2E3191",
              opacity: tapIndex3 === 0 ? "" : "0.3",
            }}
            className={styles.title}
            onClick={() => setTapIndex3(0)}
          >
            Wellness Index
          </Text>
          <Text
            style={{
              color: tapIndex3 === 1 ? "" : "#2E3191",
              opacity: tapIndex3 === 1 ? "" : "0.3",
            }}
            className={styles.title}
            onClick={() => setTapIndex3(1)}
          >
            Happiness Index
          </Text>
        </div>
      </div>

      <div className={styles.title_grp1}>
        {/* <Image
          className={styles.img}
          src="/img/new/download_icon.svg"
          preview={false}
        /> */}
        {/* <Text className={styles.subtitle1}>21% from last month</Text> */}
      </div>
      <div className={styles.graph}>
        {departmentLevelWellness.length === 0 ? (
          <div className="no_data_yet">
            <img src="/img/User_empty.png" alt="" width="56" height="52" />
            <p>No data yet</p>
          </div>
        ) : (
          <StackedBarChart
            chartData={
              tapIndex3 === 0
                ? departmentLevelWellness
                : tapIndex3 === 1
                ? departmentLevelhappiness
                : null
            }
          />
        )}
      </div>
    </div>
  );
}

export default BarChartOverview;
