import React, { useState } from "react";
import "./datatable.scss";
import {
  Space,
  Table,
  Input,
  Button,
  Image,
  Modal,
  message,
  Popover,
} from "antd";
import type { ColumnsType } from "antd/es/table";

interface Contentdata {
  key?: string;
  id?: string;
  title?: string;
  image?: string;
  content?: string;
  hash?: string;
  themeId?: string;
}

interface DataTableProps {
  setshowModel: (value: boolean) => void;
  setEditable: (value: boolean) => void;
  deleteArticle: (value: any) => void;
  editContent: (value: any) => void;
  isOpen: boolean;
  contentData: Contentdata[];
}
function DataTableArticle({
  setshowModel,
  setEditable,
  isOpen,
  contentData,
  deleteArticle,
  editContent,
}: DataTableProps) {
  const [open, setOpen] = useState(false);
  const [pagination, setPagination] = useState<any>({ page: 1, pageSize: 5 });

  const showModal = () => {
    setOpen(true);
  };

  const hideModal = () => {
    setOpen(false);
  };

  const [searchText, setSearchText] = useState("");

  const columns: ColumnsType<Contentdata> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      filteredValue: [searchText],
      defaultSortOrder: "descend",
      sorter: (a: any, b: any) => a.id - b.id,
      onFilter: (value, record) => {
        return (
          String(record.id).toLowerCase().includes(value.toLocaleString()) ||
          String(record.title).toLowerCase().includes(value.toLocaleString()) ||
          String(record.hash).toLowerCase().includes(value.toLocaleString())
        );
      },
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (text) => (text.length > 15 ? `${text.slice(0, 100)}...` : text),
    },
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      render: (_, record) => (
        <Space size="middle">
          <Image src={record.image} width={60} height={60} alt={"image"} />
        </Space>
      ),
    },
    {
      title: "Content",
      dataIndex: "content",
      key: "content",
      render: (text) => (
        <>
          <p dangerouslySetInnerHTML={{ __html: text.slice(0, 100) }} />
        </>
      ),
    },
    {
      title: "Hash",
      dataIndex: "hash",
      key: "hash",
    },
    {
      title: "Theme ID",
      dataIndex: "themeId",
      key: "themeId",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={() => {
              setshowModel(!isOpen);
              setEditable(true);
              editContent(record.id);
            }}
          >
            Edit
          </Button>
          <Popover
            content={
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "10px",
                }}
              >
                <Button
                  style={{ backgroundColor: "green", width: "100%" }}
                  onDoubleClick={() => deleteArticle(record.id)}
                >
                  Yes
                </Button>
              </div>
            }
            title="Please Double click to delete."
          >
            <Button>Delete</Button>
          </Popover>
        </Space>
      ),
    },
  ];

  return (
    <div className="dataTable">
      <div className="filterMenu">
        <div></div>
        <Input.Search
          onSearch={(value) => {
            setSearchText(value);
          }}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
          className="searchField"
          placeholder="Search here..."
        />
      </div>
      <Table
        className="dataGrid"
        columns={columns}
        dataSource={contentData}
        pagination={{
          pageSize: pagination?.pageSize,
          onChange(page, pageSize) {
            setPagination(pageSize);
          },
          showSizeChanger:true
        }}
      />
    </div>
  );
}

export default DataTableArticle;
