import React, { useEffect, useState } from "react";
import { Button, Typography, Modal, Input, message, Select } from "antd";
import { authSVC, categorySVC } from "../../services";
import { Option } from "antd/es/mentions";
import axios from "axios";
import { useB2BContext } from "../../context/ContextB2B";
import DataTable from "../../components/dataTable/b2b/DataTable";
import { Spin } from "antd";
import { CSVLink } from "react-csv";
import useSWR from 'swr';
import * as Cookie from "universal-cookie";
// import { LazyLoadImage } from "react-lazy-load-image-component";

const cookie = new Cookie.default();
const cachedToken = cookie.get("userAuthTokenAdmin");

const { Text } = Typography;

interface Contentdata {
  _id: string;
  full_name: string;
  email: string;
  user_type: string;
  company: string;
  department: string;
}

const fetcher = (url: any) => axios.get(url, {
  headers: {
    'Authorization': `Bearer ${cachedToken}`,
    'Content-Type': 'application/json'
  }
}).then(res => res?.data?.data);
function CSVDownload() {
  // const { getUserByID } = useB2BContext();
  const [rioDataList, setRioDataList] = useState<any>([]);
  const [ocaDataList, setOcaDataList] = useState<any>([]);
  const [wellnessDataList, setWellnessDataList] = useState<any>([]);


  const API_URL = process.env.REACT_APP_API_URL;

  useSWR(API_URL + '/roi/all', fetcher, {
    revalidateOnFocus: true,
    revalidateOnReconnect: true,
    onSuccess(data) {
      setRioDataList(data);
    },
  });

  useSWR(API_URL + '/oca/all', fetcher, {
    revalidateOnFocus: true,
    revalidateOnReconnect: true,
    onSuccess(data) {
      setOcaDataList(data);
    },
  });

  useSWR('https://8pg4jnme8f.us-east-1.awsapprunner.com/audit/getALL', fetcher, {
    revalidateOnFocus: true,
    revalidateOnReconnect: true,
    onSuccess(data) {
      setWellnessDataList(data);
    },
  });



  // const csvDataRio = rioDataList && rioDataList?.map((item: any) => ({
  //   id: item?._id,
  //   name: item?.first_Name,
  //   email: item?.corporate_Email,
  //   organization_Size: item?.organization_Size,
  //   preferred_Name: item?.preferred_Name,
  //   second_Name: item?.second_Name,
  //   createdAt: item?.createdAt
  // }));


  // const csvDataQuestionnaire = ocaDataList && ocaDataList?.map((item: any) => ({
  //   name: item?.first_Name,
  //   email: item?.corporate_Email,
  //   organization_Size: item?.organization_Size,
  //   preferred_Name: item?.preferred_Name,
  //   second_Name: item?.second_Name,
  //   status: item?.status,
  //   createdAt: item?.createdAt
  // }));


  const csvWellnessData = wellnessDataList && wellnessDataList?.map((item: any) => ({
    _id: item?._id,
    user: item?.user,
    "sportsLikeToWatch": item?.sport?.sportsLikeToWatch,
    "sportsLikeToPlay": item?.sport?.sportsLikeToPlay,
    "exercisePrefer": item?.sport?.exercisePrefer,
    "whichYouLike": item?.recreation?.whichYouLike,
    "howDoYouMoodToday": item?.mood?.howDoYouMoodToday,
    "howIsMoodThisWeek": item?.mood?.howIsMoodThisWeek,
    "lastTimeLaughtHeartedly": item?.mood?.lastTimeLaughtHeartedly,
    "whoMakesYouSmiles": item?.mood?.whoMakesYouSmiles,
    "responseForChallenge": item?.interaction?.responseForChallenge,
    "HowPrioritizeTasks": item?.interaction?.HowPrioritizeTasks,
    "othersDescribeYourCommiunication": item?.interaction?.othersDescribeYourCommiunication,
    "oftenRoleInGroupProjects": item?.interaction?.oftenRoleInGroupProjects,
    "approachForProblemSolving": item?.interaction?.approachForProblemSolving,
    "describeOfWorkEthic": item?.interaction?.describeOfWorkEthic,
    "InATeamSettingMoreImportantToYou": item?.interaction?.InATeamSettingMoreImportantToYou,
    "howHandleFeedback": item?.interaction?.howHandleFeedback,
    "motivesAtWork": item?.interaction?.motivesAtWork,
    "dealWithConflicts": item?.interaction?.dealWithConflicts,
    "resposeForPersonDidWrongToYou": item?.interaction?.resposeForPersonDidWrongToYou,
    "ageOrBirthYear": item?.destiny?.ageOrBirthYear,
    "bornDate": item?.destiny?.bornDate,
    "bornMonth": item?.destiny?.bornMonth,
    "howDoYouFeelToday": item?.colors?.howDoYouFeelToday,
    "PositiveAffirmation": item?.colors?.PositiveAffirmation,
    "mostFavorite": item?.colors?.mostFavorite,
    "NegativeAffirmation": item?.colors?.NegativeAffirmation,
    "leastFavorite": item?.colors?.leastFavorite,
    "colorRedWhoOutOfYourFamilyAndFriends": item?.associateColorRed?.colorRedWhoOutOfYourFamilyAndFriends,
    "whichArtsYouLike": item?.art?.whichArtsYouLike,
    "paintingLikeYouBests": item?.art?.paintingLikeYouBests,
    "shapeYouLikeBests": item?.art?.shapeYouLikeBests,
    "pickTopOne": item?.animals?.pickTopOne,
    "pickTopTwo": item?.animals?.pickTopTwo,
    createdAt: item?.createdAt
  }))

  return (
    <div className="contentBox">
      <div className="info">
        <Text className="title24">CSV Download</Text>
        <div className="csv_btn_set">
          {rioDataList?.length > 0 ? <CSVLink data={rioDataList} filename={"rio-data.csv"}>
            <Button type="primary" style={{ marginTop: "16px" }}>
              RIO Export CSV
            </Button>
          </CSVLink> : null}
          {ocaDataList?.length > 0 ? <CSVLink
            data={ocaDataList}
            filename={"questionnaire-data.csv"}
          >
            <Button type="primary" style={{ marginTop: "16px" }}>
              Questionnaire Export CSV
            </Button>
          </CSVLink> : null}
          {csvWellnessData?.length > 0 ? <CSVLink data={csvWellnessData} filename={"wellness-data.csv"}>
            <Button type="primary" style={{ marginTop: "16px" }}>
              Wellness Profile Data
            </Button>
          </CSVLink> : null}
        </div>
      </div>
    </div>
  );
}

export default CSVDownload;
