import React, { createContext, useContext, useEffect, useState } from "react";
import * as Cookie from "universal-cookie";
import { authSVC } from "../services";
import { message } from "antd";

interface context {
  getUserByID: any;
  getUserById: (id: string) => void;
  paymentDetails: any;
  setPaymentDetails: any;
  setPriceId: (value: string) => void;
  priceId: any;
  setAgreementId: (value: any) => void;
  agreementId: any;
  setUserByID: any;
  setAllPackageList: any;
  allPackageList: any;
  selectedModulesList: any;
  setSelectedModulesList: any;
}

type props = {
  children: React.ReactNode;
};

const cookie = new Cookie.default();
export const B2BContext = createContext({} as context);

export const B2BContextProvider = ({ children }: props) => {
  const [getUserByID, setUserByID] = useState<any>();
  const [paymentDetails, setPaymentDetails] = useState<any>();
  const [priceId, setPriceId] = useState<any>();
  const [agreementId, setAgreementId] = useState<any>();
  const [allPackageList, setAllPackageList] = useState<any>();
  const [selectedModulesList, setSelectedModulesList] = useState<any>();

  const getUserById = async (id: string) => {
    await authSVC
      .getByUID(id)
      .then((res) => {
        setUserByID(res.data.data);
      })
      .catch((err) => {});
  };

  const getPaymentDtls = (id: string) => {
    authSVC
      .paymentDetails(id)
      .then((res) => {
        setPaymentDetails(res?.data?.data);
      })
      .catch((err) => {
        message.error("Something went wrong. Please try again");
      });
  };

  useEffect(() => {
    const uId: any = localStorage.getItem("uIdcms");
    if (uId) {
      getUserById(uId);
      getPaymentDtls(uId);
    }
  }, []);

  useEffect(() => {
    AllPackage();
  }, []);

  const AllPackage = async () => {
    authSVC
      .getAllPackage()
      .then((res: any) => {
        console.log(res.data.package, "All Package");
        setAllPackageList(res.data.package);
      })
      .catch((err: any) => {
        message.error(err.message);
      });
  };

  return (
    <B2BContext.Provider
      value={{
        getUserByID,
        getUserById,
        paymentDetails,
        setPaymentDetails,
        setPriceId,
        priceId,
        setAgreementId,
        agreementId,
        setUserByID,
        setAllPackageList,
        allPackageList,
        selectedModulesList,
        setSelectedModulesList,
      }}
    >
      {children}
    </B2BContext.Provider>
  );
};

export function useB2BContext() {
  return useContext(B2BContext);
}
