import React, { useEffect, useState } from "react";
import "./webinar.scss";
import {
  Form,
  Button,
  Typography,
  Modal,
  Input,
  message,
  DatePickerProps,
  DatePicker,
} from "antd";
import dayjs from "dayjs";
import moment from "moment";
import DataTableWebinar from "../../components/dataTable/DataTableWebinar";
import { webinarSVC } from "../../services";
import WebinarImageUploader from "../../components/WebinarImageUploader";
import axios from "axios";
import * as Cookie from "universal-cookie";
import useSWR from "swr";

const cookie = new Cookie.default();
const cachedToken = cookie.get("userAuthTokenAdmin");

const { Text } = Typography;

interface Contentdata {
  key?: string;
  _id: string;
  title: string;
  date?: string;
  hour: string;
  video?: string;
  start_date: string;
  end_date: string;
  meetingLink?: string;
  meeting_id?: string;
  passcode?: string;
  cover_img: any;
}

const fetcher = (url: any) =>
  axios
    .get(url, {
      headers: {
        Authorization: `Bearer ${cachedToken}`,
        "Content-Type": "application/json",
      },
    })
    .then((res) => res?.data?.data);

function Webinar() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [editContent, setEditContent] = useState<Contentdata>({
    _id: "",
    title: "",
    date: "",
    hour: "",
    video: "",
    start_date: "",
    end_date: "",
    meetingLink: "",
    meeting_id: "",
    passcode: "",
    cover_img: "",
  });
  // const [contentData, setContentData] = useState<Contentdata[]>([])
  const [uploadedImage, setUploadedImage] = useState<any>(null);
  const [loading, setloading] = useState<boolean>(false);

  // const SITEURL = process.env.REACT_APP_API_SITE;
  const API_URL = process.env.REACT_APP_API_URL;

  const { data: contentData, mutate } = useSWR(API_URL + "/webinars", fetcher);

  // const getOtherContent = async () => {
  //   webinarSVC.getwebinar().then((res: any) => {
  //     setContentData(res.data.data)
  //   }).catch((err: any) => {
  //     message.error(err.message);
  //   })
  // }

  // useEffect(() => {
  //   getOtherContent()
  // }, [])

  const deleteWebinar = (id: string) => {
    webinarSVC
      .deletewebinar(id)
      .then((res: any) => {
        mutate();
        message.success("Deleted Successfully");
      })
      .catch((err: any) => {
        message.error(err.message);
      });
  };

  const updateWebinar = (id: string) => {
    setloading(true);
    const formData: any = new FormData();
    formData.append("title", editContent.title);
    formData.append("date", null);
    formData.append("hour", editContent.hour);
    formData.append("video", editContent.video);
    formData.append("start_date", editContent.start_date);
    formData.append("end_date", editContent.end_date);
    formData.append("meetingLink", editContent.meetingLink);
    formData.append("meeting_id", editContent.meeting_id);
    formData.append("passcode", editContent.passcode);
    formData.append("image", editContent.cover_img);

    webinarSVC
      .updatewebinar(id, formData)
      .then((res: any) => {
        setloading(false);
        mutate();
        message.success("Updated Successfully");
        handleCancel();
      })
      .catch((err: any) => {
        message.error(err.message);
        setloading(false);
      });
  };
  const addwebinar = () => {
    setloading(true);
    const formData: any = new FormData();
    formData.append("title", editContent.title);
    formData.append("date", null);
    formData.append("hour", editContent.hour);
    formData.append("video", editContent.video);
    formData.append("start_date", editContent.start_date);
    formData.append("end_date", editContent.end_date);
    formData.append("meetingLink", editContent.meetingLink);
    formData.append("meeting_id", editContent.meeting_id);
    formData.append("passcode", editContent.passcode);
    formData.append("image", editContent.cover_img);

    webinarSVC
      .addwebinar(formData)
      .then((res: any) => {
        setloading(false);
        mutate();
        message.success("Added Successfully");
        setEditContent({
          _id: "",
          title: "",
          date: "",
          hour: "",
          video: "",
          start_date: "",
          end_date: "",
          meetingLink: "",
          meeting_id: "",
          passcode: "",
          cover_img: "",
        });
        setUploadedImage(null);
        handleCancel();
      })
      .catch((err: any) => {
        message.error(err.message);
        setloading(false);
      });
  };
  const editWebinar = (id: string) => {
    webinarSVC
      .editwebinar(id)
      .then((res: any) => {
        const data = res.data.data;
        setEditContent({
          _id: data._id,
          title: data.title,
          date: data.date,
          hour: data.hour,
          video: data.video,
          start_date: data.start_date,
          end_date: data.end_date,
          meetingLink: data.meetingLink,
          meeting_id: data.meeting_id,
          passcode: data.passcode,
          cover_img: data.cover_img,
        });
        setUploadedImage(data.cover_img);
      })
      .catch((err: any) => {
        message.error(err.message);
      });
  };

  const showModal = () => {
    setIsModalOpen(true);
    setIsEditable(false);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setEditContent({
      _id: "",
      title: "",
      date: "",
      hour: "",
      video: "",
      start_date: "",
      end_date: "",
      meetingLink: "",
      meeting_id: "",
      passcode: "",
      cover_img: "",
    });
    setUploadedImage(null);
    setloading(false);
  };

  const handleFileUpload = async (event: any) => {
    event.preventDefault();
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setUploadedImage(reader.result);
    };

    setEditContent((prevData) => ({
      ...prevData,
      cover_img: file,
    }));

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    setEditContent((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const onChangeStartDate: DatePickerProps["onChange"] = (date, dateString) => {
    const endDate = moment(editContent.end_date, "YYYY-MM-DDTHH:mm");
    const dateToCompare = moment(dateString, "YYYY-MM-DDTHH:mm");

    if (endDate.isSame(dateToCompare)) {
      message.info("Time can't be same.");
      setEditContent((prevData: any) => ({
        ...prevData,
        start_date: "",
      }));
    } else {
      setEditContent((prevData: any) => ({
        ...prevData,
        start_date: dateString,
      }));
    }
  };

  const onChangeEndDate: DatePickerProps["onChange"] = (date, dateString) => {
    const startDate = moment(editContent.start_date, "YYYY-MM-DDTHH:mm");
    const dateToCompare = moment(dateString, "YYYY-MM-DDTHH:mm");
    if (startDate.isSame(dateToCompare)) {
      message.info("Time can't be same");
      setEditContent((prevData: any) => ({
        ...prevData,
        end_date: "",
      }));
    } else if (dateToCompare < startDate) {
      message.info("Can't select end date less then start date");
      setEditContent((prevData: any) => ({
        ...prevData,
        end_date: "",
      }));
    } else {
      setEditContent((prevData: any) => ({
        ...prevData,
        end_date: dateString,
      }));
    }
  };

  const disabledTime = (current: any) => {
    // Disable past times if the selected date is today
    if (current && current.isSame(moment(), "day")) {
      const now = moment();
      return {
        disabledHours: () =>
          Array.from({ length: 24 }, (_, i) => i).splice(0, now.hour()),
        disabledMinutes: () =>
          Array.from({ length: 60 }, (_, i) => i).splice(0, now.minute()),
        disabledSeconds: () =>
          Array.from({ length: 60 }, (_, i) => i).splice(0, now.second()),
      };
    }
    return {};
  };

  return (
    <div className="contentBox">
      <div className="info">
        <Text className="title24">Webinar</Text>
      </div>
      <DataTableWebinar
        showModal={showModal}
        deleteWebinar={deleteWebinar}
        editContent={editWebinar}
        setshowModel={setIsModalOpen}
        setEditable={setIsEditable}
        isOpen={isModalOpen}
        contentData={contentData}
      />
      <Modal
        width={"40%"}
        footer={false}
        title={isEditable === true ? "Update  Webinar" : "Add Webinar"}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form>
          <label htmlFor="">
            <b>Title</b>
          </label>
          <Input
            className="input"
            name="title"
            onChange={handleInputChange}
            size={"large"}
            style={{ borderRadius: "10px" }}
            value={editContent.title}
            placeholder={"Enter Webinar Title"}
          />

          {/* <br /><br />
        <label htmlFor=""><b>Host Date</b></label>
        <Input className='input' name='date' onChange={handleInputChange} size={'large'} style={{ borderRadius: '10px' }} value={editContent.date} placeholder={"Enter Webinar Host Date"} /> */}

          <br />
          <br />
          <label htmlFor="">
            <b>Host Hour</b>
          </label>

          <Input
            type="number"
            className="input"
            name="hour"
            onChange={handleInputChange}
            size={"large"}
            style={{ borderRadius: "10px" }}
            value={editContent.hour}
            placeholder={"Enter Webinar Host Hour"}
          />

          {/* {
            isEditable === true ?
              <> */}
          <br />
          <br />
          <label htmlFor="">
            <b>Video URL</b>
          </label>
          <Input
            type="url"
            className="input"
            name="video"
            onChange={handleInputChange}
            size={"large"}
            style={{ borderRadius: "10px" }}
            value={editContent.video}
            placeholder={"Enter Video Url"}
          />
          {/* </>
              : null
          } */}

          <br />
          <br />
          <label htmlFor="">
            <b>Webinar Host Date</b>
          </label>
          <DatePicker
            showTime
            format="YYYY-MM-DD HH:mm"
            value={
              editContent.start_date ? dayjs(editContent.start_date) : null
            }
            disabledDate={(current) => {
              return current && current < moment().startOf("day");
            }}
            disabledTime={disabledTime}
            style={{ width: "100%" }}
            onChange={onChangeStartDate}
          />

          <br />
          <br />
          <label htmlFor="">
            <b>Webinar End Date</b>
          </label>
          <DatePicker
            showTime
            format="YYYY-MM-DD HH:mm"
            value={editContent.end_date ? dayjs(editContent.end_date) : null}
            disabledDate={(current) => {
              return current && current < moment().startOf("day");
            }}
            disabledTime={disabledTime}
            style={{ width: "100%" }}
            onChange={onChangeEndDate}
          />

          <br />
          <br />
          <label htmlFor="">
            <b>Meeting Link</b>
          </label>

          <Input
            type="url"
            className="input"
            name="meetingLink"
            onChange={handleInputChange}
            size={"large"}
            style={{ borderRadius: "10px" }}
            value={editContent.meetingLink}
            placeholder={"Enter Meeting Link"}
          />

          <br />
          <br />
          <label htmlFor="">
            <b>Meeting ID</b>
          </label>

          <Input
            type="number"
            className="input"
            name="meeting_id"
            onChange={handleInputChange}
            size={"large"}
            style={{ borderRadius: "10px" }}
            value={editContent.meeting_id}
            placeholder={"Enter Meeting ID"}
          />
          <br />
          <br />
          <label htmlFor="">
            <b>Meeting Passcode</b>
          </label>

          <Input
            className="input"
            name="passcode"
            onChange={handleInputChange}
            size={"large"}
            style={{ borderRadius: "10px" }}
            value={editContent.passcode}
            placeholder={"Enter Meeting Passcode"}
          />

          <br />
          <br />
          <label htmlFor="">
            <b>Cover Image</b>
          </label>

          <WebinarImageUploader
            image={editContent?.cover_img}
            uploadedImage={uploadedImage}
            handleFileUpload={handleFileUpload}
          />
          <br />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              onClick={() => {
                if (editContent.title === "" || editContent.title === null) {
                  message.info("Please Enter title");
                }
                // else if (editContent.date === "") {
                //   message.info("Please Enter Host Date")
                // }
                else if (editContent.hour === "" || editContent.hour === null) {
                  message.info("Please Enter Host Hour");
                } else if (
                  editContent.start_date === "" ||
                  editContent.start_date === null
                ) {
                  message.info("Please Enter Start Date");
                } else if (
                  editContent.end_date === "" ||
                  editContent.end_date === null
                ) {
                  message.info("Please Enter End Date");
                } else if (
                  editContent.meetingLink === "" ||
                  editContent.meetingLink === null
                ) {
                  message.info("Please Enter Meeting Link");
                }
                // else if (editContent.meeting_id === "") {
                //   message.info("Please Enter Meeting ID")
                // }
                // else if (editContent.passcode === "") {
                //   message.info("Please Enter Passcode")
                // }
                else {
                  if (isEditable === true) {
                    updateWebinar(editContent._id);
                  } else {
                    if (editContent.cover_img === "") {
                      message.info("Please upload a cover image");
                    } else {
                      addwebinar();
                    }
                  }
                }
              }}
              className="button"
              loading={loading}
            >
              {isEditable === true ? "Update Webinar" : "Add Webinar"}
            </Button>
          </div>
        </Form>
      </Modal>
    </div>
  );
}

export default Webinar;
