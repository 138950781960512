import React, { useEffect, useState } from "react";
import "./article.scss";
import { Button, Typography, Modal, Input, message, Form } from "antd";
import { ngoSVC } from "../../services";
import ImageUploader from "./ImageUploader";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import DataTableNGO from "../../components/dataTable/DataTableNGO";

const { Text } = Typography;

interface Contentdata {
  key?: string;
  _id: string;
  organization_name: string;
  logo: string;
  established_in: string;
  global_presence: any;
  description: string;
  cover_image: string;
  web_url: any;
}

function RegisterNGO() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [editContent, setEditContent] = useState<any>({
    _id: "",
    organization_name: "",
    logo: "",
    established_in: "",
    global_presence: "",
    description: "",
    cover_image: "",
    web_url: "",
  });
  const [contentData, setContentData] = useState<Contentdata[]>([]);
  const [uploadedImage, setUploadedImage] = useState<any>(null);
  const [uploadedCoverImage, setUploadedCoverImage] = useState<any>(null);
  const [loading, setloading] = useState<boolean>(false);
  const [btnDisable, setBtnDisable] = useState(false);

  const getOtherContent = async () => {
    ngoSVC
      .getorganization()
      .then((res: any) => {
        setContentData(res.data.data);
      })
      .catch((err: any) => {
        message.error(err.message);
      });
  };

  useEffect(() => {
    getOtherContent();
  }, []);

  const deleteArticle = (id: string) => {
    ngoSVC
      .deleteorganization(id)
      .then((res: any) => {
        getOtherContent();
        message.success("Deleted Successfully");
      })
      .catch((err: any) => {
        message.error(err.message);
      });
  };

  const updateArticle = (id: string) => {
    setloading(true);
    const formData: any = new FormData();

    formData.append("organization_name", editContent.organization_name);
    // formData.append('image', editContent.logo)
    formData.append("established_in", editContent.established_in);
    formData.append(
      "global_presence",
      JSON.stringify(editContent.global_presence)
    );
    formData.append("description", editContent.description);
    formData.append("image", editContent.cover_image);
    formData.append("web_url", editContent.web_url);

    ngoSVC
      .updateorganization(id, formData)
      .then((res: any) => {
        setloading(false);
        getOtherContent();
        message.success("Updated Successfully");
        handleCancel();
      })
      .catch((err: any) => {
        message.error(err.message);
        setloading(false);
      });
  };
  const addArticle = () => {
    setloading(true);
    const formData: any = new FormData();
    formData.append("organization_name", editContent.organization_name);
    formData.append("image", editContent.logo);
    formData.append("established_in", editContent.established_in);
    formData.append(
      "global_presence",
      JSON.stringify(editContent.global_presence)
    );
    formData.append("description", editContent.description);
    // formData.append('cover_image', editContent.cover_image)
    formData.append("web_url", editContent.web_url);

    ngoSVC
      .addorganization(formData)
      .then((res: any) => {
        setloading(false);
        getOtherContent();
        message.success("Added Successfully");
        setEditContent({
          _id: "",
          organization_name: "",
          logo: "",
          established_in: "",
          global_presence: "",
          description: "",
          cover_image: "",
          web_url: "",
        });
        setUploadedImage(null);
        handleCancel();
      })
      .catch((err: any) => {
        message.error(err.message);
        setloading(false);
      });
  };
  const editArticle = (id: string) => {
    const findByid = contentData.find((item: any) =>
      item._id === id ? item : null
    );
    setEditContent({
      _id: findByid?._id,
      organization_name: findByid?.organization_name,
      logo: findByid?.logo,
      established_in: findByid?.established_in,
      global_presence: findByid?.global_presence,
      description: findByid?.description,
      cover_image: findByid?.cover_image,
      web_url: findByid?.web_url,
    });
  };

  const showModal = () => {
    setIsModalOpen(true);
    setIsEditable(false);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setEditContent({
      _id: "",
      organization_name: "",
      logo: "",
      established_in: "",
      global_presence: "",
      description: "",
      cover_image: "",
      web_url: "",
    });
    setUploadedImage(null);
    setloading(false);
  };

  const handleLogoUpload = async (event: any) => {
    event.preventDefault();
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setUploadedImage(reader.result);
    };
    setEditContent((prevData: any) => ({
      ...prevData,
      logo: file,
    }));

    if (file) {
      reader.readAsDataURL(file);
    }
  };
  const handleCoverImgUpload = async (event: any) => {
    event.preventDefault();
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setUploadedCoverImage(reader.result);
    };
    setBtnDisable(false);
    setEditContent((prevData: any) => ({
      ...prevData,
      cover_image: file,
    }));

    if (file) {
      reader.readAsDataURL(file);
    }
  };
  const handleInputChange = (event: any) => {
    setBtnDisable(false);
    const { name, value } = event.target;
    setEditContent((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleDescription = (value: any) => {
    setBtnDisable(false);
    setEditContent((prevData: any) => ({
      ...prevData,
      description: value,
    }));
  };

  return (
    <div className="contentBox">
      <div className="info">
        <Text className="title24">NGO</Text>
        <Button onClick={showModal} className="button">
          Add NGO{" "}
        </Button>
      </div>
      <DataTableNGO
        deleteArticle={deleteArticle}
        editContent={editArticle}
        setshowModel={setIsModalOpen}
        setEditable={setIsEditable}
        isOpen={isModalOpen}
        contentData={contentData}
      />
      <Modal
        width={"80%"}
        footer={false}
        title={isEditable === true ? "Update  NGO" : "Add NGO"}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <label htmlFor="">
          <b>Organization Name</b>
        </label>
        <br />
        <Input
          className="input"
          name="organization_name"
          onChange={handleInputChange}
          size={"large"}
          style={{ borderRadius: "10px" }}
          value={editContent.organization_name}
          placeholder={"Enter Organization Name"}
        />
        <br />
        <br />

        {isEditable !== true ? (
          <>
            <label htmlFor="">
              <b>LOGO</b>
            </label>
            <br />
            <ImageUploader
              image={editContent?.logo}
              uploadedImage={uploadedImage}
              handleFileUpload={handleLogoUpload}
            />
            <br />
          </>
        ) : null}
        <label htmlFor="">
          <b>Established In</b>
        </label>
        <br />
        <Input
          className="input"
          name="established_in"
          onChange={handleInputChange}
          size={"large"}
          style={{ borderRadius: "10px", marginBottom: "30px" }}
          value={editContent.established_in}
          placeholder={"Enter Established in"}
        />
        <br />
        <label htmlFor="">
          <b>Global Presence</b>
        </label>
        <br />
        <Input
          className="input"
          name="global_presence"
          onChange={handleInputChange}
          size={"large"}
          style={{ borderRadius: "10px" }}
          value={editContent.global_presence}
          placeholder={"Enter Global Presence"}
        />
        <br />
        <br />
        <label htmlFor="">
          <b>Description</b>
        </label>
        <br />
        <ReactQuill
          style={{ height: 300 }}
          value={editContent.description}
          onChange={handleDescription}
          modules={{
            toolbar: [
              ["bold", "italic", "underline", "strike", "blockquote"],
              [{ list: "ordered" }, { list: "bullet" }],
              ["link"],
              ["clean"],
              ["image"],
            ],
          }}
        />
        <br />
        <br />
        <br />
        {isEditable === true ? (
          <>
            <label htmlFor="">
              <b>Cover Image</b>
            </label>
            <br />
            <ImageUploader
              image={editContent?.cover_image}
              uploadedImage={uploadedCoverImage}
              handleFileUpload={handleCoverImgUpload}
            />
          </>
        ) : null}
        <br />
        <br />
        <label htmlFor="">
          <b>Web URL</b>
        </label>
        <br />
        <Input
          type="url"
          showCount
          className="input"
          name="web_url"
          onChange={handleInputChange}
          size={"large"}
          style={{ borderRadius: "10px" }}
          value={editContent.web_url}
          placeholder={"Enter Web Url"}
        />
        <br />
        <br />
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            onClick={() => {
              if (editContent.organization_name === "") {
                setBtnDisable(true);
                message.info("Please Enter organization");
              } else if (editContent.established_in === "") {
                setBtnDisable(true);
                message.info("Please Enter Established In");
              } else if (editContent.global_presence === "") {
                setBtnDisable(true);
                message.info("Please Enter Global Presence");
              } else if (editContent.description === "") {
                setBtnDisable(true);
                message.info("Please Enter Description");
              } else if (editContent.web_url === "") {
                setBtnDisable(true);
                message.info("Please Enter Web Url");
              } else {
                if (isEditable === true) {
                  updateArticle(editContent._id);
                } else {
                  if (editContent.logo === "") {
                    setBtnDisable(true);
                    message.info("Please upload a logo image");
                  } else {
                    addArticle();
                  }
                }
              }
            }}
            style={{
              marginTop: "10px",
            }}
            className="button"
            loading={loading}
            disabled={btnDisable ? true : false}
          >
            {isEditable === true ? "Update NGO" : "Add NGO"}
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default RegisterNGO;
