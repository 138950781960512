import React, { useState } from "react";
import "../datatable.scss";
import { Space, Table, Input, Button, Tag, Popover, Image } from "antd";
import type { ColumnsType } from "antd/es/table";

interface Contentdata {
  key?: string;
  _id: string;
  title: string;
  video: string;
  description: string;
  meta_keywords: any;
  status: string;
  publishOn: any;
  thumbnail: string;
}

function DataTableLatestVideos({
  updateVideoStatusToarchived,
  updateVideoStatusTopublished,
  setshowModel,
  setEditable,
  isOpen,
  contentData,
  deleteOtherVideoContent,
  editContent,
  showModal,
}: any) {
  const [searchText, setSearchText] = useState("");
  const [pagination, setPagination] = useState<any>({ page: 1, pageSize: 5 });

  const columns: ColumnsType<Contentdata> = [
    {
      title: "ID",
      dataIndex: "_id",
      key: "_id",
      filteredValue: [searchText],
      defaultSortOrder: "descend",
      sorter: (a: any, b: any) => a._id.localeCompare(b._id),
      onFilter: (value, record) => {
        return (
          String(record.title).toLowerCase().includes(value.toLocaleString()) ||
          String(record._id).toLowerCase().includes(value.toLocaleString()) ||
          String(record.title).toLowerCase().includes(value.toLocaleString()) ||
          String(record.title).toUpperCase().includes(value.toLocaleString()) ||
          String(record.status).toLowerCase().includes(value.toLocaleString())
        );
      },
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    // {
    //   title: "Description",
    //   dataIndex: "description",
    //   key: "description",
    //   render: (_, record) => (
    //     <>
    //       <p
    //         dangerouslySetInnerHTML={{
    //           __html: record?.description?.slice(0, 100),
    //         }}
    //       />
    //     </>
    //   ),
    // },
    {
      title: "Video",
      dataIndex: "video",
      key: "video",
      width: 120,
      render: (_, record) => (
        <a
          style={{ color: "#1677ff", textDecoration: "underline" }}
          href={record.video}
          target="_blank"
          rel="noreferrer"
        >
          See Video
        </a>
      ),
    },
    // {
    //   title: "Thumnail",
    //   dataIndex: "thumbnail",
    //   key: "thumbnail",
    //   render: (_, record) => (
    //     <Space>
    //       <Image
    //         width={80}
    //         height={80}
    //         src={record.thumbnail ? record.thumbnail : "noavatar.png"}
    //         alt="thumbnail"
    //       />
    //     </Space>
    //   ),
    // },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 120,
      render: (_, record) => (
        <Space>
          <Tag color={record.status === "active" ? "green" : "red"}>
            {record.status}
          </Tag>
        </Space>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Popover
            content={
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "10px",
                  flexDirection: "column",
                }}
              >
                <Button
                  style={{
                    backgroundColor: "green",
                    width: "100%",
                    color: "#fff",
                  }}
                  onClick={() => updateVideoStatusTopublished(record._id)}
                >
                  Publish
                </Button>
                <Button
                  style={{
                    backgroundColor: "red",
                    width: "100%",
                    color: "#fff",
                  }}
                  onClick={() => updateVideoStatusToarchived(record._id)}
                >
                  Archived
                </Button>
              </div>
            }
            title="Status Change"
          >
            <Button className="btn_edit_table">Status</Button>
          </Popover>
          <Button
            onClick={() => {
              setshowModel(!isOpen);
              setEditable(true);
              editContent(record._id);
            }}
            className="btn_edit_table"
          >
            Edit
          </Button>
          <Popover
            content={
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "10px",
                }}
              >
                <Button
                  style={{
                    backgroundColor: "green",
                    width: "100%",
                    color: "#fff",
                  }}
                  onDoubleClick={() => deleteOtherVideoContent(record._id)}
                >
                  Yes
                </Button>
              </div>
            }
            title="Please Double click to delete."
          >
            <Button className="btn_edit_table">Delete</Button>
          </Popover>
        </Space>
      ),
    },
  ];

  return (
    <div className="dataTable">
      <div className="filterMenu">
        <Button onClick={showModal} className="add_button">
          Add Events Videos
        </Button>
        <Input.Search
          onSearch={(value) => {
            setSearchText(value);
          }}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
          className="searchField"
          placeholder="Search here..."
        />
      </div>
      <Table
        scroll={{ y: 500 }}
        className="dataGrid"
        columns={columns}
        dataSource={contentData}
        pagination={{
          pageSize: pagination?.pageSize,
          onChange(page, pageSize) {
            setPagination(pageSize);
          },
          showSizeChanger: true,
        }}
      />
    </div>
  );
}

export default DataTableLatestVideos;
