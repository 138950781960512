import React, { useEffect, useState } from "react";
import "./article.scss";
import { Select } from "antd";
import { Button, Typography, Modal, Input, message, Form } from "antd";
import { blogshop } from "../../services";
import ImageUploader from "../../components/ImageUploader";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Option } from "antd/es/mentions";
import DataTableBlog from "../../components/dataTable/DataTableBlog";
import { CSVLink } from "react-csv";
import axios from "axios";
import useSWR from "swr";
import * as Cookie from "universal-cookie";
import ImageUploaderOne from "../../components/ImageUploaderOne";

const cookie = new Cookie.default();
const cachedToken = cookie.get("userAuthTokenAdmin");

const { Text } = Typography;

interface Contentdata {
  key?: string;
  id: string;
  title: string;
  body: string;
  image: string;
  hash: any;
  meta_title: string;
  meta_description: string;
  meta_keyword: any;
  blog_site: any;
  author_profile: string;
  author_name: string;
  author_designation: string;
  author_company: string;
  author_social_link: string;
  author_description: any;
}

const fetcher = (url: any) => axios.get(url).then((res) => res?.data?.data);

function Blog() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [editContent, setEditContent] = useState<Contentdata>({
    id: "",
    body: "",
    title: "",
    image: "",
    hash: null,
    blog_site: null,
    meta_title: "",
    meta_description: "",
    meta_keyword: null,
    author_profile: "",
    author_name: "",
    author_designation: "",
    author_company: "",
    author_social_link: "",
    author_description: "",
  });
  // const [contentData, setContentData] = useState<Contentdata[]>([]);
  const [uploadedImage, setUploadedImage] = useState<any>(null);
  const [uploadedAuthorImage, setUploadedAuthorImage] = useState<any>(null);
  const [loading, setloading] = useState<boolean>(false);
  const API_PATH = process.env.REACT_APP_API_URL;
  const [btnDisable, setBtnDisable] = useState(false);

  const { data: contentData, mutate } = useSWR(
    API_PATH + "/blog/getAll/",
    fetcher,
    {
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
    }
  );

  // const csvData = contentData?.map((item) => ({
  //   id: item?.id,
  //   title: item?.title,
  //   blog_site: item?.blog_site ? item?.blog_site.split(",") : null,
  // }));

  const datahash = [
    { id: "1", label: "wellness" },
    { id: "2", label: "health" },
    { id: "3", label: "fitness" },
    { id: "4", label: "healthylifestyle" },
    { id: "5", label: "selfcare" },
    { id: "6", label: "motivation" },
    { id: "7", label: "love" },
    { id: "8", label: "healthy" },
    { id: "9", label: "mentalhealth" },
    { id: "10", label: "lifestyle" },
    { id: "11", label: "yoga" },
    { id: "12", label: "beauty" },
    { id: "13", label: "nutrition" },
    { id: "14", label: "healing" },
    { id: "15", label: "selflove" },
    { id: "16", label: "mindfulness" },
    { id: "17", label: "healthyliving" },
    { id: "18", label: "wellbeing" },
    { id: "19", label: "workout" },
    { id: "20", label: "meditation" },
    { id: "21", label: "skincare" },
    { id: "22", label: "relax" },
    { id: "23", label: "happiness" },
    { id: "24", label: "fitnessmotivation" },
    { id: "25", label: "weightloss " },
    { id: "26", label: "fit" },
  ];
  const blogsites = [
    { id: "1", label: "pcube" },
    { id: "2", label: "colornostics" },
    { id: "3", label: "soulsara" },
    { id: "4", label: "shop" },
  ];
  // const getOtherContent = async () => {
  //   blogshop
  //     .getThemeposts()
  //     .then((res: any) => {
  //       setContentData(res.data.data);
  //     })
  //     .catch((err: any) => {
  //       message.error(err.message);
  //     });
  // };

  // useEffect(() => {
  //   getOtherContent();
  // }, []);

  const deleteArticle = (id: string) => {
    blogshop
      .deleteThemeposts(id)
      .then((res: any) => {
        // getOtherContent();
        mutate();
        message.success("Deleted Successfully");
      })
      .catch((err: any) => {
        message.error(err.message);
      });
  };

  const updateArticle = (id: string) => {
    setloading(true);
    //  const updatedHash = editContent.hash.filter((item:any) => !editContent.hash.includes(item));
    //   console.log(updatedHash);
    // handleUploadAuthorProfile()
    const formData: any = new FormData();
    formData.append("title", editContent.title);
    formData.append("body", editContent.body);
    formData.append("image", editContent.image);
    formData.append("hash", editContent.hash);
    formData.append("blog_site", editContent.blog_site);
    formData.append("meta_title", editContent.meta_title);
    formData.append("meta_description", editContent.meta_description);
    formData.append("meta_keyword", editContent.meta_keyword);
    // formData.append("author_profile", editContent.author_profile);
    formData.append("author_company", editContent.author_company);
    formData.append("author_description", editContent.author_description);
    formData.append("author_designation", editContent.author_designation);
    formData.append("author_name", editContent.author_name);
    formData.append("author_social_link", editContent.author_social_link);

    blogshop
      .updateThemeposts(id, formData)
      .then((res: any) => {
        setloading(false);
        // getOtherContent();
        mutate();

        message.success("Updated Successfully");
        handleUploadAuthorProfile();
        setIsModalOpen(false);
      })
      .catch((err: any) => {
        message.error(err.message);
        setloading(false);
      });
  };
  const addArticle = () => {
    setloading(true);
    const formData: any = new FormData();
    formData.append("title", editContent.title);
    formData.append("body", editContent.body);
    formData.append("image", editContent.image);
    formData.append("hash", editContent.hash);
    formData.append("blog_site", editContent.blog_site);
    formData.append("meta_title", editContent.meta_title);
    formData.append("meta_description", editContent.meta_description);
    formData.append("meta_keyword", editContent.meta_keyword);
    // formData.append("author_profile", "sss");
    formData.append("author_name", editContent.author_name);
    formData.append("author_company", editContent.author_company);
    formData.append("author_description", editContent.author_description);
    formData.append("author_designation", editContent.author_designation);
    formData.append("author_social_link", editContent.author_social_link);
    blogshop
      .addThemeposts(formData)
      .then((res: any) => {
        setloading(false);
        // getOtherContent();
        mutate();

        message.success("Added Successfully");
        setEditContent({
          id: "",
          title: "",
          body: "",
          image: "",
          hash: "",
          blog_site: "",
          meta_title: "",
          meta_description: "",
          meta_keyword: null,
          author_profile: "",
          author_name: "",
          author_designation: "",
          author_company: "",
          author_social_link: "",
          author_description: "",
        });
        setUploadedImage(null);
        setUploadedAuthorImage(null);
        setIsModalOpen(false);
      })
      .catch((err: any) => {
        message.error(err.message);
        setloading(false);
      });
  };
  const editArticle = (id: string) => {
    blogshop
      .editThemeposts(id)
      .then((res: any) => {
        const data = res.data.data;
        // console.log(data.author_profile, "single data");

        setEditContent({
          id: data._id,
          title: data.title,
          body: data.body,
          image: data.image,
          hash: data?.hash ? data?.hash.toString()?.split(",") : null,
          blog_site: data?.blog_site ? data?.blog_site.split(",") : null,
          meta_title: data.meta_title,
          meta_description: data.meta_description,
          meta_keyword:
            data.meta_keyword !== null ? data.meta_keyword.split(",") : null,
          author_profile: data.author_profile,
          author_name: data.author_name,
          author_designation: data.author_designation,
          author_company: data.author_company,
          author_social_link: data.author_social_link,
          author_description: data.author_description,
        });
      })
      .catch((err: any) => {
        message.error(err.message);
      });
  };

  const showModal = () => {
    setIsModalOpen(true);
    setIsEditable(false);
    setEditContent({
      id: "",
      title: "",
      body: "",
      image: "",
      hash: null,
      blog_site: null,
      meta_title: "",
      meta_description: "",
      meta_keyword: null,
      author_profile: "",
      author_name: "",
      author_designation: "",
      author_company: "",
      author_social_link: "",
      author_description: "",
    });
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setEditContent({
      id: "",
      title: "",
      body: "",
      image: "",
      hash: null,
      blog_site: null,
      meta_title: "",
      meta_description: "",
      meta_keyword: null,
      author_profile: "",
      author_name: "",
      author_designation: "",
      author_company: "",
      author_social_link: "",
      author_description: "",
    });
    setUploadedImage(null);
    setUploadedAuthorImage(null);

    setloading(false);
  };

  const handleFileUpload = async (event: any) => {
    event.preventDefault();
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setUploadedImage(reader.result);
    };
    setBtnDisable(false);
    setEditContent((prevData) => ({
      ...prevData,
      image: file,
    }));

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleAuthorProfileFileUpload = async (event: any) => {
    event.preventDefault();
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setUploadedAuthorImage(reader.result);
    };
    setBtnDisable(false);
    setEditContent((prevData) => ({
      ...prevData,
      author_profile: file,
    }));

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleUploadAuthorProfile = async () => {
    const formData: any = new FormData();

    formData.append("image", editContent.author_profile);
    blogshop
      .updateAuthorImage(editContent?.id, formData)
      .then((res) => {
        message.info("author profile uploaded successfully");
      })
      .catch((err) => {});
    // await axios.post(`https://hrxyv5wesk.us-east-1.awsapprunner.com/blog/updateAuthorImage/668e87097665b58f854dc4a0`, formData, {
    //     headers: {
    //       Authorization: `${cachedToken}`,
    //       "Content-Type": "application/json",
    //     },
    //   })
    //   .then((res) => {
    //     message.info("author profile uploaded successfully");
    //   })
    //   .catch((err) => { });
  };
  const handleInputChange = (event: any) => {
    setBtnDisable(false);
    const { name, value } = event.target;
    setEditContent((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleChange = (value: string[]) => {
    setBtnDisable(false);
    setEditContent((prevData) => ({
      ...prevData,
      hash: value,
    }));
  };
  const handleSitesChange = (value: string[]) => {
    setBtnDisable(false);
    setEditContent((prevData) => ({
      ...prevData,
      blog_site: value,
    }));
  };
  const handleChangeKw = (value: string[]) => {
    setBtnDisable(false);
    setEditContent((prevData) => ({
      ...prevData,
      meta_keyword: value,
    }));
  };
  const handleDescription = (value: any) => {
    setBtnDisable(false);
    setEditContent((prevData) => ({
      ...prevData,
      body: value,
    }));
  };

  const handleAuthorDescription = (value: any) => {
    setBtnDisable(false);
    setEditContent((prevData) => ({
      ...prevData,
      author_description: value,
    }));
  };

  // console.log(editContent?.author_profile, "ss");

  return (
    <div className="contentBox">
      <div className="info">
        <Text className="title24">Blog</Text>
      </div>
      {/* <CSVLink data={csvData} filename={"exported-data.csv"}>
        <Button type="primary" style={{ marginTop: "16px" }}>
          Export CSV
        </Button>
      </CSVLink> */}

      <DataTableBlog
        deleteArticle={deleteArticle}
        editContent={editArticle}
        setshowModel={setIsModalOpen}
        setEditable={setIsEditable}
        isOpen={isModalOpen}
        contentData={contentData}
        showModal={showModal}
      />
      <Modal
        footer={false}
        title={isEditable === true ? "Update  Blog" : "Add Blog"}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        style={{ width: "80%" }}
      >
        <label htmlFor="">
          <b>Select Sites</b>
        </label>
        <Select
          value={editContent?.blog_site ?? []}
          mode={"tags"}
          allowClear
          style={{ width: "100%", marginBottom: "16px" }}
          placeholder="Please select a sites"
          onChange={handleSitesChange}
        >
          {blogsites?.map((option) => (
            <Option key={option.id} value={option.label}>
              {option.label}
            </Option>
          ))}
        </Select>
        <label htmlFor="">
          <b>Select hash Tags</b>
        </label>
        <Select
          value={editContent.hash ?? []}
          mode={"tags"}
          allowClear
          style={{ width: "100%", marginBottom: "16px" }}
          placeholder="Please select a hash tag"
          onChange={handleChange}
        >
          {datahash.map((option) => (
            <Option key={option.id} value={option.label}>
              {option.label}
            </Option>
          ))}
        </Select>
        {editContent.hash === null ? null : (
          <>
            <label htmlFor="">
              <b>Blog Title</b>
            </label>
            <Input
              className="input"
              name="title"
              onChange={handleInputChange}
              size={"large"}
              style={{ marginBottom: "16px" }}
              value={editContent.title}
              placeholder={"Enter Title"}
            />
          </>
        )}

        {editContent.title === "" ? null : (
          <>
            <label htmlFor="">
              <b>Blog Description</b>
            </label>
            <ReactQuill
              style={{ marginBottom: "16px" }}
              value={editContent.body}
              onChange={handleDescription}
              modules={{
                toolbar: [
                  [{ header: [1, 2, 3, 4, 5, 6, false] }, { font: [] }],
                  ["bold", "italic", "underline", "strike", "blockquote"],
                  [
                    { list: "ordered" },
                    { list: "bullet" },
                    { indent: "-1" },
                    { indent: "+1" },
                  ],
                  ["link", "image"],
                  [{ align: [] }],
                  [{ color: [] }, { background: [] }],
                  ["code-block"],
                  ["clean"], // remove formatting button
                ],
                clipboard: {
                  matchVisual: false,
                },
              }}
            />
          </>
        )}

        {editContent.body === "" ? null : (
          <div style={{ marginBottom: "16px" }}>
            <label htmlFor="">
              <b>Blog Thumbnail</b>
            </label>
            <ImageUploaderOne
              image={editContent?.image}
              uploadedImage={uploadedImage ?? editContent?.image}
              handleFileUpload={handleFileUpload}
            />
          </div>
        )}

        {editContent.image === "" ? null : (
          <>
            <label htmlFor="">
              <b>Author Name</b>
            </label>
            <Input
              className="input"
              name="author_name"
              onChange={handleInputChange}
              size={"large"}
              style={{ marginBottom: "16px" }}
              value={editContent.author_name}
              placeholder={"Enter Author Name"}
            />
          </>
        )}

        {editContent.author_name === "" ? null : (
          <>
            <label htmlFor="">
              <b>Author Desgination</b>
            </label>
            <Input
              className="input"
              name="author_designation"
              onChange={handleInputChange}
              size={"large"}
              style={{ marginBottom: "16px" }}
              value={editContent.author_designation}
              placeholder={"Enter Author Designation"}
            />
          </>
        )}

        {editContent.author_designation === "" ? null : (
          <>
            <label htmlFor="">
              <b>Author Company</b>
            </label>
            <Input
              className="input"
              name="author_company"
              onChange={handleInputChange}
              size={"large"}
              style={{ marginBottom: "16px" }}
              value={editContent.author_company}
              placeholder={"Enter Author Company"}
            />
          </>
        )}

        {editContent.author_company === "" ? null : (
          <>
            <label htmlFor="">
              <b>Author LinkedIn URL</b>
            </label>
            <Input
              className="input"
              name="author_social_link"
              onChange={handleInputChange}
              size={"large"}
              style={{ marginBottom: "16px" }}
              value={editContent.author_social_link}
              placeholder={"Enter Author Social Link"}
            />
          </>
        )}

        {editContent.author_social_link === "" ? null : (
          <>
            <label htmlFor="">
              <b>Author Description</b>
            </label>
            <ReactQuill
              style={{ marginBottom: "16px" }}
              value={editContent.author_description}
              onChange={handleAuthorDescription}
              modules={{
                toolbar: [
                  [{ header: [1, 2, 3, 4, 5, 6, false] }, { font: [] }],
                  ["bold", "italic", "underline", "strike", "blockquote"],
                  [
                    { list: "ordered" },
                    { list: "bullet" },
                    { indent: "-1" },
                    { indent: "+1" },
                  ],
                  ["link", "image"],
                  [{ align: [] }],
                  [{ color: [] }, { background: [] }],
                  ["code-block"],
                  ["clean"], // remove formatting button
                ],
                clipboard: {
                  matchVisual: false,
                },
              }}
            />
          </>
        )}

        {isEditable === false ? null : isEditable === true &&
          editContent.author_description === "" ? null : (
          <div style={{ marginBottom: "16px" }}>
            <label htmlFor="">
              <b>Upload Author Profile</b>
            </label>
            <ImageUploaderOne
              image={editContent?.author_profile}
              uploadedImage={uploadedAuthorImage ?? editContent?.author_profile}
              handleFileUpload={handleAuthorProfileFileUpload}
            />
          </div>
        )}
        <label htmlFor="">
          <b>Meta Keyword</b>
        </label>
        <Select
          value={editContent.meta_keyword ?? []}
          mode={"tags"}
          allowClear
          style={{ width: "100%", marginBottom: "16px" }}
          placeholder="Please SELECT/ADD a meta_keyword"
          onChange={handleChangeKw}
        >
          {editContent.meta_keyword?.map((option: any) => (
            <Option key={option.id} value={option.label}>
              {option.label}
            </Option>
          ))}
        </Select>
        <label htmlFor="">
          <b>Meta Title</b>
        </label>
        <Input
          showCount
          maxLength={60}
          className="input"
          name="meta_title"
          onChange={handleInputChange}
          size={"large"}
          style={{ marginBottom: "16px" }}
          value={editContent.meta_title}
          placeholder={"Enter Meta Title"}
        />
        <label htmlFor="">
          <b>Meta Description</b>
        </label>
        <Input.TextArea
          showCount
          maxLength={160}
          className="input"
          name="meta_description"
          onChange={handleInputChange}
          size={"large"}
          style={{ marginBottom: "16px" }}
          value={editContent.meta_description}
          placeholder={"Enter Meta Description"}
        />
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            onClick={() => {
              if (editContent.blog_site === null) {
                setBtnDisable(true);
                message.info("Please Selected Blog Site");
              } else if (editContent.hash === null) {
                setBtnDisable(true);
                message.info("Please Selected a hash");
              } else if (editContent.title === "") {
                setBtnDisable(true);
                message.info("Please Enter title");
              } else if (editContent.body === "") {
                setBtnDisable(true);
                message.info("Please Enter Content");
              } else {
                if (isEditable === true) {
                  updateArticle(editContent.id);
                  // handleUploadAuthorProfile()
                } else {
                  if (editContent.image === "") {
                    setBtnDisable(true);
                    message.info("Please upload a image");
                  } else {
                    addArticle();
                  }
                }
              }
            }}
            style={{
              marginTop: "16px",
            }}
            className="button"
            loading={loading}
            disabled={btnDisable ? true : false}
          >
            {isEditable === true ? "Update Blog" : "Add Blog"}
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default Blog;
