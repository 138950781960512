import React, { useEffect, useState } from "react";
import { Button, Typography, Input, message, ColorPicker, Form } from "antd";
import { authSVC } from "../../services";
import { useB2BContext } from "../../context/ContextB2B";
import type { ColorPickerProps } from "antd";
import { useNavigate, useLocation } from "react-router-dom";

// const { Text } = Typography;

const departmmentColors = [
  "#479751",
  "#CB2E89",
  "#9EC756",
  "#D476AA",
  "#F5EE56",
  "#65529C",
  "#EBB949",
  "#84559D",
  "#E6893C",
  "#233264",
  "#D56135",
  "#9891C2",
  "#D94834",
  "#3170B5",
  "#B93347",
  "#4FA1A5",
];

function EditDepartments() {
  const { getUserByID } = useB2BContext();
  const [loading, setloading] = useState<boolean>(false);
  const [allDepartments, setAllDepartments] = useState<any>();
  const [departmentName, setDepartmentName] = useState<any>("");
  const [editDepartment, setEditDepartment] = useState<any>();
  const [form] = Form.useForm();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const departmentId = queryParams.get("did");
  const [colorValue, setColorValue] = useState<ColorPickerProps["value"]>("");

  const getDepartments = () => {
    authSVC
      .getCompanyDepartments(getUserByID?.company)
      .then((res: any) => {
        setAllDepartments(res.data.data);
        res.data.data.map((data: any) => {
          if (data._id === departmentId) {
            setEditDepartment(data);
          }
        });
      })
      .catch((err: any) => {
        message.error(err.message);
      });
  };

  useEffect(() => {
    getDepartments();
  }, []);

  useEffect(() => {
    if (editDepartment) {
      console.log(editDepartment, "ddd");
    }
  }, [editDepartment]);

  const onFinish = () => {
    authSVC
      .editDepartment({
        // companyName: getUserByID?.company,
        // departments: [
        //   {
        //     name: departmentName,
        //     color: colorValue,
        //   },
        // ],
        companyName: getUserByID?.company,
        department_id: editDepartment._id,
        department_name: departmentName,
        department_color: colorValue,
      })
      .then((res: any) => {
        message.success("update Successfully");
        form.resetFields();
        setColorValue("");
        getDepartments();
        navigate("/departments");
      })
      .catch((err: any) => {
        message.error(err.message);
      });
  };

  const nameValidation = (_: any, value: any) => {
    // Use a regular expression to validate that the input contains only letters
    const regex = /^[A-Za-z\s]+$/;

    if (!value || regex.test(value)) {
      return Promise.resolve();
    }

    return Promise.reject(
      new Error("Please enter a valid name with only letters.")
    );
  };

  return (
    <div className="add_form_wrapper">
      <h1>Edit Department</h1>
      <Form
        name="normal_login"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        form={form}
      >
        <Form.Item
          name="name"
          rules={[
            {
              required: true,
              message: "Please Enter Department Name",
            },
            {
              validator: nameValidation,
            },
          ]}
          style={{ marginBottom: "16px" }}
        >
          <Input
            name="department"
            className="input"
            onChange={(e: any) => setDepartmentName(e?.target?.value)}
            type="text"
            placeholder="Please Enter Department Name"
          />
        </Form.Item>

        <Form.Item name={"color"} style={{ marginBottom: "16px" }}>
          <div className="departmentColors">
            {departmmentColors?.map((item, index) => (
              <div
                key={index}
                onClick={() => {
                  setColorValue(item);
                  message.info(`Selected Department color is ${item}`);
                }}
                style={{ backgroundColor: item }}
                className="colorBox"
              />
            ))}
            <ColorPicker
              value={colorValue}
              onChangeComplete={(color) => {
                setColorValue(color.toHexString());
                message.info(
                  `Selected Department color is ${color.toHexString()}`
                );
              }}
            />
          </div>
        </Form.Item>
        <p style={{ marginBottom: "16px" }}>
          Before Adding Employees, It’s Essential To Create Departments. This
          Enhances The Wellness Tracking Experience.{" "}
        </p>
        <Button className="cn_btn" htmlType="submit" loading={loading}>
          Save
        </Button>
      </Form>
    </div>
  );
}

export default EditDepartments;
