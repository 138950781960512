import React, { useEffect, useState } from "react";
import "./datatable.scss";
import { Space, Table, Input, Button, Popover } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useNavigate } from "react-router-dom";

interface Contentdata {
  key?: string;
  _id: number;
  name?: string;
  color?: string;
}

interface DataTableProps {
  setshowModel: (value: boolean) => void;
  deleteDepartment: any;
  isOpen: boolean;
  contentData: Contentdata[];
}
function DataTableDepartments({
  contentData,
  deleteDepartment,
}: DataTableProps) {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [pagination, setPagination] = useState<any>({ page: 1, pageSize: 5 });

  useEffect(() => {
    console.log(contentData, "dfsdfsd");
  }, [contentData]);

  const columns: ColumnsType<Contentdata> = [
    {
      title: "ID",
      dataIndex: "_id",
      key: "_id",
      filteredValue: [searchText],
      defaultSortOrder: "descend",
      sorter: (a: any, b: any) => a._id.localeCompare(b._id),
      ellipsis: true,
      onFilter: (value, record) => {
        return (
          String(record._id).toLowerCase().includes(value.toLocaleString()) ||
          String(record.name).toLowerCase().includes(value.toLocaleString()) ||
          String(record.name).toLowerCase().includes(value.toLocaleString())
        );
      },
    },
    {
      title: "Department Name",
      dataIndex: "name",
      // key: "name",
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
    },
    {
      title: "Color",
      dataIndex: "color",
      key: "color",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={() => navigate(`/editdepartments?did=${record._id}`)}
          >
            Edit
          </Button>
          <Popover
            content={
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "10px",
                }}
              >
                <Button
                  style={{ backgroundColor: "green", width: "100%" }}
                  onClick={() => deleteDepartment(record?.name)}
                >
                  Yes
                </Button>
              </div>
            }
            title="Please Double click to delete."
          >
            <Button>Delete</Button>
          </Popover>
        </Space>
      ),
    },
  ];

  return (
    <div className="dataTable">
      <div className="filterMenu">
        <div></div>
        <Input.Search
          onSearch={(value) => {
            setSearchText(value);
          }}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
          className="searchField"
          placeholder="Search here..."
        />
      </div>
      <Table
        scroll={{ y: 500 }}
        className="dataGrid"
        columns={columns}
        dataSource={contentData}
        pagination={{
          pageSize: pagination?.pageSize,
          onChange(page, pageSize) {
            setPagination(pageSize);
          },
          showSizeChanger:true
        }}
      />
    </div>
  );
}

export default DataTableDepartments;
