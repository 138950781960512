import React, { useEffect, useState } from "react";
import "./article.scss";
import { Select } from "antd";
import { Button, Typography, Modal, Input, message, Form } from "antd";
import ImageUploader from "../../components/ImageUploader";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { ngoarticlesSVC, ngoSVC } from "../../services";
import DataTableArticleNGO from "../../components/dataTable/DataTableArticleNGO";
import useSWR from 'swr';
import axios from 'axios';
import * as Cookie from "universal-cookie";

const cookie = new Cookie.default();
const cachedToken = cookie.get("userAuthTokenAdmin");

const { Text } = Typography;

interface Contentdata {
  key?: string;
  _id: string;
  title: string;
  content: string;
  image: string;
  ngo_id: string;
}
const fetcher = (url: any) => axios.get(url, {
  headers: {
    'Authorization': `Bearer ${cachedToken}`,
    'Content-Type': 'application/json'
  }
}).then(res => res?.data?.data);

function NGOArticle() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [editContent, setEditContent] = useState<Contentdata>({
    _id: "",
    content: "",
    title: "",
    image: "",
    ngo_id: "",
  });
  // const [contentData, setContentData] = useState<Contentdata[]>([]);
  // const [getNGOData, setNGOData] = useState<any[]>([]);
  const [uploadedImage, setUploadedImage] = useState<any>(null);
  const [loading, setloading] = useState<boolean>(false);

  const API_URL = process.env.REACT_APP_API_URL;

  const { data: contentData, mutate: getOtherContent } = useSWR(API_URL + '/ngo/all/', fetcher, {
    revalidateOnFocus: true,
    revalidateOnReconnect: true
  });

  const { data: getNGOData, mutate: getNgo } = useSWR(API_URL + '/ngo_organization/all/', fetcher, {
    revalidateOnFocus: true,
    revalidateOnReconnect: true
  });
  // const getOtherContent = async () => {
  //   ngoarticlesSVC
  //     .getThemeposts()
  //     .then((res: any) => {
  //       setContentData(res.data.data);
  //     })
  //     .catch((err: any) => {
  //       message.error(err.message);
  //     });
  // };

  // const getNgo = async () => {
  //   ngoSVC
  //     .getorganization()
  //     .then((res: any) => {
  //       setNGOData(res.data.data);
  //     })
  //     .catch((err: any) => {
  //       message.error(err.message);
  //     });
  // };

  // useEffect(() => {
    // getOtherContent();
    // getNgo();
  // }, []);

  const deleteArticle = (id: string) => {
    ngoarticlesSVC
      .deleteThemeposts(id)
      .then((res: any) => {
        getOtherContent();
        message.success("Deleted Successfully");
      })
      .catch((err: any) => {
        message.error(err.message);
      });
  };

  const updateArticle = (id: string) => {
    setloading(true);
    const formData: any = new FormData();
    formData.append("title", editContent.title);
    formData.append("content", editContent.content);
    formData.append("image", editContent.image);
    formData.append("ngo_id", editContent.ngo_id);

    ngoarticlesSVC
      .updateThemeposts(id, formData)
      .then((res: any) => {
        setloading(false);
        getOtherContent();
        message.success("Updated Successfully");
        handleCancel();
      })
      .catch((err: any) => {
        message.error(err.message);
        setloading(false);
      });
  };
  const addArticle = () => {
    setloading(true);
    const formData: any = new FormData();
    formData.append("title", editContent.title);
    formData.append("content", editContent.content);
    formData.append("image", editContent.image);
    formData.append("ngo_id", editContent.ngo_id);
    ngoarticlesSVC
      .addThemeposts(formData)
      .then((res: any) => {
        setloading(false);
        getOtherContent();
        message.success("Added Successfully");
        setEditContent({
          _id: "",
          title: "",
          content: "",
          image: "",
          ngo_id: "",
        });
        setUploadedImage(null);
        handleCancel();
      })
      .catch((err: any) => {
        message.error(err.message);
        setloading(false);
      });
  };
  const editArticle = (id: string) => {
    ngoarticlesSVC
      .editThemeposts(id)
      .then((res: any) => {
        const data = res.data.data;
        setEditContent({
          _id: data._id,
          title: data.title,
          content: data.content,
          image: data.image,
          ngo_id: data.ngo_id,
        });
      })
      .catch((err: any) => {
        message.error(err.message);
      });
  };

  const showModal = () => {
    setIsModalOpen(true);
    setIsEditable(false);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setEditContent({ _id: "", title: "", content: "", image: "", ngo_id: "" });
    setUploadedImage(null);
    setloading(false);
  };

  const handleFileUpload = async (event: any) => {
    event.preventDefault();
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setUploadedImage(reader.result);
    };

    setEditContent((prevData) => ({
      ...prevData,
      image: file,
    }));

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    setEditContent((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSelectedItem = (value: string) => {
    setEditContent((prevData) => ({
      ...prevData,
      ngo_id: value,
    }));
  };

  const handleDescription = (value: any) => {
    setEditContent((prevData) => ({
      ...prevData,
      content: value,
    }));
  };

  return (
    <div className="contentBox">
      <div className="info">
        <Text className="title24">Blog</Text>
        <Button onClick={showModal} className="button">
          Add New Blog
        </Button>
      </div>
      <DataTableArticleNGO
        deleteArticle={deleteArticle}
        editContent={editArticle}
        setshowModel={setIsModalOpen}
        setEditable={setIsEditable}
        isOpen={isModalOpen}
        contentData={contentData}
      />
      <Modal
        width={"80%"}
        footer={false}
        title={isEditable === true ? "Update  Blog" : "Add Blog"}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <label htmlFor="">
          <b>Select a NGO</b>
        </label>
        <br />
        {editContent.ngo_id === "" ? (
          <Select
            showSearch
            style={{ width: "100%", borderRadius: "24px" }}
            placeholder="Select a Theme"
            optionFilterProp="children"
            onChange={handleSelectedItem}
            filterOption={(input: any, option: any) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={getNGOData?.map((option: any) => ({
              value: option.organization_name,
              label: option.organization_name,
            }))}
          />
        ) : (
          <Select
            value={editContent.ngo_id}
            showSearch
            style={{ width: "100%", borderRadius: "24px" }}
            placeholder="Select a Theme"
            optionFilterProp="children"
            onChange={handleSelectedItem}
            filterOption={(input: any, option: any) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={getNGOData?.map((option:any) => ({
              value: option?.organization_name,
              label: option?.organization_name,
            }))}
          />
        )}
        <br />
        <br />
        <label htmlFor="">
          <b>Blog Title</b>
        </label>
        <br />
        <Input
          className="input"
          name="title"
          onChange={handleInputChange}
          size={"large"}
          style={{ borderRadius: "10px" }}
          value={editContent.title}
          placeholder={"Enter Title"}
        />

        <br />
        <br />
        <label htmlFor="">
          <b>Blog Discription</b>
        </label>
        <br />
        <ReactQuill
          style={{ height: 300 }}
          value={editContent.content}
          onChange={handleDescription}
          modules={{
            toolbar: [
              // [{ 'header': '1' }, { 'header': '2' }],
              ["bold", "italic", "underline", "strike", "blockquote"],
              [{ list: "ordered" }, { list: "bullet" }],
              ["link"],
              ["clean"],
              ["image"],
            ],
          }}
        />

        <br />
        <br />
        <br />
        <ImageUploader
          image={editContent?.image}
          uploadedImage={uploadedImage}
          handleFileUpload={handleFileUpload}
        />

        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            onClick={() => {
              if (editContent.ngo_id === "") {
                message.info("Please Selected a NGO");
              } else if (editContent.title === "") {
                message.info("Please Enter title");
              } else if (editContent.content === "") {
                message.info("Please Enter Content");
              } else {
                if (isEditable === true) {
                  updateArticle(editContent._id);
                } else {
                  if (editContent.image === "") {
                    message.info("Please upload a image");
                  } else {
                    addArticle();
                  }
                }
              }
            }}
            style={{
              marginTop: "10px",
            }}
            className="button"
            loading={loading}
          >
            {isEditable === true ? "Update Blog" : "Add Blog"}
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default NGOArticle;
