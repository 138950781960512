import { AxiosPromise } from 'axios';

const API_PATH = process.env.REACT_APP_API_URL;

type API = {
  post: (url: string, payload: any) => AxiosPromise
  put: (url: string, payload: any) => AxiosPromise
  get: (url: string) => AxiosPromise
  delete: (id: string) => AxiosPromise
}

const otherVideoContents = (api: API) => {

  // latest videos
  const addLatestVideos = (payload: any) => api.post(API_PATH + '/times/video-contents/', payload)
  const getLatestVideos = () => api.get(API_PATH + '/times/video-contents/')
  const deleteLatestVideos = (id: string) => api.delete(API_PATH + `/times/video-contents/${id}`)
  const editLatestVideos = (id: string) => api.get(API_PATH + `/times/video-contents/${id}`)
  const updateLatestVideos = (id: string, payload: any) => api.put(API_PATH + `/times/video-contents/${id}`, payload)



  return {
    addLatestVideos,
    getLatestVideos,
    deleteLatestVideos,
    editLatestVideos,
    updateLatestVideos
  }
}

export default otherVideoContents
