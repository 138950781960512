import React, { useEffect, useState } from "react";
import { Button, Typography, Modal, Input, message, Form, Select } from "antd";
import { commentsSVC, authSVC } from "../../services";
import DemoUserTable from "../../components/dataTable/b2b/DemoUserTable";
import useSWR from "swr";
import axios from "axios";
import * as Cookie from "universal-cookie";

const cookie = new Cookie.default();
const cachedToken = cookie.get("userAuthTokenAdmin");

const { Text } = Typography;

interface Contentdata {
  key?: string;
  _id: string;
  bodyText: string;
  user_id: string;
  video_id: string;
}

const fetcher = (url: any) =>
  axios
    .get(url, {
      headers: {
        Authorization: `Bearer ${cachedToken}`,
        "Content-Type": "application/json",
      },
    })
    .then((res) => res?.data?.data);

function DemoSchedule() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const [contentData, setContentData] = useState<Contentdata[]>([]);

  const API_URL = process.env.REACT_APP_API_URL;

  const { data: contentData, mutate: getDemoUsers } = useSWR(
    API_URL + "/businessLead/",
    fetcher,
    {
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
    }
  );

  // const getDemoUsers = async () => {
  //   authSVC
  //     .DemoUsers()
  //     .then((res: any) => {
  //       console.log(res.data.data);

  //       setContentData(res.data.data);
  //     })
  //     .catch((err: any) => {
  //       message.error(err.message);
  //     });
  // };

  // useEffect(() => {
  //   getDemoUsers();
  // }, []);

  return (
    <div className="contentBox">
      <div className="info">
        <Text className="title24"> Demo Requests</Text>
      </div>
      <DemoUserTable
        //deleteComments={deleteComments}
        setshowModel={setIsModalOpen}
        isOpen={isModalOpen}
        contentData={contentData}
      />
    </div>
  );
}

export default DemoSchedule;
