import React, { useEffect, useState } from 'react'
import './themes.scss'
import { Button, Typography, Modal, Input, message, Form, } from 'antd'
import { themesSVC } from '../../services';
import DataTableTheme from '../../components/dataTable/DataTableTheme';
const { Text } = Typography;

interface Contentdata {
  id: number,
  name: string
}
function Themes() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [editContent, setEditContent] = useState({ id: "", name: "" })
  const [contentData, setContentData] = useState<Contentdata[]>([])
  const [loading, setloading] = useState<boolean>(false)


  const getThemes = async () => {
    themesSVC.getThemes().then((res: any) => {
      setContentData(res.data)
    }).catch((err: any) => {
      message.error(err.message);
    })
  }
  
  useEffect(() => {
    getThemes()
  }, [])

  const deleteThemes = (id: string) => {
    themesSVC.deleteThemes(id).then((res: any) => {
      getThemes()
      message.success("Deleted Successfully")
      setEditContent({ id: "", name: "" })
    }).catch((err: any) => {
      message.error(err.message);
    })
  }

  const updateThemes = (id: string) => {
    setloading(true)
    themesSVC.updateThemes(id, {
      name: editContent.name
    }).then((res: any) => {
      setloading(false)
      getThemes()
      message.success("Theme Updated Successfully")
      handleCancel()
     
    }).catch((err: any) => {
      message.error(err.message);
      setloading(false)
    })
  }
  const addThemes = () => {
    setloading(true)
    themesSVC.addThemes({
      name: editContent.name
    }).then((res: any) => {
      setloading(false)
      getThemes()
      message.success("Themes Added Successfully")
      setEditContent({ id: "", name: "" })
      handleCancel()

    }).catch((err: any) => {
      message.error(err.message);
      setloading(false)
    })

  }
  const editThemes = (id: string) => {
    themesSVC.editThemes(id).then((res: any) => {
      setEditContent({ id: res.data.id, name: res.data.name })

    }).catch((err: any) => {
      message.error(err.message);
    })
  }


  const showModal = () => {
    setIsModalOpen(true);
    setIsEditable(false);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setEditContent({ id: "", name: "" })
    setloading(false)
  };

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    setEditContent((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <div className="contentBox">
      <div className="info">
        <Text className="title24">Themes</Text>
        <Button onClick={showModal} className='button'>Add New Themes</Button>
      </div>
      <DataTableTheme deleteThemes={deleteThemes} editContent={editThemes} setshowModel={setIsModalOpen} setEditable={setIsEditable} isOpen={isModalOpen} contentData={contentData} />
      <Modal footer={false} title={isEditable === true ? 'Update  Themes' : 'Add Themes'} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>

        <Input name='name' onChange={handleInputChange} size={'large'} style={{ borderRadius: '10px' }} value={editContent.name} placeholder={"Write here..."} />

        <div style={{ display: 'flex', justifyContent: 'center', }}>
          <Button onClick={() => {
            if (editContent.name === "") {
              message.info("Please write a theme name")
            } else {
              if (isEditable === true) {
                updateThemes(editContent.id)
              } else {
                addThemes()
              }
            }
          }} style={{ marginTop: '10px', width: '200px', backgroundColor: 'green', color: 'white' }} loading={loading}>{isEditable === true ? 'Update Themes' : 'Add Themes'}</Button>
        </div>
      </Modal>
    </div >
  )
}

export default Themes