import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./menu.scss";
import { menu } from "../data/menu";
import Text from "antd/lib/typography/Text";
import { useB2BContext } from "../context/ContextB2B";

function Menu() {
  const { getUserByID } = useB2BContext();
  const location = useLocation();
  const userType: any = localStorage.getItem("user_type");
  const [collapsedItems, setCollapsedItems] = useState<any>({});
  const navigate = useNavigate();

  const toggleCollapse = (itemId: any) => {
    setCollapsedItems((prevCollapsedItems: any) => {
      const newCollapsedItems: any = {};

      Object.keys(prevCollapsedItems).forEach((key) => {
        newCollapsedItems[key] =
          key === itemId ? !prevCollapsedItems[key] : true;
      });

      newCollapsedItems[itemId] = !prevCollapsedItems[itemId];

      return newCollapsedItems;
    });
  };

  const resetCollapsedState = () => {
    const initialCollapsedState: any = {};
    let firstMenuExpanded = false;

    menu.forEach((item) => {
      if (item.userType === userType) {
        initialCollapsedState[item.id] = true; // Set to true if you want all collapsed initially

        if (!firstMenuExpanded) {
          initialCollapsedState[item.id] = false;
          firstMenuExpanded = true;
        }
      }
    });

    setCollapsedItems(initialCollapsedState);
  };
  useEffect(() => {
    resetCollapsedState(); // Reset collapsed state on component mount
  }, []);

  return (
    <div className="menu">
      {userType === "Super_admin" ? (
        <>
          <div className="item">
            <a href="/demo-requests">
              Demo Requests <span>1</span>
            </a>
          </div>
          <div className="item">
            <a href="/linkgenerate">Demo Link Generate</a>
          </div>
          <div className="menu_title">
            <p>Content Management</p>
          </div>
          <div className="item sub_dropown">
            <p>SOULSARA Times</p>
            <div className="menu_dropdown_link">
              <div className="menu_title border_none">
                <p>SOULSARA Times</p>
              </div>
              <ul>
                <li>
                  <a href="/thought">Thought for the week</a>
                </li>
                <li>
                  <a href="/featureditems">Featured items</a>
                </li>
                <li>
                  <a href="/latestvideos">Latest Videos</a>
                </li>
                <li>
                  <a href="/crisismanagementtools">Crisis Management Tools</a>
                </li>
                <li>
                  <a href="/womeninsports">Women In Sports</a>
                </li>
                <li>
                  <a href="/newsonwellness">News On Wellness</a>
                </li>
                <li>
                  <a href="/takeaway">Takeaway</a>
                </li>
                <li>
                  <a href="/times-featured-story">Featured Story</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="item">
            <a href="/forum">Community & Forum</a>
          </div>
          <div className="item">
            <a href="/blog">Blog Posts</a>
          </div>
          <div className="item">
            <a href="/webinar">Webinar</a>
          </div>
          <div className="item sub_dropown">
            <p>Event Videos</p>
            <div className="menu_dropdown_link">
              <div className="menu_title border_none">
                <p>Event Videos</p>
              </div>
              <ul>
                <li>
                  <a href="/soulsaraeventvideos">Full Videos</a>
                </li>
                <li>
                  <a href="/soulsarashortvideos">Shorts</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="item">
            <a href="/approve-products">Approved Products</a>
          </div>
          <div className="item">
            <a href="/register_"> NOGs</a>
          </div>
          <div className="menu_title">
            <p>Booking & CSV Download</p>
          </div>
          <div className="item">
            <a href="/helpline_history"> Wellness Counseling Booking</a>
          </div>
          <div className="item">
            <a href="/download-csv"> Export CSV</a>
          </div>
          <div className="menu_title">
            <p>Clients Management</p>
          </div>
          <div className="item">
            <a href="/clients"> Clients</a>
          </div>
          <div className="item sub_dropown">
            <p>Client Requests</p>
            <div className="menu_dropdown_link">
              <div className="menu_title border_none">
                <p>Client Requests</p>
              </div>
              <ul>
                <li>
                  <a href="/soulsaraeventvideos">
                    Membership Plan Change <span>2</span>
                  </a>
                </li>
                <li>
                  <a href="/soulsarashortvideos">
                    ADd-ons Change <span>2</span>
                  </a>
                </li>
              </ul>
              <div className="menu_title border_none">
                <p>On Demand Service Requests</p>
              </div>
              <ul>
                <li>
                  <a href="/soulsaraeventvideos">
                    Exclusive Online Events <span>4</span>
                  </a>
                </li>
                <li>
                  <a href="/soulsarashortvideos">
                    Offline Group Training Sessions
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="item">
            <a href="/oca">Knowledge Hub Users</a>
          </div>
          <div className="menu_title">
            <p>Profile & Dashboard</p>
          </div>
          <div className="item">
            <a href="/oca"> Roles & Permissions</a>
          </div>
          <div className="item">
            <a href="/oca"> User Wellness Dashboard</a>
          </div>
        </>
      ) : (
        <>
          <div className="item">
            {getUserByID?.is_payment ? (
              <a href="/overview">Overview</a>
            ) : (
              <p>Overview</p>
            )}
          </div>
          <div className="item">
            <a href="/subscription_agreement">Subscription Agreement</a>
          </div>
          <div className="item">
            {getUserByID?.is_payment ? (
              <a href="/manage_exployees">Manage Employees</a>
            ) : (
              <p>Manage Employees</p>
            )}
          </div>
          <div className="item">
            {getUserByID?.is_payment ? (
              <a href="/departments">Manage Department</a>
            ) : (
              <p>Manage Department</p>
            )}
          </div>
          <div className="item">
            {getUserByID?.is_payment ? (
              <a href="/insights">Insights</a>
            ) : (
              <p>Insights</p>
            )}
          </div>
          <div className="item">
            {getUserByID?.is_payment ? (
              <a href="/plan">Manage Plan</a>
            ) : (
              <p>Manage Plan</p>
            )}
          </div>
          <div className="item">
            {getUserByID?.is_payment ? (
              <a href="/hrprofile">Profile</a>
            ) : (
              <p>Profile</p>
            )}
          </div>

          <div className="item">
            <a
              href="https://www.soulsara.world/contact-us"
              target="_blank"
              rel="noreferrer"
            >
              Contact Us
            </a>
          </div>
        </>
      )}

      {/* {menu?.map((item) =>
        item?.userType === userType ? (
          <div
            className="item"
            style={{
              backgroundColor: location.pathname === item.url ? "#E8EBFF" : "",
            }}
            key={item.id}
            onClick={() => {
              if (item?.url) {
                navigate(`${item.url}`);
              } else {
                toggleCollapse(item.id);
              }
            }}
          >
            <div className="collapse_menu">
              <Text className="title"> {item.title}</Text>
            </div>
            {item?.listItem
              ? item?.listItem?.map((item: any) => (
                  <Link
                    className={`listItem ${
                      collapsedItems[item.id] ? "collapsed" : ""
                    }`}
                    key={item.id}
                    to={item.url}
                  >
                    <span className="listItemTitle">{item.title}</span>
                  </Link>
                ))
              : null}
          </div>
        ) : null
      )} */}
    </div>
  );
}

export default Menu;
