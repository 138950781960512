import React, { useEffect, useState } from "react";
import moment from "moment";
import { authSVC } from "../../services";
import { useB2BContext } from "../../context/ContextB2B";
import { Button, Form, Input, message, DatePicker } from "antd";
import authstyle from "../../styles/auth.module.scss";
import type { DatePickerProps } from "antd";

const { TextArea } = Input;

interface props {
  comapnyId: string;
}

function AgreementData({ comapnyId }: props) {
  const { setAgreementId, getUserByID } = useB2BContext();
  const [form1] = Form.useForm();
  const [selectedDate, setSelectedDate] = useState<any>();

  useEffect(() => {
    if (comapnyId) {
      authSVC
        .getAgreementsPostData(getUserByID.email)
        .then((res) => {
          form1.setFieldsValue({
            //agreementstartsate: res.data.data.s,
            companyname: res.data.data.clientCompanyName,
            location: res.data.data.location,
            country: res.data.data.country,
            companyaddress: res.data.data.companyAddress,
            companyowner: res.data.data.companyOwner,
            aboutthecompany: res.data.data.aboutTheCompanyName,
          });
        })
        .catch();
    }
  }, [getUserByID, form1]);

  const onFinish = (values: {
    companyname: string;
    location: string;
    country: string;
    companyaddress: string;
    companyowner: string;
    aboutthecompany: string;
  }) => {
    let User: any = {};
    User = {
      clientCompanyName: values.companyname,
      location: values.location,
      country: values.country,
      companyAddress: values.companyaddress,
      companyOwner: values.companyowner,
      aboutTheCompanyName: values.aboutthecompany,
    };
    authSVC
      .agreementsPost(User)
      .then((res: any) => {
        if (res.data.success === true) {
          setAgreementId(res.data.data._id);
          window.location.reload();
          // localStorage.setItem("agreementId", res.data.data._id);
          // setTimeout(() => {
          //   // router.push("/soulsara-agreement");
          //   router.push({
          //     pathname: "/soulsara-agreement",
          //     query: { param1: res.data.data._id },
          //   });
          // }, 500);
        }
      })
      .catch((error) => {
        //console.log(error.response.data.error);
        message.error(error.response.data.error);
      });
  };

  const onChange: DatePickerProps["onChange"] = (date: any, dateString) => {
    const dateStd: any = moment(dateString).format();
    setSelectedDate(dateStd);
    if (dateStd === "Invalid date") {
      setSelectedDate(null);
    }
  };

  return (
    <>
      <div className={authstyle.agreement_wrapper}>
        <h2>
          Please complete the company information before signing the agreement
        </h2>
        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          layout="vertical"
          autoComplete="off"
          form={form1}
        >
          <Form.Item
            name="companyname"
            rules={[
              {
                required: true,
                message: "Please Enter Your Company Name!",
              },
            ]}
            style={{ marginBottom: 15 }}
          >
            <Input placeholder="Company Name" />
          </Form.Item>

          <Form.Item
            name="location"
            rules={[
              {
                required: true,
                message: "Please Enter Company Location",
              },
            ]}
            style={{ marginBottom: 15 }}
          >
            <Input placeholder="Company Location" />
          </Form.Item>
          <Form.Item
            name="country"
            rules={[
              {
                required: true,
                message: "Please Enter Your Country",
              },
            ]}
            style={{ marginBottom: 15 }}
          >
            <Input placeholder="Your Country" />
          </Form.Item>
          <Form.Item
            name="companyaddress"
            rules={[
              {
                required: true,
                message: "Please Enter Your Company Address",
              },
            ]}
            style={{ marginBottom: 15 }}
          >
            <Input placeholder="Company Address" />
          </Form.Item>
          <Form.Item
            name="companyowner"
            rules={[
              {
                required: true,
                message: "Please Enter Your Company Owner",
              },
            ]}
            style={{ marginBottom: 15 }}
          >
            <Input placeholder="Company Owner" />
          </Form.Item>
          <Form.Item
            name="aboutthecompany"
            rules={[
              {
                required: true,
                message: "Please Enter Your About The Company",
              },
            ]}
            style={{ marginBottom: 15 }}
          >
            <TextArea
              placeholder="About The Company"
              autoSize={{ minRows: 2, maxRows: 6 }}
            />
          </Form.Item>

          <Form.Item className={authstyle.login_btn_set}>
            <Button className="btn-an4 btn-hover1" htmlType="submit">
              Continue
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
}

export default AgreementData;
