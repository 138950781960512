
import React from 'react'

function Forbidden() {
    return (
        <div
            className='comingsoon_wrapper'
        >
            <h1>404 Page Not Found</h1>
        </div>
    )
}

export default Forbidden