import React, { useEffect, useState } from "react";
import styles from "../../styles/dashboard.module.scss";
import { authSVC } from "../../services";
import moment from "moment";
import { useB2BContext } from "../../context/ContextB2B";
import BarChartOverview from "./BarChart";
import DepartmentByProgressBar from "./DepartmentByProgressBar";
import PieChartData from "./PieChart";
import OverallResult from "./OverallResult";
import { useNavigate, useLocation } from "react-router-dom";
import { Spin } from "antd";
import { Checkbox, Modal, message } from "antd";

const levelsData = [
  {
    level: 0,
    color: "#FFDAEE",
  },
  {
    level: 1,
    color: "#FCD6E9",
  },
  {
    level: 2,
    color: "#F9BCDC",
  },
  {
    level: 3,
    color: "#F69FCC",
  },
  {
    level: 4,
    color: "#F168B1",
  },
  {
    level: 5,
    color: "#F04BA1",
  },
  {
    level: 6,
    color: "#EC3192",
  },
  {
    level: 7,
    color: "#EB1481",
  },
  {
    level: 8,
    color: "#CE1273",
  },
];
function Overview() {
  const [overallLevel, setoverallLevel] = useState<any>(null);
  const { getUserByID, setUserByID } = useB2BContext();
  const [isLoading, setIsLoding] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const [isPaymentApproved, setIsPaymentApproved] = useState(false);

  const handlePaymentApproved = () => {
    setIsPaymentApproved(true);
  };

  const handleCancelPaymentApproved = () => {
    setIsPaymentApproved(false);

    // window.location.reload();
  };

  useEffect(() => {
    const month: any = moment().format("M");
    const year: any = moment().format("yyyy");
    // setIsLoding(true);
    if (getUserByID) {
      authSVC
        .getoverAllViewLevels(getUserByID?.company, month, year)
        .then((res) => {
          setoverallLevel(res?.data?.data[0]);
          console.log(res?.data?.data[0], "res?.data?.data[0]");
          setIsLoding(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [getUserByID]);

  useEffect(() => {
    if (
      getUserByID?.is_payment === true &&
      getUserByID?.first_welcome_message === false
    ) {
      setIsPaymentApproved(true);
      authSVC
        .setFirstWelcomeMessage({ user_id: getUserByID?._id })
        .then((res) => {
          console.log("is first true");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [getUserByID]);

  return (
    <div className={styles.wellness_report_main_wrapper}>
      {!isLoading ? (
        <>
          <p className={styles.info_text}>
            A lower index value indicates a less confused mental state.
          </p>
          <div className={styles.col_2_div}>
            {/* Left Container */}
            <div className={styles.data_container}>
              <OverallResult overallLevel={overallLevel} />
              <PieChartData levelsData={levelsData} />
            </div>
            {/* Right Container */}
            <div className={styles.data_container}>
              <BarChartOverview />
              <DepartmentByProgressBar />
            </div>
          </div>
        </>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "400px",
          }}
        >
          <div className="spinner"></div>
        </div>
      )}

      <Modal
        open={isPaymentApproved}
        onOk={handlePaymentApproved}
        onCancel={handleCancelPaymentApproved}
        footer={null}
        closable={true}
        maskClosable={false}
        width={"390px"}
        className="paymat_status_modal"
      >
        <div className="payment_status_wrapper">
          <img src="/img/Group19356.png" alt="" width="100%" height="240px" />
          <div className="thank_you_content">
            <h2>Welcome to SOULSARA, Your Wellness Community</h2>
            <p>
              Thank you for choosing us to support your team’s health,
              happiness, and productivity.
            </p>
            <a href="/departments" className="start_btn">
              start adding your team now! 🚀
            </a>
            <p className="help">
              Need help? We are here for you!{" "}
              <a
                href="https://www.soulsara.world/contact-us"
                target="_blank"
                rel="noreferrer"
              >
                contact us
              </a>
            </p>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Overview;
