import { AxiosPromise } from 'axios';

const API_PATH = process.env.REACT_APP_API_URL;

type API = {
  post: (url: string, payload: any) => AxiosPromise
  put: (url: string, payload: any) => AxiosPromise
  get: (url: string) => AxiosPromise
  delete: (id: string) => AxiosPromise
}

const articles_blog = (api: API) => {
  // themeposts post
  const addThemeposts = (payload: any) => api.post(API_PATH + '/blog/add/', payload)
  const getThemeposts = () => api.get(API_PATH + '/blog/getAll/')
  const deleteThemeposts = (id: string) => api.delete(API_PATH + `/blog/delete/${id}`)
  const editThemeposts = (id: string) => api.get(API_PATH + `/blog/getBlogById/${id}`)
  const updateThemeposts = (id: string, payload: any) => api.put(API_PATH + `/blog/update/${id}`, payload)
  const updateAuthorImage = (id: string, payload: any) => api.put(API_PATH + `/blog/updateAuthorImage/${id}`, payload)

  return {
    addThemeposts,
    getThemeposts,
    deleteThemeposts,
    editThemeposts,
    updateThemeposts,
    updateAuthorImage
  }
}

export default articles_blog
