import React, { useEffect, useState } from "react";
import "./latestvideos.scss";
import {
  Button,
  Typography,
  Modal,
  Input,
  message,
  Form,
  Select,
  DatePickerProps,
  DatePicker,
} from "antd";
import { categorySVC, soulsaraVideoContentsSVC } from "../../services";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import moment from "moment";
import dayjs from "dayjs";
import ImageUploader from "./ImageUploader";
import { Option } from "antd/es/mentions";
import DataTableLatestVideos from "../../components/dataTable/events/DataTableLatestVideo";
import useSWR from 'swr';
import axios from 'axios';
import * as Cookie from "universal-cookie";

const cookie = new Cookie.default();
const cachedToken = cookie.get("userAuthTokenAdmin");

const { Text } = Typography;

interface Contentdata {
  key?: string;
  _id: string;
  title: string;
  video: string;
  // category: {
  //   _id: string
  //   title?: string
  //   type?: string
  // }
  description?: string;
  status?: string;
  publishOn?: any;
  thumbnail?: string;
  meta_title?: string;
  meta_description?: string;
  meta_keywords?: any;
  videoType?: string;
}
const fetcher = (url: any) => axios.get(url, {
  headers: {
    'Authorization': `Bearer ${cachedToken}`,
    'Content-Type': 'application/json'
  }
}).then(res => res?.data);

function SoulsaraShortVideos() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [editContent, setEditContent] = useState<Contentdata>({
    _id: "",
    title: "",
    video: "",
    videoType: "",
    description: "",
    meta_keywords: null,
    status: "",
    publishOn: "",
    thumbnail: "",
    meta_title: "",
    meta_description: "",
  });
  // const [contentData, setContentData] = useState<Contentdata[]>([]);
  // const [categories, setCategoriesData] = useState<any[]>([]);
  const [loading, setloading] = useState<boolean>(false);
  const [uploadedImage, setUploadedImage] = useState<any>(null);
  const [btnDisable, setBtnDisable] = useState(false);

  const API_URL = process.env.REACT_APP_API_URL;

  const { data: contentData, mutate: getOtherContent } = useSWR(API_URL + '/ss_video/videoType/events_shorts', fetcher, {
    revalidateOnFocus: true,
    revalidateOnReconnect: true
  });

  // const getCategory = async () => {
  //   categorySVC
  //     .getCategories()
  //     .then((res: any) => {
  //       setCategoriesData(res.data.data);
  //     })
  //     .catch((err: any) => {
  //       message.error(err.message);
  //     });
  // };
  // const getOtherContent = async () => {
  //   soulsaraVideoContentsSVC
  //     .getSoulsaraShortVideos()
  //     .then((res: any) => {
  //       setContentData(res.data);
  //     })
  //     .catch((err: any) => {
  //       message.error(err.message);
  //     });
  // };

  // useEffect(() => {
  // getCategory();
  //   getOtherContent();
  // }, []);

  const deleteOtherContent = (id: string) => {
    soulsaraVideoContentsSVC
      .deleteLatestVideos(id)
      .then((res: any) => {
        getOtherContent();
        message.success("Deleted Successfully");
      })
      .catch((err: any) => {
        message.error(err.message);
      });
  };

  const updateOtherContent = (id: string) => {
    setloading(true);
    if (editContent.thumbnail !== "") {
      const formData: any = new FormData();
      formData.append("image", editContent.thumbnail);
      formData.append("videoType", "events_shorts");

      soulsaraVideoContentsSVC
        .updateLatestVideosThu(id, formData)
        .then((res: any) => {
          setloading(false);
          getOtherContent();
          message.success("Updated Successfully");
          setIsModalOpen(false);
        })
        .catch((err: any) => {
          message.error(err.message);
          setloading(false);
        });
    } else {
      const formData: any = new FormData();
      formData.append("video", editContent.video);
      // formData.append('category', editContent.category._id)
      formData.append("videoType", "events_shorts");
      editContent.description &&
        formData.append("description", editContent.description);
      editContent.meta_title &&
        formData.append("meta_title", editContent.meta_title);
      editContent.meta_description &&
        formData.append("meta_description", editContent.meta_description);
      editContent.meta_keywords &&
        formData.append("meta_keywords", editContent.meta_keywords);
      editContent.status && formData.append("status", editContent.status);
      // editContent.thumbnail && formData.append('image', editContent.thumbnail)
      editContent.publishOn &&
        formData.append("publishOn", editContent.publishOn);

      soulsaraVideoContentsSVC
        .updateLatestVideos(id, formData)
        .then((res: any) => {
          setloading(false);
          getOtherContent();
          message.success("Updated Successfully");
          setIsModalOpen(false);
        })
        .catch((err: any) => {
          message.error(err.message);
          setloading(false);
        });
    }
  };
  const updateVideoStatusTopublished = (id: string) => {
    setloading(true);
    const formData: any = new FormData();
    formData.append("status", "published");

    soulsaraVideoContentsSVC
      .updateLatestVideos(id, formData)
      .then((res: any) => {
        setloading(false);
        getOtherContent();
        message.success("Updated Successfully");
      })
      .catch((err: any) => {
        message.error(err.message);
        setloading(false);
      });
  };

  const updateVideoStatusToarchived = (id: string) => {
    setloading(true);
    const formData: any = new FormData();
    formData.append("status", "archived");

    soulsaraVideoContentsSVC
      .updateLatestVideos(id, formData)
      .then((res: any) => {
        setloading(false);
        getOtherContent();
        message.success("Updated Successfully");
      })
      .catch((err: any) => {
        message.error(err.message);
        setloading(false);
      });
  };
  const addOtherContent = () => {
    setloading(true);
    const formData: any = new FormData();
    formData.append("title", editContent.title);
    formData.append("video", editContent.video);
    // formData.append('category', editContent.category._id)
    formData.append("videoType", "events_shorts");
    editContent.description &&
      formData.append("description", editContent.description);
    editContent.meta_title &&
      formData.append("meta_title", editContent.meta_title);
    editContent.meta_description &&
      formData.append("meta_description", editContent.meta_description);
    editContent.meta_keywords &&
      formData.append("meta_keywords", editContent.meta_keywords);
    editContent.publishOn &&
      formData.append("publishOn", editContent.publishOn);
    soulsaraVideoContentsSVC
      .addLatestVideos(formData)
      .then((res: any) => {
        setloading(false);
        getOtherContent();
        message.success("Added Successfully");
        setEditContent({
          _id: "",
          title: "",
          video: "",
          description: "",
          meta_keywords: null,
          status: "",
          publishOn: "",
          thumbnail: "",
          meta_title: "",
          meta_description: "",
        });
        setIsModalOpen(false);
      })
      .catch((err: any) => {
        message.error(err.message);
        setloading(false);
      });
  };
  const editOtherContent = (id: string) => {
    soulsaraVideoContentsSVC
      .editLatestVideos(id)
      .then((res: any) => {
        setEditContent({
          _id: res.data.data._id,
          title: res.data.data.title,
          description: res.data.data.description,
          video: res.data.data.video,
          status: res.data.data.status,
          publishOn: res.data.data.publishOn,
          meta_keywords:
            res.data.data.meta_keywords !== null
              ? res.data.data.meta_keywords?.split(",")
              : null,
          meta_title: res.data.data.meta_title,
          meta_description: res.data.data.meta_description,
          thumbnail: res.data.data.thumbnail,
        });
      })
      .catch((err: any) => {
        message.error(err.message);
      });
  };

  const handleFileUpload = async (event: any) => {
    event.preventDefault();
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setUploadedImage(reader.result);
    };
    setEditContent((prevData) => ({
      ...prevData,
      thumbnail: file,
    }));

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleVideoFileUpload = (event: any) => {
    event.preventDefault();
    const file = event.target.files[0];
    setBtnDisable(false);
    setEditContent((prevData: any) => ({
      ...prevData,
      video: file,
    }));
  };
  const showModal = () => {
    setIsModalOpen(true);
    setIsEditable(false);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setEditContent({
      _id: "",
      title: "",
      video: "",
      description: "",
      meta_keywords: null,
      status: "",
      publishOn: "",
      thumbnail: "",
      meta_title: "",
      meta_description: "",
    });
    setloading(false);
  };

  const handleInputChange = (event: any) => {
    setBtnDisable(false);
    const { name, value } = event.target ?? "";
    setEditContent((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSelectedItem = (value: any) => {
    setEditContent((prevData: any) => ({
      ...prevData,
      category: {
        _id: value,
        title: "",
        type: "",
      },
    }));
  };

  const handleDescription = (value: any) => {
    setBtnDisable(false);
    setEditContent((prevData: any) => ({
      ...prevData,
      description: value,
    }));
  };

  const handleMetaKeywords = (value: any) => {
    setBtnDisable(false);
    setEditContent((prevData: any) => ({
      ...prevData,
      meta_keywords: value,
    }));
  };

  const handleStatusChange = (value: any) => {
    setBtnDisable(false);
    setEditContent((prevData: any) => ({
      ...prevData,
      status: value,
    }));
  };

  const onChange: DatePickerProps["onChange"] = (date, dateString) => {
    setEditContent((prevData: any) => ({
      ...prevData,
      publishOn: date,
    }));
  };
  return (
    <div className="contentBox">
      <div className="info">
        <Text className="title24">SoulSara Short Videos</Text>
        <Button onClick={showModal} className="button">
          Add Short Videos
        </Button>
      </div>
      <DataTableLatestVideos
        updateVideoStatusTopublished={updateVideoStatusTopublished}
        updateVideoStatusToarchived={updateVideoStatusToarchived}
        deleteOtherVideoContent={deleteOtherContent}
        editContent={editOtherContent}
        setshowModel={setIsModalOpen}
        setEditable={setIsEditable}
        isOpen={isModalOpen}
        contentData={contentData}
      />
      <Modal
        footer={false}
        title={
          isEditable === true
            ? "Update  SoulSara Short Videos"
            : "Add SoulSara Short Videos"
        }
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        {/* {
            editContent.category.title === "" ? (<Select
              showSearch
              style={{ width: '100%', borderRadius: '24px' }}
              placeholder="Select a Video Title"
              optionFilterProp="children"
              onChange={handleSelectedItem}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }

              options={categories.map((option) => ({
                value: option._id,
                label: option.title,
              }))}
            />) : (
              <>
                <Select
                  value={editContent.category.title}
                  showSearch
                  style={{ width: '100%', borderRadius: '24px' }}
                  placeholder="Select a Video Title"
                  optionFilterProp="children"
                  onChange={handleSelectedItem}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }

                  options={categories.map((option) => ({
                    value: option._id,
                    label: option.title,
                  }))}
                />
              </>
            )
          } */}

        <br />
        <br />
        <Input
          type="text"
          name="title"
          value={editContent.title}
          onChange={handleInputChange}
          placeholder="Enter Title"
        />
        <br />
        <br />
        <ReactQuill
          value={editContent.description}
          onChange={handleDescription}
          modules={{
            toolbar: [
              ["bold", "italic", "underline", "strike", "blockquote"],
              [{ list: "ordered" }, { list: "bullet" }],
              ["link"],
              ["clean"],
            ],
          }}
        />

        {/* <br />
        <br />
        <DatePicker
          value={editContent.publishOn ? dayjs(editContent.publishOn) : null}
          disabledDate={(current) => {
            return current && current < moment().endOf("day");
          }}
          style={{ width: "100%" }}
          onChange={onChange}
        />

        <br />
        <br /> */}
        {editContent.status === "" ? (
          <></>
        ) : (
          <>
            <br />

            <label htmlFor="">
              <b>Video Status</b>
            </label>
            <Select
              value={editContent.status}
              allowClear
              style={{ width: "100%" }}
              placeholder="Please Change Status"
              onChange={handleStatusChange}
              options={[
                {
                  value: "published",
                  label: "Published",
                },
                {
                  value: "archived",
                  label: "Archived",
                },
              ]}
            />
          </>
        )}

        <br />
        <br />
        <label htmlFor="">
          <b>Meta Keyword</b>
        </label>
        <br />

        {
          <Select
            value={editContent.meta_keywords ?? []}
            mode={"tags"}
            allowClear
            style={{ width: "100%" }}
            placeholder="Please SELECT/ADD a meta_keyword"
            onChange={handleMetaKeywords}
          >
            {editContent.meta_keywords?.map((items: any) => (
              <Option value={items}>{items}</Option>
            ))}
          </Select>
        }
        <br />
        <br />
        <label htmlFor="">
          <b>Meta Title</b>
        </label>
        <br />
        <Input
          showCount
          maxLength={60}
          className="input"
          name="meta_title"
          onChange={handleInputChange}
          size={"large"}
          style={{ borderRadius: "10px" }}
          value={editContent.meta_title}
          placeholder={"Enter Meta Title"}
        />

        <br />
        <br />
        <label htmlFor="">
          <b>Meta Description</b>
        </label>
        <br />
        <Input.TextArea
          showCount
          maxLength={160}
          className="input"
          name="meta_description"
          onChange={handleInputChange}
          size={"large"}
          style={{ borderRadius: "10px" }}
          value={editContent.meta_description}
          placeholder={"Enter Meta Description"}
        />

        <br />
        <br />

        {/* <label htmlFor="" style={{ color: 'red' }}>Note:Maximum file size 500MB can upload</label> */}
        <div>
          <input
            type="file"
            accept="video/*"
            onChange={handleVideoFileUpload}
          />
        </div>

        {editContent.thumbnail !== "" ? (
          <>
            <br />
            <br />
            <ImageUploader
              image={
                editContent?.thumbnail ? editContent?.thumbnail : "noavatar.png"
              }
              uploadedImage={uploadedImage}
              handleFileUpload={handleFileUpload}
            />
          </>
        ) : null}

        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            onClick={() => {
              if (editContent.title === "") {
                setBtnDisable(true);
                message.info("Please Enter title");
              } else if (editContent.description === "") {
                setBtnDisable(true);
                message.info("Please Enter description type");
              } else {
                if (isEditable === true) {
                  setBtnDisable(true);
                  updateOtherContent(editContent._id ?? "");
                } else {
                  if (editContent.video === "") {
                    setBtnDisable(true);
                    message.info("Please upload a video");
                  } else {
                    addOtherContent();
                  }
                }
              }
            }}
            style={{
              marginTop: "10px",
            }}
            className="button"
            loading={loading}
            disabled={btnDisable ? true : false}
          >
            {isEditable === true ? "Update " : "Add"}
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default SoulsaraShortVideos;
