import React, { useEffect, useState } from "react";
// import { Button, Typography, Input, message, ColorPicker, Form } from "antd";
import { authSVC } from "../../services";
import { useB2BContext } from "../../context/ContextB2B";
import overview from "../../data/state.json";

import {
  Button,
  Typography,
  Modal,
  Input,
  message,
  Select,
  Form,
  Image,
} from "antd";
import { Option } from "antd/es/mentions";
import { useNavigate, useLocation } from "react-router-dom";

function AddressPopup({ onFinishAddress }: any) {
  const { getUserByID } = useB2BContext();
  const [form] = Form.useForm();

  //   const onFinishAddress = () => {
  //     // authSVC
  //     //   .editDepartment({
  //     //     companyName: getUserByID?.company,
  //     //     department_id: editDepartment._id,
  //     //     department_name: departmentName,
  //     //     department_color: colorValue,
  //     //   })
  //     //   .then((res: any) => {
  //     //     message.success("update Successfully");
  //     //     form.resetFields();
  //     //     setColorValue("");
  //     //     getDepartments();
  //     //     navigate("/departments");
  //     //   })
  //     //   .catch((err: any) => {
  //     //     message.error(err.message);
  //     //   });
  //   };

  useEffect(() => {
    if (getUserByID) {
      form.setFieldsValue({
        //agreementstartsate: res.data.data.s,

        address: getUserByID?.company_id?.location?.address,
        country: getUserByID?.company_id?.location?.country,
        state: getUserByID?.company_id?.location?.state,
        city: getUserByID?.company_id?.location?.city,
        pin_code: getUserByID?.company_id?.location?.zipcode,
        state_code: getUserByID?.company_id?.location?.state_code,
        company_pancard: getUserByID?.company_id?.location?.company_pancard,
        gstin_number: getUserByID?.company_id?.location?.gstin_number,
      });
    }
  }, [getUserByID, form]);

  return (
    <div className="add_form_wrapper address_form">
      <h3>Your Office address</h3>
      <Form
        name="normal_login"
        initialValues={{ remember: true }}
        onFinish={onFinishAddress}
        form={form}
      >
        <Form.Item
          label="Address"
          name="address"
          rules={[
            {
              required: true,
              message: "Please enter your office address",
            },
          ]}
          style={{ marginBottom: "16px" }}
        >
          <Input
            name="address"
            className="input"
            type="text"
            placeholder="Please enter your office address"
          />
        </Form.Item>
        <Form.Item
          label="City"
          name="city"
          rules={[
            {
              required: true,
              message: "Please enter where your office is located",
            },
          ]}
          style={{ marginBottom: "16px" }}
        >
          <Input
            name="city"
            className="input"
            type="text"
            placeholder="Please enter where your office is located"
          />
        </Form.Item>
        <Form.Item
          label="Country"
          name="country"
          rules={[
            {
              required: true,
              message: "Please enter your office country",
            },
          ]}
          style={{ marginBottom: "16px" }}
        >
          <Input
            name="country"
            className="input"
            type="text"
            placeholder="Please enter your office country"
          />
        </Form.Item>
        <Form.Item
          label="State"
          name="state"
          rules={[
            {
              required: true,
              message: "Please enter your office State",
            },
          ]}
          style={{ marginBottom: "16px" }}
        >
          <Select
            style={{ width: "100%", borderRadius: "8px" }}
            placeholder="Please Select The Department"
            optionFilterProp="children"
            // onChange={handleSelectedDepratment}
          >
            {overview?.map((item: any, index: any) => {
              return (
                <Option value={item.state} key={index}>
                  {item.state}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          label="State code"
          name="state_code"
          rules={[
            {
              required: true,
              message: "Please enter your office State Code",
            },
          ]}
          style={{ marginBottom: "16px" }}
        >
          <Input
            name="state_code"
            className="input"
            type="text"
            placeholder="Please enter your office State Code"
          />
        </Form.Item>
        <Form.Item
          label="Pin Code"
          name="pin_code"
          rules={[
            {
              required: true,
              message: "Please enter your office Pin Code",
            },
          ]}
          style={{ marginBottom: "16px" }}
        >
          <Input
            name="pin_code"
            className="input"
            type="text"
            placeholder="Please enter your office Pin Code"
          />
        </Form.Item>
        <Form.Item
          label="Pan Card"
          name="company_pancard"
          rules={[
            {
              required: true,
              message: "Please enter your office Pan Number",
            },
          ]}
          style={{ marginBottom: "16px" }}
        >
          <Input
            name="company_pancard"
            className="input"
            type="text"
            placeholder="Please enter your office Pan Number"
          />
        </Form.Item>
        <Form.Item
          label="GSTIN"
          name="gstin_number"
          rules={[
            {
              required: true,
              message: "Please enter your office GST number",
            },
          ]}
          style={{ marginBottom: "16px" }}
        >
          <Input
            name="gstin_number"
            className="input"
            type="text"
            placeholder="Please enter your office GST number"
          />
        </Form.Item>
        <Button
          className="checkout_btn"
          htmlType="submit"
          style={{ marginTop: "24px" }}
        >
          Continue Checkout
        </Button>
      </Form>
    </div>
  );
}

export default AddressPopup;
