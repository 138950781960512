import React, { useState } from "react";
import "./datatable.scss";
import { Space, Table, Input, Button, Popconfirm, Popover } from "antd";
import type { ColumnsType } from "antd/es/table";

interface Contentdata {
  key?: string;
  id: number;
  content: string;
}
interface DataTableProps {
  setshowModel: (value: boolean) => void;
  setEditable: (value: boolean) => void;
  deleteThought: (value: any) => void;
  editContent: (value: any) => void;
  isOpen: boolean;
  contentData?: Contentdata[];
  showModal: any;
}
function DataTable({
  setshowModel,
  setEditable,
  isOpen,
  contentData,
  deleteThought,
  editContent,
  showModal,
}: any) {
  const [searchText, setSearchText] = useState("");
  const [pagination, setPagination] = useState<any>({ page: 1, pageSize: 5 });

  const columns: ColumnsType<Contentdata> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      filteredValue: [searchText],
      defaultSortOrder: "descend",
      sorter: (a, b) => a.id - b.id,
      onFilter: (value, record) => {
        return (
          String(record.content)
            .toLowerCase()
            .includes(value.toLocaleString()) ||
          String(record.id).toLowerCase().includes(value.toLocaleString())
        );
      },
    },
    {
      title: "Content",
      dataIndex: "content",
      key: "content",
      render: (text) => (text.length > 15 ? `${text}` : text),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={() => {
              setshowModel(!isOpen);
              setEditable(true);
              editContent(record.id);
            }}
            className="btn_edit_table"
          >
            Edit
          </Button>
          <Popover
            content={
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "10px",
                }}
              >
                <Button
                  style={{
                    backgroundColor: "green",
                    width: "100%",
                    color: "#fff",
                  }}
                  onDoubleClick={() => deleteThought(record.id)}
                >
                  Yes
                </Button>
              </div>
            }
            title="Please Double click to delete."
          >
            <Button className="btn_edit_table">Delete</Button>
          </Popover>
        </Space>
      ),
    },
  ];

  return (
    <div className="dataTable">
      <div className="filterMenu">
        <Button onClick={showModal} className="add_button">
          Add New Thought
        </Button>
        <Input.Search
          onSearch={(value) => {
            setSearchText(value);
          }}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
          className="searchField"
          placeholder="Search here..."
        />
      </div>
      <Table
        className="dataGrid"
        columns={columns}
        dataSource={contentData}
        pagination={{
          pageSize: pagination?.pageSize,
          onChange(page, pageSize) {
            setPagination(pageSize);
          },
          showSizeChanger: true,
        }}
      />
    </div>
  );
}

export default DataTable;
