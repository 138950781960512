import React, { useEffect, useState } from "react";
import { Button, Typography, message, Image } from "antd";
import { authSVC, categorySVC } from "../../services";
import { useB2BContext } from "../../context/ContextB2B";
import DataTable from "../../components/dataTable/b2b/DataTable";
import { Spin } from "antd";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import useSWR from "swr";
import * as Cookie from "universal-cookie";
const cookie = new Cookie.default();
const cachedToken = cookie.get("userAuthTokenAdmin");

const { Text } = Typography;

interface Contentdata {
  _id: string;
  full_name: string;
  email: string;
  user_type: string;
  company: string;
  department: string;
}

const fetcherOne = (url: any) =>
  axios
    .get(url, {
      headers: {
        Authorization: `Bearer ${cachedToken}`,
        "Content-Type": "application/json",
      },
    })
    .then((res) => res?.data?.data);

function Employees() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const { getUserByID } = useB2BContext();
  const [editContent, setEditContent] = useState<Contentdata>({
    _id: "",
    full_name: "",
    email: "",
    user_type: "",
    company: "",
    department: "",
  });
  const [contentData, setContentData] = useState<any[]>([]);
  // const [loading, setloading] = useState<boolean>(false);
  const [disableAddBtn, setDisableAddBtn] = useState(false);
  const [disableAddEmp, setDisableAddEmp] = useState(false);
  const [userPackage, setUserPackage] = useState("");
  const [isLoding, setIsLoding] = useState(false);
  const [isDownloadLoding, setIsDownloadLoding] = useState(false);
  // const [allDepartments, setAllDepartments] = useState<any>();
  const [csvFile, setCSVFile] = useState<any>(null);
  const navigate = useNavigate();

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      setCSVFile(file);
    }
  };

  const UploadCSVFile = async () => {
    const formData: any = new FormData();
    formData.append("adminEmail", getUserByID?.email);
    formData.append("csvFile", csvFile);
    console.log(formData, "fff");
    setIsLoding(true);
    await authSVC
      .UploadCompanyCSV(formData)
      .then((res: any) => {
        getEmployees();
        setIsLoding(false);
        setCSVFile(null);
      })
      .catch((err: any) => {
        message.error(err.message);
      });
  };

  const getEmployees = async () => {
    if (getUserByID?.email) {
      await authSVC
        .getCompanyuser({ email: getUserByID?.email })
        .then((res: any) => {
          setContentData(res.data?.data?.data);
        })
        .catch((err: any) => {
          message.error(err.message);
        });
    }
  };

  useEffect(() => {
    getEmployees();
  }, [getUserByID]);

  const API_PATH = process.env.REACT_APP_API_URL;

  const { data: allDepartments } = useSWR(
    getUserByID?.email &&
      API_PATH + `/admin/${getUserByID?.company}/departments`,
    fetcherOne,
    {
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
    }
  );

  useEffect(() => {
    setUserPackage(getUserByID?.package_name);
  }, [getUserByID]);

  const deleteUser = (email: string) => {
    console.log(email);
    authSVC
      .DeleteUser(email)
      .then((response) => {
        message.success("Delete successfully");
        getEmployees();
      })
      .catch((error) => {
        console.error("Error deleting resource:", error);
      });
  };

  const editCategory = (id: string) => {
    const findByid = contentData.find((item: any) =>
      item._id === id ? item : null
    );

    setEditContent({
      _id: findByid?._id,
      full_name: findByid?.full_name ?? findByid?.first_name,
      email: findByid?.email,
      user_type: findByid?.user_type,
      company: findByid?.company,
      department: findByid?.department,
    });
  };

  const filteredContents = contentData?.filter((item: any) => {
    return item?.email === getUserByID?.email ? null : item;
  });

  useEffect(() => {
    if (filteredContents?.length === 10 && userPackage === "Gold") {
      setDisableAddBtn(true);
    } else if (filteredContents?.length === 100 && userPackage === "Diamond") {
      setDisableAddBtn(true);
    } else if (filteredContents?.length >= 100 && userPackage === "Platinum") {
      setDisableAddBtn(true);
    } else {
      setDisableAddBtn(false);
    }
  }, [filteredContents, userPackage]);

  useEffect(() => {
    if (allDepartments?.message?.length === 0) {
      setDisableAddEmp(true);
    } else {
      setDisableAddEmp(false);
    }
  }, [allDepartments]);

  const downloadFile = () => {
    const headers = ["Name", "Age", "Address"];
    const rows =
      contentData &&
      contentData?.map((row: any) => [
        row.full_name,
        row.company,
        row.department,
        row.email,
      ]);

    // Combine headers and rows into a single array
    const csvContent = [headers, ...rows].map((e) => e.join(",")).join("\n");

    // Create a blob from the CSV content
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    // Create a link element
    const link = document.createElement("a");
    if (link.download !== undefined) {
      // Set the URL using createObjectURL
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "Employee_Data.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <div className="contentBox">
      <div className="info">
        {getUserByID ? (
          <div className="employ_btn_set">
            <Button
              onClick={() => navigate("/addemployees")}
              className="cn_btn"
              style={{ width: "max-content" }}
              disabled={
                getUserByID?.is_payment === false
                  ? true
                  : disableAddBtn
                  ? true
                  : false
              }
            >
              Add New Employee
            </Button>
            {/* <div className="csv_upload">
              {getUserByID?.is_payment === false ? null : (
                <div className="upload_btn">
                  <div className="choosefile">
                    <input
                      className="file"
                      title="Choose CSV File"
                      type="file"
                      accept=".csv"
                      onChange={handleFileChange}
                    />
                    {csvFile ? (
                      csvFile?.name
                    ) : (
                      <>
                        <Image
                          src="/img/new/csv_icon.svg"
                          alt="import"
                          preview={false}
                          height={22}
                        />
                        Choose File
                      </>
                    )}
                  </div>
                </div>
              )}

              <Button
                disabled={csvFile ? false : true}
                className="cn_btn"
                style={{ width: "max-content" }}
                onClick={UploadCSVFile}
              >
                {isLoding ? <Spin size="small" /> : "Import CSV"}
              </Button>
              <Button
                disabled={
                  getUserByID?.is_payment === false ? true : isDownloadLoding
                }
                className="cn_btn"
                style={{ width: "max-content" }}
                onClick={downloadFile}
              >
                Download CSV Format
              </Button>
            </div> */}
          </div>
        ) : (
          <></>
        )}
      </div>
      <DataTable
        email={getUserByID?.email}
        deleteuser={deleteUser}
        editContent={editCategory}
        setshowModel={setIsModalOpen}
        setEditable={setIsEditable}
        isOpen={isModalOpen}
        contentData={filteredContents}
      />
    </div>
  );
}

export default Employees;
