import React, { useEffect, useState } from "react";
import { authSVC } from "../../services";
import { useB2BContext } from "../../context/ContextB2B";
import { calculatePrices } from "../../utils/calculatePrices";
import CheckoutButton from "./CheckoutButton";
import AddressPopup from "./Address";

// interface props {
//   paymentOption: string;
// }
function PaymentHDFC() {
  const { getUserByID, setPriceId } = useB2BContext();
  const [paymentIsOK, setPaymentIsOK] = useState(false);
  const [paymentId, setPaymentId] = useState(false);
  const [amount, setAmount] = useState<any>("");
  const [taxAmount, setTaxAmount] = useState<any>("");
  const [amountWithOutTax, setAmountWithOutTax] = useState<any>("");
  const [taxRate, setTaxRate] = useState(18); // Default tax rate (10%)
  const [total, setTotal] = useState(0);
  const [showAddress, setShowAddress] = useState(true);
  const [companyState, setCompanyState] = useState("");
  const [paymentBreakDown, setPaymentBreakDown] = useState({
    igstTax: 0,
    cgst: 0,
    sgst: 0,
    subTotalAmount: 0,
    amountQuote: 0,
  });
  /*
  useEffect(() => {
    setAmount(getUserByID?.company_id.package[0]?.price);
    const reduction = (amount * 18) / 100; // Calculate 18% of the amount
    const finalAmount = amount - reduction; // Subtract reduction from the original amount
    setAmountWithOutTax(finalAmount.toFixed(2));
    setTaxAmount(amount - amountWithOutTax);
  }, [getUserByID, amount, taxRate, amountWithOutTax]);
*/
  useEffect(() => {
    let amountBreakDown = {
      igstTax: 0,
      cgst: 0,
      sgst: 0,
      subTotalAmount: 0,
      amountQuote: 0,
    };
    const amountQuote = getUserByID?.company_id.package[0]?.price;
    amountBreakDown.igstTax = amountQuote * 0.18;
    amountBreakDown.cgst = amountQuote * 0.09;
    amountBreakDown.sgst = amountQuote * 0.09;
    amountBreakDown.subTotalAmount = amountQuote + amountBreakDown.igstTax;
    amountBreakDown.amountQuote = amountQuote;
    setPaymentBreakDown(amountBreakDown);
  });

  const HDFCPaymentInitiate = () => {
    if (getUserByID) {
      authSVC
        .initateHRMSPayment({ company_id: getUserByID.company_id._id })
        .then((res) => {
          localStorage.setItem("ss_order_id", res.data.order_id);
          window.location.href = res.data.payment_links.web;
        })
        .catch();
    }
  };
  const onFinishAddress = (values: any) => {
    console.log(values);
    setCompanyState(values.state);
    let addressData: any = {};
    addressData = {
      company_id: getUserByID?.company_id?._id,
      address: values.address,
      country: values.country,
      state: values.state,
      city: values.city,
      zipcode: values.pin_code,
      state_code: values.state_code,
      company_pancard: values.company_pancard,
      gstin_number: values.gstin_number,
    };

    console.log(addressData, "addressData");
    authSVC
      .CompanyAddress(addressData)
      .then((res: any) => {
        setShowAddress(false);
      })
      .catch((err: any) => {
        //message.error(err.message);
      });
  };

  return (
    <>
      <div className="chekout_details">
        <h3>Subscription Checkout</h3>
        <div className="checkout_indicator">
          <ul>
            <li className={showAddress ? "active" : ""}></li>
            <li className={showAddress ? "" : "active"}></li>
          </ul>
        </div>
        {showAddress ? (
          <div>
            <AddressPopup onFinishAddress={onFinishAddress} />
          </div>
        ) : (
          <>
            <div className="plan_name">
              <h2>
                {getUserByID?.company_id?.package[0]?.package_id?.package}{" "}
                Membership
              </h2>
              <div className="duration"></div>
            </div>

            <div className="price_box">
              <ul>
                <li>
                  <div>
                    <p>
                      {getUserByID?.company_id?.package[0]?.package_id?.package}{" "}
                      Membership charges
                    </p>
                  </div>
                  <div>
                    <p>₹{paymentBreakDown.amountQuote.toFixed(2)}</p>
                  </div>
                </li>
                <li className="tax_details">
                  <div className="line">
                    <div>
                      {companyState.toLowerCase() === "haryana" ? (
                        <p>Tax</p>
                      ) : (
                        <p>Tax / (IGST 18%)</p>
                      )}
                    </div>
                    <div>
                      <p>₹{paymentBreakDown.igstTax.toFixed(2)}</p>
                    </div>
                  </div>

                  <div className="line tax" style={{ marginTop: "8px" }}>
                    <div className="gray">
                      <p>CGST (9%)</p>
                    </div>
                    <div>
                      {companyState.toLowerCase() === "haryana" ? (
                        <p>₹{paymentBreakDown.cgst.toFixed(2)}</p>
                      ) : (
                        <p>₹0.00</p>
                      )}
                    </div>
                  </div>
                  <div className="line tax">
                    <div className="gray">
                      <p>SGST/UTGST (9%)</p>
                    </div>
                    <div>
                      {companyState.toLowerCase() === "haryana" ? (
                        <p>₹{paymentBreakDown.sgst.toFixed(2)}</p>
                      ) : (
                        <p>₹0.00</p>
                      )}
                    </div>
                  </div>
                </li>
                <li className="border">
                  <div>
                    <p>Subtotal</p>
                  </div>
                  <div>
                    <p>₹{paymentBreakDown.subTotalAmount.toFixed(2)}</p>
                  </div>
                </li>
              </ul>
            </div>
            {paymentIsOK ? (
              // Stripe Payment
              <CheckoutButton priceId={paymentId} />
            ) : (
              <button
                className="checkout_btn"
                onClick={HDFCPaymentInitiate}
                disabled={
                  getUserByID?.company_id.package.length === 0 ? true : false
                }
              >
                Continue Checkout
              </button>
            )}
          </>
        )}
      </div>
      <p className="need_help">
        Need help?{" "}
        <a
          href="https://www.soulsara.world/contact-us"
          rel="noreferrer"
          target="_blank"
        >
          Contact Us
        </a>{" "}
      </p>
    </>
  );
}

export default PaymentHDFC;
