import { message } from 'antd'
import * as Cookie from 'universal-cookie'

const cookie = new Cookie.default()

const login = (authToken: string, refreshToken?: string) => {
  if (authToken) {
    cookie.set('userAuthTokenAdmin', authToken, { path: '/', maxAge: 3600, sameSite: 'none', secure: true })
    // cookie.set('userRefreshToken', refreshToken, { path: '/', maxAge: 3600, sameSite: 'none', secure: true })
  }
  // return jwtDecode(token)
}

const setUserToken = (token: string) => {
  if (token) {
    cookie.set('userAuthTokenAdmin', token, { path: '/' })
  }
}

const getUserToken = () => {
  const cachedToken = cookie.get('userAuthTokenAdmin')
  return cachedToken
}

const setRefreshToken = (token: string) => {
  if (token) {
    cookie.set('userRefreshToken', token, { path: '/' })
  }
}

const isLogedin = () => {
  const cachedToken = cookie.get('userAuthTokenAdmin')

  if (cachedToken === null || cachedToken === undefined) {
    return false
  }

  return true
}
const getRefreshToken = () => {
  const cachedToken = cookie.get('userAuthTokenAdmin')

  return cachedToken
}
const logOut = () => {
  cookie.remove('userAuthTokenAdmin', { path: '/' })
  localStorage.clear()
//   cookie.remove('userRefreshToken', { path: '/' })
}

export default { setUserToken, logOut, login, setRefreshToken, getUserToken, getRefreshToken, isLogedin }
