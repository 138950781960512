import React, { useEffect } from "react";
import * as echarts from "echarts";

const PieChartHappiness = ({ chartData }: any) => {
  useEffect(() => {
    const myChart = echarts.init(document.getElementById("pieChart1"));

    const option = {
      grid: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        containLabel: true, // Set to true to remove white space around the pie chart
      },
      series: [
        {
          name: "Data",
          type: "pie",
          radius: "80%",
          center: ["50%", "50%"],
          data: [
            {
              value: chartData[0]?.percentage,
              name: "Level 0",
              itemStyle: { color: "#FFDAEE" },
            },
            {
              value: chartData[1]?.percentage,
              name: "Level 1",
              itemStyle: { color: "#FCD6E9" },
            },
            {
              value: chartData[2]?.percentage,
              name: "Level 2",
              itemStyle: { color: "#F9BCDC" },
            },
            {
              value: chartData[3]?.percentage,
              name: "Level 3",
              itemStyle: { color: "#F69FCC" },
            },
            {
              value: chartData[4]?.percentage,
              name: "Level 4",
              itemStyle: { color: "#F168B1" },
            },
            {
              value: chartData[5]?.percentage,
              name: "Level 5",
              itemStyle: { color: "#F04BA1" },
            },
            {
              value: chartData[6]?.percentage,
              name: "Level 6",
              itemStyle: { color: "#EC3192" },
            },
            {
              value: chartData[7]?.percentage,
              name: "Level 7",
              itemStyle: { color: "#EB1481" },
            },
            {
              value: chartData[8]?.percentage,
              name: "Level 8",
              itemStyle: { color: "#CE1273" },
            },
          ],
          label: {
            show: true, // Set to false to hide the indicator text
          },

          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    };

    // Set the chart configuration
    myChart.setOption(option);

    // Clean up the chart when the component is unmounted
    return () => {
      myChart.dispose();
    };
  }, [chartData]); // Run the effect only once on mount

  return (
    <div
      id="pieChart1"
      style={{
        display: "flex",
        padding: "0",
        margin: 0,
        justifyContent: "center",
        width: "100%",
        height: "400px",
      }}
    />
  );
};

export default PieChartHappiness;
