import React, { useEffect, useState } from "react";
import { Button, Typography, Modal, Input, message, Form, Select } from "antd";
// import { commentsSVC, authSVC } from "../../services";
import CommentsDataTable from "../../components/dataTable/b2b/CommentasDataTable";
import axios from "axios";
import * as Cookie from "universal-cookie";
import useSWR from "swr";
const { Text } = Typography;

const cookie = new Cookie.default();
const cachedToken = cookie.get("userAuthTokenAdmin");

interface Contentdata {
  key?: string;
  _id: string;
  bodyText: string;
  user_id: string;
  video_id: string;
}
const fetcher = (url: any) =>
  axios
    .get(url, {
      headers: {
        Authorization: `Bearer ${cachedToken}`,
        "Content-Type": "application/json",
      },
    })
    .then((res) => res?.data?.data?.data);

function Clients() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const API_URL = process.env.REACT_APP_API_URL;

  const { data: contentData } = useSWR(
    API_URL + "/admin/getAllAdmin/",
    fetcher,
    {
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
    }
  );

  return (
    <div className="contentBox">
      <div className="info">
        <Text className="title24">Clients</Text>
      </div>
      <CommentsDataTable
        // deleteComments={deleteComments}
        setshowModel={setIsModalOpen}
        isOpen={isModalOpen}
        contentData={contentData}
      />
    </div>
  );
}

export default Clients;
