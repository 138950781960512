import React, { useEffect, useState } from "react";
import "./fourm.scss";
import {
  Button,
  Typography,
  Modal,
  Input,
  message,
  Form,
  DatePickerProps,
  DatePicker,
  Select,
  Image,
} from "antd";
import { fourmSVC } from "../../services";
import ReactQuill from "react-quill";
import moment from "moment";
import DataTableFourm from "../../components/dataTable/DataTableFourm";
import { Option } from "antd/es/mentions";
import * as Cookie from "universal-cookie";
import { LazyLoadImage } from "react-lazy-load-image-component";
import useSWR from "swr";
import axios from "axios";

const { Text } = Typography;
interface Contentdata {
  key?: string;
  _id: string;
  author: {
    _id: string;
    email: string;
    profile_picture: string;
  };
  title: string;
  bodytext: string;
  image: string;
  adminStatus: string;
  createdAt: string;
  updatedAt: string;
}

function FourmPage() {
  const SITEURL = process.env.REACT_APP_API_SITE;
  const API_URL = process.env.REACT_APP_API_URL;

  const cookie = new Cookie.default();
  const cachedToken = cookie.get("userAuthTokenAdmin");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userId, setuserId] = useState<any>();

  const [isEditable, setIsEditable] = useState(false);
  const [editContent, setEditContent] = useState<Contentdata>({
    _id: "",
    author: { email: "", _id: "", profile_picture: "" },
    title: "",
    bodytext: "",
    image: "",
    adminStatus: "",
    createdAt: "",
    updatedAt: "",
  });
  // const [contentData, setContentData] = useState<Contentdata[]>([])
  const [uploadedImage, setUploadedImage] = useState<any>(null);
  const [loading, setloading] = useState<boolean>(false);

  // const { data: postDetails, error } = useSWR(userId && API_URL + `/post/${userId}`, fetcher);

  const fetcher = (url: any) =>
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${cachedToken}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => res?.data?.data);

  console.log(cachedToken, "ss");

  const { data: contentData, mutate } = useSWR(API_URL + "/post/all", fetcher, {
    revalidateOnFocus: true,
    revalidateOnReconnect: true,
  });

  // const getfourmData = async () => {
  //   fourmSVC.getfourm().then((res: any) => {
  //     setContentData(res.data.data)
  //   }).catch((err: any) => {
  //     message.error(err.message);
  //   })
  // }

  // useEffect(() => {
  //   getfourmData()
  // }, [])

  const deleteFourm = async (id: string) => {
    await fourmSVC
      .deletefourm(id)
      .then((res: any) => {
        mutate();
        message.success("Deleted Successfully");
        handleCancel();
      })
      .catch((err: any) => {
        message.error(err.message);
      });
  };

  const updateFourm = async (id: any, status?: string) => {
    setloading(true);

    if (
      editContent.adminStatus === "PENDING" ||
      editContent.adminStatus === "REJECTED"
    ) {
      message.info("Please change the status");
    } else {
      await fourmSVC
        .updatefourm(id, {
          adminStatus: status ?? editContent.adminStatus,
        })
        .then((res: any) => {
          setloading(false);
          mutate();
          handleCancel();
          message.success("Updated Successfully");
        })
        .catch((err: any) => {
          // message.error(err.message);
          setloading(false);
        });
    }
  };

  const editFourm = (id: any) => {
    setIsModalOpen(!isModalOpen);
    setloading(true);
    fourmSVC
      .editfourm(id)
      .then((res: any) => {
        setEditContent({
          _id: res.data.data?._id,
          author: {
            email: res.data.data.author?.email,
            _id: res.data.data.author?._id,
            profile_picture: res.data.data.author?.profile_picture,
          },
          title: res.data.data.title,
          bodytext: res.data.data.bodytext,
          image: res.data.data.image,
          adminStatus: res.data.data.adminStatus,
          createdAt: res.data.data.createdAt,
          updatedAt: res.data.data.updatedAt,
        });
        setloading(false);
      })
      .catch((err: any) => {
        message.error(err.message);
        setloading(false);
      });
  };

  const showModal = () => {
    setIsModalOpen(true);
    setIsEditable(false);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setEditContent({
      _id: "",
      author: { email: "", _id: "", profile_picture: "" },
      title: "",
      bodytext: "",
      image: "",
      adminStatus: "",
      createdAt: "",
      updatedAt: "",
    });
    setUploadedImage(null);
    setloading(false);
  };

  const handleSelectedItem = async (value: string) => {
    setEditContent((prevData) => ({
      ...prevData,
      adminStatus: value,
    }));
  };

  // const csvData = contentData?.map((item:any) => ({
  //   id: item?._id,
  //   email: item?.author?.email,
  //   status: item?.adminStatus,
  //   date: item?.createdAt
  // }));

  const matchedContent = contentData?.find(
    (item: any) => item?._id === editContent._id
  );
  // APPROVED
  // console.log(matchedContent?.adminStatus, "");

  return (
    <div className="contentBox">
      <div className="info">
        <Text className="title24">Community Posts</Text>
      </div>

      <DataTableFourm
        setuserId={setuserId}
        isLoading={loading}
        deleteOtherContent={deleteFourm}
        editContent={editFourm}
        setshowModel={setIsModalOpen}
        setEditable={setIsEditable}
        isOpen={isModalOpen}
        contentData={contentData}
        updateFourm={updateFourm}
      />
      <Modal
        confirmLoading
        footer={false}
        title={"Forum Admin Verify"}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        {loading ? (
          <div className="loaderWrapper">
            <div className="loadercircle"></div>
          </div>
        ) : (
          <>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <LazyLoadImage
                style={{
                  width: "100px",
                  height: "100px",
                  borderRadius: "50%",
                }}
                src={editContent?.author?.profile_picture ?? "noavatar.png"}
                alt="Avatar"
                // preview={false}
              />
            </div>
            <br />
            <Input
              className="input"
              disabled
              size={"large"}
              style={{ borderRadius: "10px" }}
              value={editContent.author._id}
              placeholder={"POST ID"}
            />
            <br />
            <br />
            <Input
              className="input"
              disabled
              size={"large"}
              style={{ borderRadius: "10px" }}
              value={editContent.author.email}
              placeholder={"Email"}
            />
            <br />
            <br />
            <Input
              className="input"
              disabled
              size={"large"}
              style={{ borderRadius: "10px" }}
              value={editContent.adminStatus}
              placeholder={"Status"}
            />
            <br />
            <br />
            <Input
              className="input"
              disabled
              size={"large"}
              style={{ borderRadius: "10px" }}
              value={moment(editContent.createdAt).format("DD-MM-YYYY")}
              placeholder={"Post Created Date"}
            />
            <br />
            <br />
            {matchedContent?.adminStatus === "APPROVED" ? (
              <>
                <Button
                  style={{ backgroundColor: "orange" }}
                  onClick={() =>
                    window.open(`${SITEURL}/post/${editContent._id}`)
                  }
                >
                  Go to Live Post
                </Button>
                <br />
                <br />
              </>
            ) : null}
            <Input
              className="input"
              disabled
              size={"large"}
              style={{ borderRadius: "10px" }}
              value={editContent.title}
              placeholder={"Post Title"}
            />
            <br />
            <br />
            <ReactQuill
              style={{ cursor: "not-allowed" }}
              value={editContent.bodytext}
              readOnly={true}
              modules={{
                toolbar: [],
                clipboard: {
                  matchVisual: false,
                },
              }}
            />
            <br />
            <br />
            <div style={{ display: "flex", justifyContent: "center" }}>
              <LazyLoadImage
                style={{
                  width: "300px",
                  height: "200px",
                }}
                src={editContent?.image ?? "noavatar.png"}
                alt="Avatar"
                // preview={false}
              />
            </div>
            <br />
            <br />
            <Select
              showSearch
              value={editContent.adminStatus}
              style={{ width: "100%", borderRadius: "24px" }}
              placeholder="Select a Theme"
              optionFilterProp="children"
              onChange={handleSelectedItem}
            >
              <Option value="APPROVED">APPROVED</Option>
              <Option value="REJECTED">REJECTED</Option>
            </Select>
            <br />
            <br />
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                className="button"
                onClick={() => updateFourm(editContent._id)}
              >
                Update Status
              </Button>
            </div>
          </>
        )}
      </Modal>
    </div>
  );
}

export default FourmPage;
