import React, { useState } from "react";
import styles from "../../styles/auth.module.scss";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Form,
  Image,
  Input,
  message,
  Modal,
  Typography,
} from "antd";
import { Link, useNavigate } from "react-router-dom";
import { authSVC } from "../../services";
import helper from "../../utils/helper";
import Text from "antd/lib/typography/Text";
interface userDetails {
  email: string;
  password: string;
}
function Login() {
  const [userDetails, setuserDetails] = useState<userDetails>({
    email: "",
    password: "",
  });
  const [email, setEmail] = useState("");
  const [loading, setloading] = useState<boolean>(false);
  const [visible, setIsVisible] = useState(false);
  const handleCancel = () => setIsVisible(false);

  const ENV_V = process.env.REACT_APP_STG_ENV;

  const navigate = useNavigate();
  const onFinish = () => {
    setloading(true);
    authSVC
      .login(userDetails)
      .then((res) => {
        if (res.status === 200) {
          setloading(false);
          const userAuthTokenAdmin = res.data?.data?.token;
          const id = res.data?.data?.userObj?._id;
          const userType = res.data?.data?.user_type;
          const userPackage = res.data?.data?.userObj?.package_name;
          console.log(res.data?.data, "admin login response");
          if (userType === "Company_admin" || userType === "Super_admin") {
            localStorage.setItem("uIdcms", id);
            localStorage.setItem("accessToken", userAuthTokenAdmin);
            localStorage.setItem("user_type", userType);
            localStorage.setItem("userPackage", userPackage);
            helper.login(userAuthTokenAdmin);
            setuserDetails({ email: "", password: "" });
            message.success("Welcome to Your Dashboard!");

            window.location.reload();
            navigate("/subscription_agreement");
          } else {
            message.info("Invalid Credentials");
            setloading(false);
          }
        }
      })
      .catch((err) => {
        message.info("Invalid Credentials");
        setloading(false);
      });
  };

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;

    setuserDetails((preValue) => ({
      ...preValue,
      [name]: value,
    }));
  };

  const handleForgotPassword = (email: string) => {
    authSVC
      .forgotPassword({ email: email })
      .then((res) => {
        message.success("Email Send.");
        setIsVisible(false);
        setEmail("");
      })
      .catch((err: any) => {
        message.error(err?.response?.data?.error);
        console.log(err, "sahas");
      });
  };

  return (
    <div className="main_wrapper">
      <div className={styles.wrapper}>
        <div className={styles.content_wrapper}>
          <Image
            className={styles.img}
            src="/img/login.png"
            preview={false}
            width="100%"
          />
          {/* <div className={styles.bg}>
            <h1>
              27% Of Managers Ranked “Work-Related Stress” Among The Top Three
              Mental Health Concerns
            </h1>
          </div> */}
        </div>
        <div className={styles.content_wrapper1}>
          <div className={styles.login_wrapper}>
            <Image
              className={styles.logo}
              src="/img/logo_new_v2.png"
              preview={false}
            />
            <div className={styles.contents}>
              <div className={styles.titletxt}>
                <h1>Welcome Back!</h1>
                <p>
                  <span>Login To Your</span> SoulSARA Dashboard
                </p>
              </div>
              <Form
                name="normal_login"
                className="login-form"
                initialValues={{ remember: true }}
                onFinish={onFinish}
              >
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      type: "email",
                      message: "Please input your Email!",
                    },
                  ]}
                >
                  <Input
                    name="email"
                    onChange={handleInputChange}
                    placeholder="Please Enter Your HR Email ID"
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    { required: true, message: "Please input your Password!" },
                  ]}
                >
                  <Input.Password
                    name="password"
                    onChange={handleInputChange}
                    type="password"
                    placeholder="Please Enter Your Password"
                  />
                </Form.Item>
                <Form.Item>
                  {/* <Form.Item name="remember" valuePropName="checked" noStyle>
                    <Checkbox>Remember me</Checkbox>
                  </Form.Item> */}

                  <div
                    className={styles.forgot}
                    onClick={() => setIsVisible(true)}
                  >
                    <Link className={styles.forgot_txt} to={""}>
                      Forgot Password?
                    </Link>
                  </div>
                </Form.Item>

                <Form.Item style={{ marginBottom: "0" }}>
                  <Button
                    loading={loading}
                    type="primary"
                    htmlType="submit"
                    className={styles.btn}
                  >
                    Login
                  </Button>
                </Form.Item>
              </Form>
              {ENV_V == "STG" ? (
                <Button
                  onClick={() =>
                    window.open(
                      "https://kalinga-wellness-monitor-stg.dr9djqnb75rxo.amplifyapp.com/login"
                    )
                  }
                  type="primary"
                  className={styles.back}
                >
                  Go Back To Employee Login
                </Button>
              ) : (
                <Button
                  onClick={() =>
                    window.open("https://www.soulsara.world/login")
                  }
                  type="primary"
                  className={styles.back}
                >
                  Go Back To Employee Login
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={visible}
        footer={false}
        closable
        onCancel={handleCancel}
        width={400}
      >
        <div className="contentBox">
          <div className="info">
            <Text className="font14">Forgot Password</Text>
            <div className="profile_wrapper">
              <Input
                className="input"
                type="email"
                name="email"
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter Your Email Id"
                suffix={
                  <Image
                    src={"/img/new/edit_input.svg"}
                    alt={""}
                    width={17}
                    height={17}
                    preview={false}
                  />
                }
              />
              <Button
                loading={loading}
                className="cn_btn"
                onClick={() => {
                  if (email === "") {
                    message.info("Please Fill Email Id");
                  } else {
                    handleForgotPassword(email);
                  }
                }}
              >
                Forgot Password
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Login;
