import { AxiosPromise } from 'axios';

const API_PATH = process.env.REACT_APP_API_URL;

type API = {
  post: (url: string, payload: any) => AxiosPromise
  put: (url: string, payload: any) => AxiosPromise
  get: (url: string) => AxiosPromise
  delete: (id: string) => AxiosPromise
}

const ngoarticles = (api: API) => {
  // themeposts post
  const addThemeposts = (payload: any) => api.post(API_PATH + '/ngo/create/', payload)
  const getThemeposts = () => api.get(API_PATH + '/ngo/all/')
  const deleteThemeposts = (id: string) => api.delete(API_PATH + `/api/v1/deleteblogbyid/${id}`)
  const editThemeposts = (id: string) => api.get(API_PATH + `/ngo/get/${id}`)
  const updateThemeposts = (id: string, payload: any) => api.put(API_PATH + `/ngo/updatePost/${id}`, payload)

  return {
    addThemeposts,
    getThemeposts,
    deleteThemeposts,
    editThemeposts,
    updateThemeposts,
  }
}

export default ngoarticles
