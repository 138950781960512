import { AxiosPromise } from 'axios';

const API_PATH = process.env.REACT_APP_API_URL;

type API = {
  post: (url: string, payload: any) => AxiosPromise
  put: (url: string, payload: any) => AxiosPromise
  get: (url: string) => AxiosPromise
  delete: (id: string) => AxiosPromise
}

const themes = (api: API) => {
  // Theme
  const addThemes = (payload: any) => api.post(API_PATH + '/themes/', payload)
  const getThemes = () => api.get(API_PATH + '/themes')
  const deleteThemes = (id: string) => api.delete(API_PATH + `/themes/${id}`)
  const editThemes = (id: string) => api.get(API_PATH + `/themes/${id}`)
  const updateThemes = (id: string, payload: any) => api.put(API_PATH + `/themes/${id}`, payload)
 
  return {
    addThemes,
    getThemes,
    deleteThemes, 
    editThemes,
    updateThemes
  }
}

export default themes
