import React, { useEffect, useState } from "react";
import Text from "antd/lib/typography/Text";
import styles from "../../styles/dashboard.module.scss";

export default function Summary({overallLevel}:any) {
    return (
        <div className={styles.white_bg_wrapper}>
            <div className={styles.info_title}>
                <Text className={styles.title}>Summary</Text>
                <div className={styles.summery}>
                    <p>
                        {overallLevel?.summary_report}
                    </p>
                </div>
            </div>
        </div>
    )
}
