import React, { useEffect, useState } from 'react'
import './webinar.scss'
import { Button, Typography, Modal, Input, message, DatePickerProps, DatePicker } from 'antd'
import { ocaSVC } from '../../services';
import DataTableOCA from '../../components/dataTable/DataTableOCA';
import axios from "axios";
import useSWR from "swr";
import * as Cookie from "universal-cookie";
const cookie = new Cookie.default();
const cachedToken = cookie.get("userAuthTokenAdmin");

const { Text } = Typography;


interface Contentdata {
  key?: string
  _id: string
  first_Name: string
  second_Name: string
  preferred_Name: string
  corporate_Email: string
  organization_Size: string
  createdAt: string
  status: string
}

const fetcher = (url: any) => axios.get(url, {
  headers: {
    'Authorization': `Bearer ${cachedToken}`,
    'Content-Type': 'application/json'
  }
}).then((res) => res?.data?.data);

function OCA() {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  // const [editContent, setEditContent] = useState<Contentdata>({ _id: "", first_Name: "", second_Name: "", preferred_Name: "", corporate_Email: "", organization_Size: "", createdAt: "", status:"" })
  // const [contentData, setContentData] = useState<Contentdata[]>([])
  // const [uploadedImage, setUploadedImage] = useState<any>(null)
  // const [loading, setloading] = useState<boolean>(false)

  const API_PATH = process.env.REACT_APP_API_URL;

  const { data: contentData } = useSWR(API_PATH + `/oca/all/`, fetcher, {
    revalidateOnFocus: true,
    revalidateOnReconnect: true
  });

  // const getOtherContent = async () => {
  //   ocaSVC.getOCAData().then((res: any) => {
  //     setContentData(res.data.data)
  //   }).catch((err: any) => {
  //     message.error(err.message);
  //   })
  // }

  // useEffect(() => {
  //   getOtherContent()
  // }, [])


  // const deleteWebinar = (id: string) => {
  //   webinarSVC.deletewebinar(id).then((res: any) => {
  //     getOtherContent()
  //     message.success("Deleted Successfully")
  //   }).catch((err: any) => {
  //     message.error(err.message);
  //   })
  // }

  // const updateWebinar = (id: string) => {
  //   setloading(true)
  //   const formData: any = new FormData();
  //   formData.append('title', editContent.title)
  //   formData.append('date', editContent.date)
  //   formData.append('hour', editContent.hour)
  //   formData.append('video', editContent.video)
  //   formData.append('start_date', editContent.start_date)
  //   formData.append('end_date', editContent.end_date)
  //   formData.append('meetingLink', editContent.meetingLink)
  //   formData.append('meeting_id', editContent.meeting_id)
  //   formData.append('passcode', editContent.passcode)
  //   formData.append('image', editContent.cover_img)

  //   webinarSVC.updatewebinar(id, formData).then((res: any) => {
  //     setloading(false)
  //     getOtherContent()
  //     message.success("Updated Successfully")
  //     handleCancel()
  //   }).catch((err: any) => {
  //     message.error(err.message);
  //     setloading(false)
  //   })
  // }
  // const addwebinar = () => {
  //   setloading(true)
  //   const formData: any = new FormData();
  //   formData.append('title', editContent.title)
  //   formData.append('date', editContent.date)
  //   formData.append('hour', editContent.hour)
  //   formData.append('video', editContent.video)
  //   formData.append('start_date', editContent.start_date)
  //   formData.append('end_date', editContent.end_date)
  //   formData.append('meetingLink', editContent.meetingLink)
  //   formData.append('meeting_id', editContent.meeting_id)
  //   formData.append('passcode', editContent.passcode)
  //   formData.append('image', editContent.cover_img)

  //   webinarSVC.addwebinar(formData).then((res: any) => {
  //     setloading(false)
  //     getOtherContent()
  //     message.success("Added Successfully")
  //     setEditContent({ _id: "", title: "", date: "", hour: "", video: "", start_date: "", end_date: "", meetingLink: "", meeting_id: "", passcode: "", cover_img: "" })
  //     setUploadedImage(null)
  //     handleCancel()
  //   }).catch((err: any) => {
  //     message.error(err.message);
  //     setloading(false)
  //   })
  // }
  // const editWebinar = (id: string) => {
  //   webinarSVC.editwebinar(id).then((res: any) => {
  //     const data = res.data.data
  //     setEditContent({ _id: data._id, title: data.title, date: data.date, hour: data.hour, video: data.video, start_date: data.start_date, end_date: data.end_date, meetingLink: data.meetingLink, meeting_id: data.meeting_id, passcode: data.passcode, cover_img: data.cover_img })
  //     setUploadedImage(data.cover_img)
  //   }).catch((err: any) => {
  //     message.error(err.message);
  //   })
  // }

  // const showModal = () => {
  //   setIsModalOpen(true);
  //   setIsEditable(false);
  // };

  // const handleOk = () => {
  //   setIsModalOpen(false);
  // };

  // const handleCancel = () => {
  //   setIsModalOpen(false);
  //   setEditContent({ _id: "", first_Name: "", second_Name: "", preferred_Name: "", corporate_Email: "", organization_Size: "", createdAt: "", status:"" })
  //   setUploadedImage(null)
  //   setloading(false)
  // };

  // const handleFileUpload = async (event: any) => {
  //   event.preventDefault();
  //   const file = event.target.files[0];
  //   const reader = new FileReader();

  //   reader.onloadend = () => {
  //     setUploadedImage(reader.result)
  //   }

  //   setEditContent((prevData) => ({
  //     ...prevData,
  //     cover_img: file,
  //   }));

  //   if (file) {
  //     reader.readAsDataURL(file)
  //   }
  // }

  // const handleInputChange = (event: any) => {
  //   const { name, value } = event.target;
  //   setEditContent((prevData) => ({
  //     ...prevData,
  //     [name]: value,
  //   }));
  // };
  // const onChangeStartDate: DatePickerProps['onChange'] = (date, dateString) => {

  //   setEditContent((prevData: any) => ({
  //     ...prevData,
  //     start_date: dateString,
  //   }));
  // };

  // const onChangeEndDate: DatePickerProps['onChange'] = (date, dateString) => {
  //   setEditContent((prevData: any) => ({
  //     ...prevData,
  //     end_date: dateString,
  //   }));
  // };

  return (
    <div className="contentBox">
      <div className="info">
        <Text className="title24">OCA DATA</Text>
        {/* <Button onClick={showModal} className='button'>Add New Webinar</Button> */}
      </div>
      <DataTableOCA setshowModel={setIsModalOpen} setEditable={setIsEditable} isOpen={isModalOpen} contentData={contentData} />
      {/* <Modal centered width={"40%"} footer={false} title={isEditable === true ? 'Update  Webinar' : 'Add Webinar'} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>

        <label htmlFor=""><b>Title</b></label>
        <Input className='input' name='title' onChange={handleInputChange} size={'large'} style={{ borderRadius: '10px' }} value={editContent.title} placeholder={"Enter Webinar Title"} />

        <br /><br />
        <label htmlFor=""><b>Host Date</b></label>
        <Input className='input' name='date' onChange={handleInputChange} size={'large'} style={{ borderRadius: '10px' }} value={editContent.date} placeholder={"Enter Webinar Host Date"} />

        <br /><br />
        <label htmlFor=""><b>Host Hour</b></label>

        <Input className='input' name='hour' onChange={handleInputChange} size={'large'} style={{ borderRadius: '10px' }} value={editContent.hour} placeholder={"Enter Webinar Host Hour"} />

        {
          isEditable === true ?
            <>
              <br /><br />
              <label htmlFor=""><b>Video URL</b></label>
              <Input type='url' className='input' name='video' onChange={handleInputChange} size={'large'} style={{ borderRadius: '10px' }} value={editContent.video} placeholder={"Enter Video Url"} />
            </>
            : null
        }

        <br /><br />
        <label htmlFor=""><b>Start Date</b></label>
        <DatePicker
          showTime
          format="YYYY-MM-DD HH:mm:ss"
          value={editContent.start_date ? dayjs(editContent.start_date) : null}
          // disabledDate={(current) => {
          //   return current && current < moment().endOf('day');
          // }}
          style={{ width: '100%' }} onChange={onChangeStartDate} />

        <br /><br />
        <label htmlFor=""><b>End Date</b></label>
        <DatePicker
          showTime
          format="YYYY-MM-DD HH:mm:ss"
          value={editContent.end_date ? dayjs(editContent.end_date) : null}
          // disabledDate={(current) => {
          //   return current && current < moment().endOf('day');
          // }}
          style={{ width: '100%' }} onChange={onChangeEndDate} />


        <br /><br />
        <label htmlFor=""><b>Meeting Link</b></label>

        <Input className='input' name='meetingLink' onChange={handleInputChange} size={'large'} style={{ borderRadius: '10px' }} value={editContent.meetingLink} placeholder={"Enter Meeting Link"} />

        <br /><br />
        <label htmlFor=""><b>Meeting ID</b></label>

        <Input className='input' name='meeting_id' onChange={handleInputChange} size={'large'} style={{ borderRadius: '10px' }} value={editContent.meeting_id} placeholder={"Enter Meeting ID"} />
        <br /><br />
        <label htmlFor=""><b>Meeting Passcode</b></label>

        <Input className='input' name='passcode' onChange={handleInputChange} size={'large'} style={{ borderRadius: '10px' }} value={editContent.passcode} placeholder={"Enter Meeting Passcode"} />

        <br /><br />
        <label htmlFor=""><b>Cover Image</b></label>

        <ImageUploader image={editContent?.cover_img} uploadedImage={uploadedImage} handleFileUpload={handleFileUpload} />

        <div style={{ display: 'flex', justifyContent: 'center', }}>
          <Button onClick={() => {

            if (editContent.title === "") {
              message.info("Please Enter title")
            }
            else if (editContent.date === "") {
              message.info("Please Enter Host Date")
            }
            else if (editContent.hour === "") {
              message.info("Please Enter Host Hour")
            }
            else if (editContent.start_date === "") {
              message.info("Please Enter Start Date")
            }
            else if (editContent.end_date === "") {
              message.info("Please Enter End Date")
            }

            else if (editContent.meetingLink === "") {
              message.info("Please Enter Meeting Link")
            }
            else if (editContent.meeting_id === "") {
              message.info("Please Enter Meeting ID")
            }
            else if (editContent.passcode === "") {
              message.info("Please Enter Passcode")
            }
            else {
              if (isEditable === true) {
                updateWebinar(editContent._id)
              } else {
                if (editContent.cover_img === "") {
                  message.info("Please upload a cover image")
                } else {
                  addwebinar()
                }
              }
            }
          }
          } style={{ marginTop: '10px', width: '200px', backgroundColor: 'green', color: 'white' }} loading={loading}>{isEditable === true ? 'Update Webinar' : 'Add Webinar'}</Button>
        </div>
      </Modal> */}
    </div>
  )
}

export default OCA