import React, { useEffect, useState } from "react";
import "./ApproveProducts.scss";
import { Select } from "antd";
import { Button, Typography, Modal, Input, message, Form } from "antd";
import "react-quill/dist/quill.snow.css";
import { Option } from "antd/es/mentions";
import DataTableApproveProducts from "../../components/dataTable/DataTableApproveProducts";
import { productsSVC } from "../../services";
import { CSVLink } from "react-csv";
import axios from "axios";
import useSWR from "swr";
import * as Cookie from "universal-cookie";
import ImageUploaderOne from "../../components/ImageUploaderOne";

const cookie = new Cookie.default();
const cachedToken = cookie.get("userAuthTokenAdmin");

const { Text } = Typography;

interface Contentdata {
  key?: string;
  product_id: string;
  meta_title: string;
  meta_description: string;
  meta_keyword: any;
}
const fetcher = (url: any) => axios.get(url).then((res) => res?.data?.data);

function ApproveProducts() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [editContent, setEditContent] = useState<Contentdata>({
    product_id: "",
    meta_title: "",
    meta_description: "",
    meta_keyword: null,
  });
  // const [contentData, setContentData] = useState<any>([]);
  const [uploadedImage, setUploadedImage] = useState<any>(null);
  const [loading, setloading] = useState<boolean>(false);
  const [btnDisable, setBtnDisable] = useState(false);
  const datahash = [
    { id: "1", label: "wellness" },
    { id: "2", label: "health" },
    { id: "3", label: "fitness" },
    { id: "4", label: "healthylifestyle" },
    { id: "5", label: "selfcare" },
    { id: "6", label: "motivation" },
    { id: "7", label: "love" },
    { id: "8", label: "healthy" },
    { id: "9", label: "mentalhealth" },
    { id: "10", label: "lifestyle" },
    { id: "11", label: "yoga" },
    { id: "12", label: "beauty" },
    { id: "13", label: "nutrition" },
    { id: "14", label: "healing" },
    { id: "15", label: "selflove" },
    { id: "16", label: "mindfulness" },
    { id: "17", label: "healthyliving" },
    { id: "18", label: "wellbeing" },
    { id: "19", label: "workout" },
    { id: "20", label: "meditation" },
    { id: "21", label: "skincare" },
    { id: "22", label: "relax" },
    { id: "23", label: "happiness" },
    { id: "24", label: "fitnessmotivation" },
    { id: "25", label: "weightloss " },
    { id: "26", label: "fit" },
  ];
  const API_PATH = "https://soulsara.shop";

  const { data: contentData, mutate:getOtherContent } = useSWR(API_PATH + '/api/v1/admin/admin-get-products-for-meta/', fetcher, {
    revalidateOnFocus: true,
    revalidateOnReconnect: true
  });

  // const getOtherContent = async () => {
  //   productsSVC
  //     .getAllProducts()
  //     .then((res: any) => {
  //       setContentData(res.data.data);
  //       console.log(res.data.data);
  //     })
  //     .catch((err: any) => {
  //       message.error(err.message);
  //     });
  // };

  const csvData = contentData && contentData?.map((item: any) => ({
    product_id: item?.id,
    product: item?.name,
    product_category: item?.category,
    price: item?.price,
    soldby: item?.vendor_name,
    status: item?.status === 0 ? "Active" : "InActive",
    meta_title: item?.meta_title,
    meta_description: item?.meta_description,
    meta_keywords: item?.meta_keywords,
  }));

  // useEffect(() => {
  //   getOtherContent();
  // }, []);

  const updatemetadata = (id: string) => {
    setloading(true);
    const data = {
      product_id: id,
      meta_title: editContent.meta_title,
      meta_description: editContent.meta_description,
      meta_keyword: ["SOULSARA"],
      // meta_keyword: editContent.meta_keyword,
    };
    productsSVC
      .updateMetaData(data)
      .then((res: any) => {
        setloading(false);
        getOtherContent();
        message.success("Updated Successfully");
      })
      .catch((err: any) => {
        message.error(err.message);
        setloading(false);
      });
  };

  const editmetadata = (id: string) => {
    const findByid = contentData.find((item: any) =>
      item.id === id ? item : null
    );
    setEditContent({
      product_id: findByid.id,
      meta_title: findByid.meta_title,
      meta_description: findByid.meta_description,
      meta_keyword:
        findByid.meta_keyword !== null
          ? findByid.meta_keyword.split(",")
          : null,
    });
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setEditContent({
      product_id: "",
      meta_title: "",
      meta_description: "",
      meta_keyword: null,
    });
    setUploadedImage(null);
    setloading(false);
  };

  const handleInputChange = (event: any) => {
    setBtnDisable(false);
    const { name, value } = event.target;
    setEditContent((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleChange = (value: string[]) => {
    setBtnDisable(false);
    setEditContent((prevData) => ({
      ...prevData,
      meta_keyword: value,
    }));
  };

  return (
    <div className="contentBox">
      <div className="info">
        <Text className="title24">All Approve Products</Text>
        {/* <Button onClick={showModal} className='button'>All Approve Products</Button> */}
      </div>
     {csvData && <CSVLink data={csvData && csvData} filename={"exported-data.csv"}>
        <Button className="button" type="primary" style={{ marginTop: "16px" }}>
          Export CSV
        </Button>
      </CSVLink>}
      <DataTableApproveProducts
        editContent={editmetadata}
        setshowModel={setIsModalOpen}
        setEditable={setIsEditable}
        isOpen={isModalOpen}
        contentData={contentData}
      />
      <Modal
        width={"80%"}
        footer={false}
        title={isEditable === true ? "Update  Meta Data" : "Add Meta Data"}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <label htmlFor="">
          <b>Production ID</b>
        </label>

        <Input
          disabled
          className="input"
          name="product_id"
          onChange={handleInputChange}
          size={"large"}
          style={{ borderRadius: "10px" }}
          value={editContent.product_id}
          placeholder={"Product ID"}
        />

        {editContent.product_id === "" ? null : (
          <>
            <br />
            <br />
            <label htmlFor="">
              <b>Meta Keyword</b>
            </label>
            <br />
            <Select
              value={editContent.meta_keyword ?? []}
              mode={"tags"}
              allowClear
              style={{ width: "100%" }}
              placeholder="Please SELECT/ADD a meta_keyword"
              onChange={handleChange}
            >
              {datahash.map((option) => (
                <Option key={option.id} value={option.label}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </>
        )}

        {editContent.meta_keyword === null ? null : (
          <>
            <br />
            <br />
            <label htmlFor="">
              <b>Meta Title</b>
            </label>
            <br />
            <Input
              showCount
              maxLength={60}
              className="input"
              name="meta_title"
              onChange={handleInputChange}
              size={"large"}
              style={{ borderRadius: "10px" }}
              value={editContent.meta_title}
              placeholder={"Enter Meta Title"}
            />
          </>
        )}

        {editContent.meta_title === null ? null : (
          <>
            <br />
            <br />
            <label htmlFor="">
              <b>Meta Description</b>
            </label>
            <br />
            <Input.TextArea
              showCount
              maxLength={160}
              className="input"
              name="meta_description"
              onChange={handleInputChange}
              size={"large"}
              style={{ borderRadius: "10px" }}
              value={editContent.meta_description}
              placeholder={"Enter Meta Description"}
            />
          </>
        )}
        <br />
        <br />
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            onClick={() => {
              if (editContent.meta_keyword === null) {
                setBtnDisable(true);
                message.info("Please Selected/Add a keywords");
              } else if (editContent.meta_title === null) {
                setBtnDisable(true);
                message.info("Please Enter title");
              } else if (editContent.meta_description === null) {
                setBtnDisable(true);
                message.info("Please Enter Meta Discription");
              } else {
                if (isEditable === true) {
                  updatemetadata(editContent.product_id);
                }
              }
            }}
            style={{
              marginTop: "10px",
              width: "200px",
              backgroundColor: "green",
              color: "white",
            }}
            loading={loading}
            disabled={btnDisable ? true : false}
          >
            {isEditable === true ? "Update Meta Data" : "Add Meta Data"}
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default ApproveProducts;
