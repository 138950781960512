export const menu = [
    {
        id: 1,
        icon: "home.png",
        url: "/demo-requests",
        title: "Demo Requests",
        userType: "Super_admin"
    },
    {
        id: 2,
        title: "Demo Link Generate",
        url: "/linkgenerate",
        userType: "Super_admin"
    },
    {
        id: 100,
        title: "Overview",
        url: "/overview",
        userType: "Company_admin"
    },
    {
        id: 200,
        title: "Manage Employees",
        url: "/manage_exployees",
        userType: "Company_admin"
    },
    {
        id: 3,
        title: "Manage Department",
        url: "/departments",
        userType: "Company_admin"
    },
    {
        id: 4,
        title: "Insights",
        url: "/insights",
        userType: "Company_admin"
    },
    {
        id: 5,
        title: "Manage Plan",
        url: "/plan",
        userType: "Company_admin"
    },
    {
        id: 6,
        title: "Profile",
        url: "/hrprofile",
        userType: "Company_admin"
    },
   
    {
        id: 8,
        title: "Webinar",
        url: "/webinar",
        userType: "Super_admin"
    },
    {
        id: 20,
        title: "OCA Data",
        url: "/oca",
        userType: "Super_admin"
    },
    
    {
        id: 19,
        title: "Forum",
        url: "/forum",
        userType: "Super_admin"
    },
    {
        id: 9,
        title: "Profile",
        url: "/hrprofile",
        userType: "Super_admin"
    },
    {
        id: 10,
        title: "Times Content",
        listItem: [
            {
                id: 10,
                icon: "thought.png",
                url: "/thought",
                title: "Thought For Week"
            },
            {
                id: 10,
                icon: "featured.png",
                url: "/featureditems",
                title: "Featured Items"
            },
            {
                id: 10,
                icon: "videoplay.png",
                url: "/latestvideos",
                title: "Latest Videos"
            },
            {
                id: 10,
                icon: "tooltips.png",
                url: "/crisismanagementtools",
                title: "Crisis Management Tools"
            },
            {
                id: 10,
                icon: "sprint.png",
                url: "/womeninsports",
                title: "Women in Sports"
            },
            {
                id: 10,
                icon: "newspaper.png",
                url: "/newsonwellness",
                title: "News on Wellness"
            },
            {
                id: 10,
                icon: "rocket.png",
                url: "/takeaway",
                title: "Takeaway"
            },
            {
                id: 10,
                icon: "newspaper.png",
                url: "/times-featured-story",
                title: "Featured Story"
            },
        ],
        userType: "Super_admin"
    },
    {
        id: 11,
        title: "Events & Short Videos",
        listItem: [
            {
                id: 11,
                icon: "playlist.png",
                url: "/soulsaraeventvideos",
                title: "Events Videos"
            },

            {
                id: 11,
                icon: "playlist.png",
                url: "/soulsarashortvideos",
                title: "Short Videos"
            }
        ],
        userType: "Super_admin"
    },
    {
        id: 12,
        icon: "article.png",
        url: "/article",
        title: "Article",
        userType: "Super_admin"
    },
    {
        id: 13,
        icon: "thought.png",
        url: "/register_ngo",
        title: "NGO's",
        userType: "Super_admin"
    },
    {
        id: 14,
        icon: "thought.png",
        url: "/blog",
        title: "Blog Post",
        userType: "Super_admin"
    },
    {
        id: 15,
        icon: "tooltips.png",
        url: "/approve-products",
        title: "Approve Products",
        userType: "Super_admin"
    },
    {
        id: 16,
        title: "Clients",
        listItem: [
         
            {
                id: 16,
                icon: "clients.png",
                url: "/clients",
                title: "Clients"
            },
          
            // {
            //     id: 16,
            //     icon: "wellness.png",
            //     url: "/wellness",
            //     title: "Wellness"
            // },

        ],
        userType: "Super_admin"
    },
    {
        id: 17,
        title: "HelpLine",
        listItem: [
            // {
            //     id: 17,
            //     icon: "home.png",
            //     url: "/help-line",
            //     title: "HelpLine Slots"
            // },
            {
                id: 17,
                icon: "home.png",
                url: "/helpline_history",
                title: "HelpLine History"
            }
        ],
        userType: "Super_admin"
    },
    {
        id: 18,
        title: "Download CSV",
        url: "/download-csv",
        userType: "Super_admin"
    },
   
]