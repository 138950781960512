import React, { useEffect } from "react";
import { Button, Image, Popover, message } from "antd";
// import helper from "../utils/helper";
import { useNavigate } from "react-router-dom";
import { useB2BContext } from "../context/ContextB2B";
import styles from "../styles/dashboard.module.scss";
import Text from "antd/lib/typography/Text";
import { Tooltip } from "antd";

function Navbar() {
  const navigate = useNavigate();
  const { getUserByID } = useB2BContext();
  const userType: any = localStorage.getItem("user_type");
  const SITEURL = process.env.REACT_APP_API_SITE;

  useEffect(() => {
    console.log(getUserByID, "getUserByID");
  }, [getUserByID]);

  return (
    <div className={styles.navbar}>
      <div className={styles.logo}>
        <Image src="/img/logo_new_v2.png" alt="logo" preview={false} />
      </div>
      <div className={styles.btn_grp}>
        <div className={styles.profile}>
          <Image
            className={styles.img}
            src={
              getUserByID?.cover_image
                ? getUserByID?.cover_image
                : "/img/new/profile.svg"
            }
            alt="logo"
            preview={false}
          />
          <div className={styles.info}>
            <Text className={styles.title}>
              {getUserByID?.user_type === "Super_admin"
                ? "Super Admin"
                : getUserByID?.full_name}
            </Text>
            <Text className={styles.subtitle}>
              {getUserByID?.user_type === "Super_admin"
                ? "SOULSARA"
                : getUserByID?.company}
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
