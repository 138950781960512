import React, { useEffect, useState } from "react";
import Text from "antd/lib/typography/Text";
import { Button, Image, Progress } from "antd";
import styles from "../../styles/dashboard.module.scss";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { authSVC } from "../../services";
import { useB2BContext } from "../../context/ContextB2B";

export default function DepartmentByProgressBar() {
  const navigate = useNavigate();
  const [tapIndex2, setTapIndex2] = useState<number>(0);
  const { getUserByID } = useB2BContext();

  const [departmentWellnessLevel, setdepartmentWellnessLevel] = useState<any[]>(
    []
  );
  const [departmentHappinessLevel, setdepartmentHappinessLevel] = useState<
    any[]
  >([]);

  useEffect(() => {
    const month: any = moment().format("M");
    const year: any = moment().format("yyyy");
    if (getUserByID) {
      authSVC
        .getDepartmentLevels(getUserByID?.company, Number(month), Number(year))
        .then((res) => {
          setdepartmentWellnessLevel(res?.data?.data);
        })
        .catch((err) => {
          console.log(err);
        });

      authSVC
        .getDepartmentHappinessLevels(
          getUserByID?.company,
          Number(month),
          Number(year)
        )
        .then((res) => {
          setdepartmentHappinessLevel(res?.data?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [getUserByID]);

  return (
    <div className={styles.white_bg_wrapper} onClick={() => {}}>
      <div className={styles.info_title}>
        <div className={styles.flex_2_col}>
          <div className={styles.title_grp}>
            <Text
              style={{
                color: tapIndex2 === 0 ? "" : "#2E3191",
                opacity: tapIndex2 === 0 ? "" : "0.3",
              }}
              className={styles.title}
              onClick={() => setTapIndex2(0)}
            >
              Wellness Index
            </Text>
            <Text
              style={{
                color: tapIndex2 === 1 ? "" : "#2E3191",
                opacity: tapIndex2 === 1 ? "" : "0.3",
              }}
              className={styles.title}
              onClick={() => setTapIndex2(1)}
            >
              Happiness Index
            </Text>
          </div>
          <Button
            disabled={departmentWellnessLevel.length === 0 ? true : false}
            className={styles.btn}
            onClick={() => navigate("/insights")}
          >
            View All
          </Button>
        </div>
      </div>
      <div className={styles.content_box}>
        {departmentWellnessLevel.length === 0 ? (
          <div className="no_data_yet">
            <img src="/img/User_empty.png" alt="" width="56" height="52" />
            <p>No data yet</p>
          </div>
        ) : tapIndex2 === 0 ? (
          departmentWellnessLevel?.map((item: any, index: number) => {
            return (
              <div
                className={styles.listTitleWrapper}
                key={index}
                onClick={() => navigate("/insights_single")}
              >
                <div className={styles.listTitle}>
                  <div className={styles.titleInfo}>
                    <div
                      style={{ backgroundColor: item?.departmentColor }}
                      className={styles.round}
                    ></div>
                    <Text className={styles.title}>{item?.department}</Text>
                  </div>
                  <Image
                    className={styles.img}
                    src="/img/new/arrow_forward1.svg"
                    preview={false}
                  />
                </div>
                <div className={styles.listProgress}>
                  <Text className={styles.title}>
                    {item?.percentage?.toFixed(1)}%
                  </Text>
                  <Progress
                    strokeColor={item?.departmentColor}
                    trailColor="#FDECED"
                    percent={item?.percentage}
                    status="active"
                    showInfo={false}
                  />
                </div>
              </div>
            );
          })
        ) : tapIndex2 === 1 ? (
          departmentHappinessLevel?.map((item: any, index: number) => {
            return (
              <div
                className={styles.listTitleWrapper}
                key={index}
                onClick={() => navigate("/insights_single")}
              >
                <div className={styles.listTitle}>
                  <div className={styles.titleInfo}>
                    <div
                      style={{ backgroundColor: item?.departmentColor }}
                      className={styles.round}
                    ></div>
                    <Text className={styles.title}>{item?.department}</Text>
                  </div>
                  <Image
                    className={styles.img}
                    src="/img/new/arrow_forward1.svg"
                    preview={false}
                  />
                </div>
                <div className={styles.listProgress}>
                  <Text className={styles.title}>
                    {item?.percentage?.toFixed(1)}%
                  </Text>
                  <Progress
                    strokeColor={item?.departmentColor}
                    trailColor="#FDECED"
                    percent={item?.percentage}
                    status="active"
                    showInfo={false}
                  />
                </div>
              </div>
            );
          })
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
