import React, { useEffect, useState } from "react";
import "./latestvideos.scss";
import {
  Button,
  Typography,
  Modal,
  Input,
  message,
  Form,
  Select,
  DatePickerProps,
  DatePicker,
} from "antd";
import DataTableLatestVideos from "../../components/dataTable/DataTableLatestVideo";
import { otherVideoContentsSVC } from "../../services";
import ReactQuill from "react-quill";
import moment from "moment";
import dayjs from "dayjs";
import useSWR from "swr";
import axios from "axios";
import * as Cookie from "universal-cookie";

const cookie = new Cookie.default();
const cachedToken = cookie.get("userAuthTokenAdmin");

const { Text } = Typography;

interface Contentdata {
  key?: string;
  id: number;
  title: string;
  type?: string;
  description: string;
  url: string;
  sheduledDate: string;
}

const fetcher = (url: any) =>
  axios
    .get(url, {
      headers: {
        Authorization: `Bearer ${cachedToken}`,
        "Content-Type": "application/json",
      },
    })
    .then((res) => res?.data);

function LatestVideos() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [editContent, setEditContent] = useState<any>({
    id: "",
    title: "",
    type: "",
    description: "",
    url: "",
    sheduledDate: null,
  });
  // const [contentData, setContentData] = useState<Contentdata[]>([]);
  const [loading, setloading] = useState<boolean>(false);
  const [btnDisable, setBtnDisable] = useState(false);
  const contentType = [
    {
      type: "energizemonday",
      label: "Energize Monday",
    },
    {
      type: "events",
      label: "Events",
    },
    {
      type: "crisis",
      label: "Crisis Management",
    },
  ];

  const API_URL = process.env.REACT_APP_API_URL;

  const { data: contentData, mutate: getOtherContent } = useSWR(
    API_URL + "/times/video-contents/",
    fetcher,
    {
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
    }
  );

  // const getOtherContent = async () => {
  //   otherVideoContentsSVC
  //     .getLatestVideos()
  //     .then((res: any) => {
  //       setContentData(res.data);
  //     })
  //     .catch((err: any) => {
  //       message.error(err.message);
  //     });
  // };

  // useEffect(() => {
  //   getOtherContent();
  // }, []);

  const deleteOtherContent = (id: string) => {
    otherVideoContentsSVC
      .deleteLatestVideos(id)
      .then((res: any) => {
        getOtherContent();
        message.success("Deleted Successfully");
      })
      .catch((err: any) => {
        message.error(err.message);
      });
  };

  const updateOtherContent = (id: string) => {
    setloading(true);
    const formData: any = new FormData();
    formData.append("title", editContent.title);
    formData.append("type", editContent.type);
    formData.append("description", editContent.description);
    formData.append("video", editContent.url);
    if (editContent.sheduledDate !== null) {
      formData.append("sheduledDate", editContent.sheduledDate);
    } else {
      formData.append("sheduledDate", moment().format("YYYY-MM-DD"));
    }

    otherVideoContentsSVC
      .updateLatestVideos(id, formData)
      .then((res: any) => {
        setloading(false);
        getOtherContent();
        message.success("Updated Successfully");
        handleCancel();
      })
      .catch((err: any) => {
        message.error(err.message);
        setloading(false);
      });
  };
  const addOtherContent = () => {
    setloading(true);
    const formData: any = new FormData();
    formData.append("title", editContent.title);
    formData.append("type", editContent.type);
    formData.append("description", editContent.description);
    formData.append("video", editContent.url);
    if (editContent.sheduledDate !== null) {
      formData.append("sheduledDate", editContent.sheduledDate);
    } else {
      formData.append("sheduledDate", moment().format("YYYY-MM-DD"));
    }
    otherVideoContentsSVC
      .addLatestVideos(formData)
      .then((res: any) => {
        setloading(false);
        getOtherContent();
        message.success("Added Successfully");
        setEditContent({
          id: "",
          title: "",
          description: "",
          url: "",
          type: "",
          sheduledDate: null,
        });
        handleCancel();
      })
      .catch((err: any) => {
        message.error(err.message);
        setloading(false);
      });
  };
  const editOtherContent = (id: string) => {
    otherVideoContentsSVC
      .editLatestVideos(id)
      .then((res: any) => {
        setEditContent({
          id: res.data.id,
          title: res.data.title,
          description: res.data.description,
          type: res.data.type,
          url: res.data.url,
          sheduledDate: res.data.sheduledDate,
        });
      })
      .catch((err: any) => {
        message.error(err.message);
      });
  };

  const showModal = () => {
    setIsModalOpen(true);
    setIsEditable(false);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setEditContent({
      id: "",
      title: "",
      description: "",
      url: "",
      type: "",
      sheduledDate: null,
    });
    setloading(false);
  };

  const handleFileUpload = (event: any) => {
    event.preventDefault();
    setBtnDisable(false);
    const file = event.target.files[0];
    setEditContent((prevData: any) => ({
      ...prevData,
      url: file,
    }));
  };

  const handleInputChange = (event: any) => {
    setBtnDisable(false);
    const { name, value } = event.target ?? "";
    setEditContent((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSelectedItem = (value: string) => {
    setBtnDisable(false);
    setEditContent((prevData: any) => ({
      ...prevData,
      type: value,
    }));
  };

  const handleDescription = (value: any) => {
    setBtnDisable(false);
    setEditContent((prevData: any) => ({
      ...prevData,
      description: value,
    }));
  };

  const onChange: DatePickerProps["onChange"] = (date, dateString) => {
    setBtnDisable(false);
    setEditContent((prevData: any) => ({
      ...prevData,
      sheduledDate: date,
    }));
  };

  return (
    <div className="contentBox">
      <div className="info">
        <Text className="title24">Latest Videos</Text>
      </div>
      <DataTableLatestVideos
        deleteOtherVideoContent={deleteOtherContent}
        editContent={editOtherContent}
        setshowModel={setIsModalOpen}
        setEditable={setIsEditable}
        isOpen={isModalOpen}
        contentData={contentData}
        showModal={showModal}
      />
      <Modal
        footer={false}
        title={
          isEditable === true ? "Update  Latest Videos" : "Add Latest Videos"
        }
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        {editContent.type === "" ? (
          <Select
            showSearch
            style={{ width: "100%", marginBottom: "16px" }}
            placeholder="Select a Content Type"
            optionFilterProp="children"
            onChange={handleSelectedItem}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={contentType.map((option) => ({
              value: option.type,
              label: option.label,
            }))}
          />
        ) : (
          <>
            <Select
              value={editContent.type}
              showSearch
              style={{ width: "100%", marginBottom: "16px" }}
              placeholder="Select a Content Type"
              optionFilterProp="children"
              onChange={handleSelectedItem}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={contentType.map((option) => ({
                value: option.type,
                label: option.label,
              }))}
            />
          </>
        )}
        {editContent.type === "" ? null : (
          <>
            <DatePicker
              value={
                editContent.sheduledDate
                  ? dayjs(editContent.sheduledDate)
                  : null
              }
              disabledDate={(current) => {
                return current && current < moment().startOf("day");
              }}
              style={{ width: "100%", marginBottom: "16px" }}
              onChange={onChange}
            />
          </>
        )}
        {editContent.type === "" ? null : (
          <>
            <Input
              className="input"
              name="title"
              onChange={handleInputChange}
              size={"large"}
              style={{ borderRadius: "8px", marginBottom: "16px" }}
              value={editContent.title}
              placeholder={"Enter Title"}
            />
          </>
        )}
        {editContent.title === "" ? null : (
          <>
            <ReactQuill
              value={editContent.description}
              onChange={handleDescription}
              modules={{
                toolbar: [
                  // [{ 'header': '1' }, { 'header': '2' }],
                  ["bold", "italic", "underline", "strike", "blockquote"],
                  [{ list: "ordered" }, { list: "bullet" }],
                  ["link"],
                  ["clean"],
                ],
              }}
              style={{ marginBottom: "16px" }}
            />
          </>
        )}

        {editContent.title === "" ? null : (
          <>
            <label htmlFor="" style={{ color: "red" }}>
              Note:Maximum file size 100MB can upload
            </label>
            <div>
              <input type="file" accept="video/*" onChange={handleFileUpload} />
            </div>
          </>
        )}
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            onClick={() => {
              if (editContent.type === "") {
                setBtnDisable(true);
                message.info("Please Enter Content Type");
              } else if (editContent.sheduledDate == null) {
                setBtnDisable(true);
                message.info("Please Enter Date");
              } else if (editContent.title === "") {
                setBtnDisable(true);
                message.info("Please Enter Title");
              } else if (editContent.description === "") {
                setBtnDisable(true);
                message.info("Please Enter Description");
              } else {
                if (isEditable === true) {
                  updateOtherContent(editContent.id ?? "");
                } else {
                  if (editContent.url === "") {
                    message.info("Please upload a video");
                    setBtnDisable(true);
                  } else {
                    addOtherContent();
                  }
                }
              }
            }}
            style={{
              marginTop: "16px",
            }}
            className="button"
            loading={loading}
            disabled={btnDisable ? true : false}
          >
            {isEditable === true ? "Update " : "Add Video"}
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default LatestVideos;
