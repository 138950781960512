import React, { useEffect, useState } from "react";
import styles from "../../styles/dashboard.module.scss";
import { useB2BContext } from "../../context/ContextB2B";
import { useNavigate } from "react-router-dom";

function AdminOverview() {
  const navigate = useNavigate();

  return (
    <div className={styles.wellness_report_main_wrapper}>
      <div className={styles.col_2_div}>
        {/* Left Container */}
        <div className={styles.data_container}>
          {/* <div className="session_card">
            <h1>Session Count</h1>
            <p>
              10
            </p>
          </div> */}
        </div>
       
      </div>
    </div>
  );
}

export default AdminOverview;
