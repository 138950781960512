import React, { useEffect, useState } from "react";
import Text from "antd/lib/typography/Text";
import { Image } from "antd";
import styles from "../../styles/dashboard.module.scss";
import BarChartHappiness from "../../components/StackedBarHappiness";
import { useB2BContext } from "../../context/ContextB2B";
import moment from "moment";
import { authSVC } from "../../services";

export default function HappinessBarchart() {
  const { getUserByID } = useB2BContext();

  const [departmentLevelhappiness, setdepartmentLevelHappiness] = useState<
    any[]
  >([]);

  useEffect(() => {
    const year: any = moment().format("yyyy");
    if (getUserByID) {
      authSVC
        .getbarChartHappinessLevels(getUserByID?.company, Number(year))
        .then((res) => {
          setdepartmentLevelHappiness(res?.data?.data);
          console.log(res?.data?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [getUserByID]);

  return (
    <div className={styles.white_bg_wrapper}>
      <div className={styles.info_title}>
        <div className={styles.title_grp}>
          <Text className={styles.title}>Happiness Index</Text>
        </div>
        <div className={styles.title_grp1}>
          {/* <Image
            className={styles.img}
            src="/img/new/download_icon.svg"
            preview={false}
          /> */}
          {/* <Text className={styles.subtitle1}>21% from last month</Text> */}
        </div>
        <div className={styles.graph}>
          <BarChartHappiness chartData={departmentLevelhappiness} />
        </div>
      </div>
    </div>
  );
}
