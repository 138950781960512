import React, { useEffect, useState } from "react";
import "./datatable.scss";
import { Space, Table, Input, Button, Image, Tag, Popover } from "antd";
import type { ColumnsType } from "antd/es/table";
import moment from "moment";

interface Contentdata {
  key?: string;
  id: number;
  title: string;
  type: string;
  contend: string;
  link: string;
  image: string;
  sheduledDate: string;
}
// interface DataTableProps {
//     setshowModel: (value: boolean) => void,
//     setEditable: (value: boolean) => void,
//     deleteThought: (value: any) => void,
//     editContent:(value:any)=>void
//     isOpen: boolean,
//     contentData?: Contentdata[]
// }
function DataTableOtherContent({
  setshowModel,
  setEditable,
  isOpen,
  contentData,
  deleteOtherContent,
  editContent,
  showModal,
}: any) {
  const [searchText, setSearchText] = useState("");
  const [pagination, setPagination] = useState<any>({ page: 1, pageSize: 5 });
  const [contentType, setContentType] = useState<any>();

  useEffect(() => {
    contentData &&
      contentData.map((data: any) => {
        setContentType(data.type);
      });
  }, [contentData]);

  useEffect(() => {
    console.log(contentType, "sdsds");
  }, [contentType]);

  const columns: ColumnsType<Contentdata> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 80,
      filteredValue: [searchText],
      defaultSortOrder: "descend",
      sorter: (a, b) => a.id - b.id,
      onFilter: (value, record) => {
        return (
          String(record.title).toLowerCase().includes(value.toLocaleString()) ||
          String(record.id).toLowerCase().includes(value.toLocaleString()) ||
          String(record.contend).toLowerCase().includes(value.toLocaleString())
        );
      },
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Content",
      dataIndex: "contend",
      key: "contend",
      render: (text) => (
        <>
          <p dangerouslySetInnerHTML={{ __html: text?.slice(0, 100) }} />
        </>
      ),
    },
    {
      title: "Link",
      dataIndex: "link",
      key: "link",
      // width: 90,
      render: (_, record) => (
        <Space size="middle">
          <a
            href={record?.link}
            target="_blank"
            rel="noreferrer"
            style={{ color: "#1677ff", textDecoration: "underline" }}
          >
            Click
          </a>
        </Space>
      ),
    },
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      render: (_, record) => (
        <Space size="middle">
          <Image src={record?.image} width={60} height={60} alt={"image"} />
        </Space>
      ),
    },
    {
      title: "Status",
      dataIndex: "sheduledDate",
      key: "sheduledDate",
      // width: 90,
      render: (_, record) => (
        <Space size="middle">
          {record.sheduledDate === null ? (
            <Tag color={"green"}>Published</Tag>
          ) : moment().format("YYYY-MM-DD") >= record.sheduledDate ? (
            <Tag color={"green"}>Published</Tag>
          ) : (
            <Tag color={"red"}>Not Published</Tag>
          )}
        </Space>
      ),
    },

    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={() => {
              setshowModel(!isOpen);
              setEditable(true);
              editContent(record.id);
            }}
            className="btn_edit_table"
          >
            Edit
          </Button>
          <Popover
            content={
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "10px",
                }}
              >
                <Button
                  style={{
                    backgroundColor: "green",
                    width: "100%",
                    color: "#fff",
                  }}
                  onDoubleClick={() => deleteOtherContent(record.id)}
                >
                  Yes
                </Button>
              </div>
            }
            title="Please Double click to delete."
          >
            <Button className="btn_edit_table">Delete</Button>
          </Popover>
        </Space>
      ),
    },
  ];

  return (
    <div className="dataTable">
      <div className="filterMenu">
        {contentType === "womeninsports" && (
          <Button onClick={showModal} className="add_button">
            Add Women In Sport
          </Button>
        )}
        {contentType === "crisis" && (
          <Button onClick={showModal} className="add_button">
            Add Crisis Management
          </Button>
        )}
        {contentType === "newsonwellness" && (
          <Button onClick={showModal} className="add_button">
            Add News On Wellness
          </Button>
        )}
        {contentType === "takeaway" && (
          <Button onClick={showModal} className="add_button">
            Add Takeaway
          </Button>
        )}

        <Input.Search
          onSearch={(value) => {
            setSearchText(value);
          }}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
          className="searchField"
          placeholder="Search here..."
        />
      </div>
      <Table
        scroll={{ y: 500 }}
        className="dataGrid"
        columns={columns}
        dataSource={contentData}
        pagination={{
          pageSize: pagination?.pageSize,
          onChange(page, pageSize) {
            setPagination(pageSize);
          },
          showSizeChanger: true,
        }}
      />
    </div>
  );
}

export default DataTableOtherContent;
