import { AxiosPromise } from 'axios';

const API_PATH = process.env.REACT_APP_API_URL;

type API = {
  post: (url: string, payload: any) => AxiosPromise
  put: (url: string, payload: any) => AxiosPromise
  get: (url: string) => AxiosPromise
  delete: (id: string) => AxiosPromise
}

const webinar = (api: API) => {

  const addwebinar = (payload: any) => api.post(API_PATH + '/webinars/', payload)
  const getwebinar = () => api.get(API_PATH + '/webinars/')
  const getByIDwebinar = () => api.get(API_PATH + '/webinars/')
  const deletewebinar = (id: string) => api.delete(API_PATH + `/webinars/${id}`)
  const editwebinar = (id: string) => api.get(API_PATH + `/webinars/${id}`)
  const updatewebinar = (id: string, payload: any) => api.put(API_PATH + `/webinars/${id}`, payload)

  return {
    addwebinar,
    getwebinar,
    deletewebinar,
    editwebinar,
    updatewebinar,
    getByIDwebinar
  }
}

export default webinar
