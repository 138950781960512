import React, { useEffect, useState } from "react";
import "./featureditems.scss";
import {
  Button,
  Typography,
  Modal,
  Input,
  message,
  DatePickerProps,
  Form,
  Select,
} from "antd";
import DataTableFeatured from "../../components/dataTable/DataTableFeatured";
import { featuredItemSVC } from "../../services";
import ImageUploader from "./ImageUploader";
import ReactQuill from "react-quill";
import { DatePicker, Space } from "antd";
import moment from "moment";
import dayjs from "dayjs";
import useSWR from "swr";
import axios from "axios";
import * as Cookie from "universal-cookie";
// import { LazyLoadImage } from "react-lazy-load-image-component";

const cookie = new Cookie.default();
const cachedToken = cookie.get("userAuthTokenAdmin");

const { Text } = Typography;

interface Contentdata {
  key?: string;
  id: number;
  title?: string;
  image?: string;
  label?: string;
  link?: string;
  sheduledDate?: string;
}
const fetcher = (url: any) =>
  axios
    .get(url, {
      headers: {
        Authorization: `Bearer ${cachedToken}`,
        "Content-Type": "application/json",
      },
    })
    .then((res) => res?.data?.data);

function FeaturedItems() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [editContent, setEditContent] = useState<any>({
    id: "",
    title: "",
    image: "",
    label: "",
    link: "",
    sheduledDate: null,
  });
  // const [contentData, setContentData] = useState<Contentdata[]>([]);
  const [uploadedImage, setUploadedImage] = useState<any>(null);
  const [loading, setloading] = useState<boolean>(false);
  const [btnDisable, setBtnDisable] = useState(false);
  const API_URL = process.env.REACT_APP_API_URL;

  const { data: contentData, mutate: getFeaturedItem } = useSWR(
    API_URL + "/times/featured-items/",
    fetcher,
    {
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
    }
  );

  // const getFeaturedItem = () => {
  //   featuredItemSVC
  //     .getFeatured()
  //     .then((res: any) => {
  //       setContentData(res.data.data);
  //     })
  //     .catch((err: any) => {
  //       message.error(err.message);
  //     });
  // };
  // useEffect(() => {
  //   getFeaturedItem();
  // }, []);

  const deleteFeaturedItem = (id: string) => {
    featuredItemSVC
      .deleteFeatureds(id)
      .then((res: any) => {
        getFeaturedItem();
        message.success("Deleted Successfully");
      })
      .catch((err: any) => {
        message.error(err.message);
      });
  };

  const updateFeatured = (id: string) => {
    setloading(true);
    const formData: any = new FormData();
    formData.append("image", editContent.image);
    formData.append("title", editContent.title);
    formData.append("label", editContent.label);
    formData.append("link", editContent.link);

    if (editContent.sheduledDate !== null) {
      formData.append("sheduledDate", editContent.sheduledDate);
    } else {
      formData.append("sheduledDate", moment().format("YYYY-MM-DD"));
    }
    featuredItemSVC
      .updateFeatureds(id, formData)
      .then((res: any) => {
        setloading(false);
        getFeaturedItem();
        message.success("Featured Item Updated Successfully");
        handleCancel();
      })
      .catch((err: any) => {
        message.error(err.message);
        setloading(false);
      });
  };
  const addFeaturedItem = () => {
    setloading(true);
    const formData: any = new FormData();
    formData.append("image", editContent.image);
    formData.append("title", editContent.title);
    formData.append("label", editContent.label);
    formData.append("link", editContent.link);

    if (editContent.sheduledDate !== null) {
      formData.append("sheduledDate", editContent.sheduledDate);
    } else {
      formData.append("sheduledDate", moment().format("YYYY-MM-DD"));
    }

    featuredItemSVC
      .addFeatured(formData)
      .then((res: any) => {
        setloading(false);
        getFeaturedItem();
        message.success("Featured Item Added Successfully");
        setEditContent({
          id: "",
          title: "",
          image: "",
          label: "",
          link: "",
          sheduledDate: null,
        });
        setUploadedImage(null);
        handleCancel();
      })
      .catch((err: any) => {
        message.error(err.message);
        setloading(false);
      });
  };
  const editFeaturedItem = (id: string) => {
    featuredItemSVC
      .editFeatureds(id)
      .then((res: any) => {
        const data = res.data.data;
        setEditContent({
          id: data.id,
          title: data.title,
          image: data.image,
          label: data.label,
          link: data.link,
          sheduledDate: data.sheduledDate,
        });
      })
      .catch((err: any) => {
        message.error(err.message);
      });
  };

  const showModal = () => {
    setEditContent({
      id: "",
      title: "",
      image: "",
      label: "",
      link: "",
      sheduledDate: null,
    });
    setIsModalOpen(true);
    setIsEditable(false);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setEditContent({
      id: "",
      title: "",
      image: "",
      label: "",
      link: "",
      sheduledDate: null,
    });
    setIsModalOpen(false);
    setUploadedImage(null);
  };

  const handleFileUpload = async (event: any) => {
    event.preventDefault();
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setUploadedImage(reader.result);
    };
    setBtnDisable(false);
    setEditContent({ ...editContent, image: file });

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleInputChange = (event: any) => {
    setBtnDisable(false);
    const { name, value } = event.target;
    setEditContent((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSelectedItem = (value: string) => {
    setBtnDisable(false);
    setEditContent({ ...editContent, title: value });
  };
  const handleDescription = (value: any) => {
    setBtnDisable(false);
    setEditContent({ ...editContent, label: value });
  };

  const onChange: DatePickerProps["onChange"] = (date, dateString) => {
    setBtnDisable(false);
    setEditContent((prevData: any) => ({
      ...prevData,
      sheduledDate: date,
    }));
  };

  return (
    <div className="contentBox">
      <div className="info">
        <Text className="title24">Featured Items</Text>
      </div>
      <DataTableFeatured
        deleteFeatured={deleteFeaturedItem}
        editContent={editFeaturedItem}
        setshowModel={setIsModalOpen}
        setEditable={setIsEditable}
        isOpen={isModalOpen}
        contentData={contentData}
        showModal={showModal}
      />
      <Modal
        footer={false}
        title={
          isEditable === true
            ? "Update Featured Product / Service"
            : "Add Featured Product / Service"
        }
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        style={{ width: "60%" }}
      >
        {editContent.title !== "" ? (
          <Select
            value={editContent.title}
            showSearch
            style={{
              width: "100%",
              marginBottom: "16px",
            }}
            placeholder="Select a Featured Item"
            optionFilterProp="children"
            onChange={handleSelectedItem}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={[
              {
                value: "product",
                label: "Product",
              },
              {
                value: "service",
                label: "Service",
              },
            ]}
          />
        ) : (
          <Select
            showSearch
            style={{ width: "100%", marginBottom: "16px" }}
            placeholder="Select a Featured Item"
            optionFilterProp="children"
            onChange={handleSelectedItem}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={[
              {
                value: "product",
                label: "Product",
              },
              {
                value: "service",
                label: "Service",
              },
            ]}
          />
        )}
        {editContent.title === "" ? null : (
          <>
            <DatePicker
              value={
                editContent.sheduledDate
                  ? dayjs(editContent.sheduledDate)
                  : null
              }
              disabledDate={(current) => {
                return current && current <= moment().startOf("day");
              }}
              style={{ width: "100%", marginBottom: "16px" }}
              onChange={onChange}
            />
          </>
        )}
        {editContent.title === "" ? null : (
          <div style={{ marginBottom: "16px" }}>
            <ReactQuill
              value={editContent.label}
              onChange={handleDescription}
              modules={{
                toolbar: [
                  [{ header: "1" }, { header: "2" }],
                  ["bold", "italic", "underline", "strike", "blockquote"],
                  [{ list: "ordered" }, { list: "bullet" }],
                  ["link"],
                  ["clean"],
                ],
              }}
            />
          </div>
        )}

        {editContent.label === "" ? null : (
          <>
            <Input
              type="url"
              name="link"
              onChange={handleInputChange}
              size={"large"}
              style={{ borderRadius: "8px", marginBottom: "16px" }}
              value={editContent.link}
              placeholder={"Enter Link"}
            />
          </>
        )}
        {editContent.link === "" ? null : (
          <>
            <ImageUploader
              image={editContent?.image}
              uploadedImage={uploadedImage}
              handleFileUpload={handleFileUpload}
            />
          </>
        )}
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            onClick={() => {
              if (editContent.title === "") {
                message.info("Please Select a Featured Item");
                setBtnDisable(true);
              } else if (editContent.sheduledDate == null) {
                message.info("Please select date ");
                setBtnDisable(true);
              } else if (editContent.label === "") {
                message.info("Please Enter Content");
                setBtnDisable(true);
              } else if (editContent.link === "") {
                message.info("Please Enter link");
                setBtnDisable(true);
              } else {
                if (isEditable === true) {
                  updateFeatured(editContent.id ?? "");
                } else {
                  if (uploadedImage === null) {
                    setBtnDisable(true);
                    message.info("Please upload a image");
                  } else {
                    addFeaturedItem();
                  }
                }
              }
              // setBtnDisable(false);
            }}
            style={{
              marginTop: "16px",
            }}
            className="button"
            disabled={btnDisable ? true : false}
            loading={loading}
          >
            {isEditable === true
              ? `Update Featured ${editContent.title}`
              : `Add Featured ${editContent.title}`}
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default FeaturedItems;
