import React, { useEffect, useState } from "react";
import { Button, Typography, Modal, Input, message, Form, Select, Divider } from "antd";
import { commentsSVC, authSVC } from "../../services";
import CommentsDataTable from "../../components/dataTable/b2b/CommentasDataTable";
import HelplineDataTable from "../../components/dataTable/b2b/HelplineDataTable";
import axios from "axios";
import * as Cookie from "universal-cookie";
import useSWR from "swr";
const { Text } = Typography;

const cookie = new Cookie.default();
const cachedToken = cookie.get("userAuthTokenAdmin");

interface Contentdata {
  key?: string;
  _id: string;
  bodyText: string;
  user_id: string;
  video_id: string;
}

const fetcher = (url: any) => axios.get(url, {
  headers: {
    'Authorization': `Bearer ${cachedToken}`,
    'Content-Type': 'application/json'
  }
}).then(res => res?.data?.data);

function HelpLineHistory() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const [contentData, setContentData] = useState<Contentdata[]>([]);
  // const [totalSessions, settotalsession] = useState<number>(0);

  const API_URL = process.env.REACT_APP_API_URL;

  const { data: contentData } = useSWR(API_URL + '/session/bookedSession/', fetcher, {
    revalidateOnFocus: true,
    revalidateOnReconnect: true
  });

  const { data: totalSessions } = useSWR(API_URL + '/session/bookedSessionCount/', fetcher, {
    revalidateOnFocus: true,
    revalidateOnReconnect: true
  });

  // const getAllbookedSession = async () => {
  //   authSVC
  //     .getAllBookedSession()
  //     .then((res: any) => {
  //       setContentData(res?.data?.data);

  //     })
  //     .catch((err: any) => {
  //       message.error(err.message);
  //     });
  // };
  // const getAllSessionCount = async () => {
  //   authSVC
  //     .getAllCount()
  //     .then((res: any) => {
  //       settotalsession(res?.data?.data);
  //     })
  //     .catch((err: any) => {
  //       message.error(err.message);
  //     });
  // };

  // useEffect(() => {
  //   // getAllbookedSession();
  //   getAllSessionCount()
  // }, []);

  return (
    <div className="contentBox">
      <div className="session_card">
        <div className="title">Total Sessions Booked</div>
        <div className="count">
          {totalSessions}
        </div>
      </div>

      <div className="colorDivider"/>

      <HelplineDataTable
        setshowModel={setIsModalOpen}
        isOpen={isModalOpen}
        contentData={contentData}
      />
    </div>
  );
}

export default HelpLineHistory;
