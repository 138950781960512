import React, { useEffect, useState } from "react";
import { Button, Typography, Modal, Input, message, Select } from "antd";
import { authSVC, categorySVC } from "../../services";
import { Option } from "antd/es/mentions";
import axios from "axios";
import { useB2BContext } from "../../context/ContextB2B";
import DataTableAudit from "../../components/dataTable/b2b/DataTableAudit";

const { Text } = Typography;

interface Contentdata {
  _id: string;
  full_name: string;
  email: string;
  user_type: string;
  company: string;
}

function Audit() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const { getUserByID } = useB2BContext();
  const [editContent, setEditContent] = useState<Contentdata>({
    _id: "",
    full_name: "",
    email: "",
    user_type: "",
    company: "",
  });
  const [contentData, setContentData] = useState<any[]>([]);
  const [loading, setloading] = useState<boolean>(false);
  const [disableAddBtn, setDisableAddBtn] = useState(false);
  const [userPackage, setUserPackage] = useState("");

  const getEmployees = async () => {
    if (getUserByID?.email) {
      await authSVC
        .getCompanyuser({ email: getUserByID?.email })
        .then((res: any) => {
          setContentData(res.data?.data?.data);
        })
        .catch((err: any) => {
          message.error(err.message);
        });
    }
  };

  // useEffect(() => {
  //   console.log(getUserByID, "jjjj");
  //   setUserPackage(getUserByID.package_name);
  // }, [getUserByID]);

  useEffect(() => {
    if (getUserByID?.email) {
      authSVC
        .getCompanyuser({ email: getUserByID?.email })
        .then((res: any) => {
          setContentData(res.data?.data?.data);
        })
        .catch((err: any) => {
          message.error(err.message);
        });
    }
  }, [getUserByID?.email]);

  const updateEmployees = (id: string) => {
    setloading(true);

    categorySVC
      .updateCategories(id, {
        full_name: editContent.full_name,
        email: editContent.email,
        user_type: editContent.user_type,
        company: getUserByID?.company,
      })
      .then((res: any) => {
        setloading(false);
        getEmployees();
        message.success("Category Updated Successfully");
        setIsModalOpen(false);
      })
      .catch((err: any) => {
        message.error(err.message);
        setloading(false);
      });
  };

  const deleteUser = (email: string) => {
    axios
      .delete(
        "https://thwjjcjh4b.us-east-1.awsapprunner.com/admin/deleteUser/",
        {
          data: {
            email: email,
          },
        }
      )
      .then((response) => {
        message.success("Delete successfully");
        getEmployees();
      })
      .catch((error) => {
        console.error("Error deleting resource:", error);
      });
  };

  const showModal = () => {
    setIsModalOpen(true);
    setIsEditable(false);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setEditContent({
      _id: "",
      full_name: "",
      email: "",
      user_type: "",
      company: "",
    });
    setloading(false);
  };
  const handleInputChange = (e: any) => {
    setEditContent((prevData: any) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSelectedItem = (value: any) => {
    setEditContent((prevData: any) => ({
      ...prevData,
      user_type: value,
    }));
  };

  const filteredContents = contentData?.filter((item: any) => {
    return item?.email === getUserByID?.email ? null : item;
  });

  useEffect(() => {
    if (filteredContents.length === 10 && userPackage === "Gold") {
      setDisableAddBtn(true);
    } else if (filteredContents.length === 100 && userPackage === "Diamond") {
      setDisableAddBtn(true);
    } else if (filteredContents.length >= 100 && userPackage === "Platinum") {
      setDisableAddBtn(true);
    } else {
      setDisableAddBtn(false);
    }
  }, [filteredContents, userPackage]);

  return (
    <div className="comingsoon_wrapper">
      <h1>Coming Soon</h1>
    </div>
    // <div className="contentBox">
    //   <div className="info">
    //     <Text className="title24">Team Wellness Audit</Text>
    //   </div>
    //   <DataTableAudit
    //     email={getUserByID?.email}
    //     deleteuser={deleteUser}
    //     //editContent={editCategory}
    //     setshowModel={setIsModalOpen}
    //     setEditable={setIsEditable}
    //     isOpen={isModalOpen}
    //     contentData={filteredContents}
    //   />
    //   <Modal
    //     footer={false}
    //     title={isEditable === true ? "Update  Employees" : "Add Employees"}
    //     open={isModalOpen}
    //     onOk={handleOk}
    //     onCancel={handleCancel}
    //   >
    //     <h2>Audit</h2>
    //   </Modal>
    // </div>
  );
}

export default Audit;
