import React, { useEffect, useState } from "react";
import { Button, Form, Image, Input, Modal, Typography, message } from "antd";
import ImageUploader from "../../components/ImageUploader";
import { authSVC } from "../../services";
import { useB2BContext } from "../../context/ContextB2B";
const { Text } = Typography;

function LinkGenerate() {
  const [text, setText] = useState("");
  const [copySuccess, setCopySuccess] = useState("");
  const [tokanKey, setTokanKey] = useState("");
  const [btnDisable, setBtnDisable] = useState(false);

  const handleTextChange = (event: any) => {
    setText(event.target.value);
  };

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(
        `https://www.soulsara.world/full-demo-url?tkn=${tokanKey}`
      );
      setCopySuccess("Copied!");
      setBtnDisable(true);
      message.success("Copied!");
      // alert();
    } catch (err) {
      setCopySuccess("Failed to copy!");
      message.warning("Failed to copy!");
    }
  };

  const getCodeDemo = () => {
    authSVC
      .getCodeDemoUrl()
      .then((res) => {
        console.log(res);
        setTokanKey(res.data.data.activationToken);
      })
      .catch((err) => {
        // setisLoading(false);
      });
  };

  return (
    <div className="contentBox">
      <div className="demo_link_generator">
        <h2>Demo Link Generator</h2>
        <p>
          The link is valid for 14 days, granting limited access to the
          community, Wellness Counseling services, or any other paid services.
        </p>
        {tokanKey && (
          <>
            <div className="link">
              <textarea
                className="textClipBorad"
                value={`https://www.soulsara.world/full-demo-url?tkn=${tokanKey}`}
                onChange={handleTextChange}
                placeholder="Enter text to copy"
              />
            </div>
            <button
              className="blue_btn"
              onClick={copyToClipboard}
              disabled={btnDisable ? true : false}
            >
              Copy Link
            </button>
          </>
        )}
        {!tokanKey && (
          <button
            className="blue_btn"
            onClick={getCodeDemo}
            style={{ marginBottom: "24pxx" }}
          >
            Create Demo URL
          </button>
        )}

        <div>{tokanKey && <></>}</div>
      </div>
    </div>
  );
}

export default LinkGenerate;
