import React, { useEffect, useState } from "react";
import Text from "antd/lib/typography/Text";
import styles from "../../styles/dashboard.module.scss";
import PieChartHappiness from "../../components/PieChartHappiness";
import { authSVC } from "../../services";
import moment from "moment";
import { useB2BContext } from "../../context/ContextB2B";

export default function OverallHappinessIndex({ levelsData }: any) {
  const { getUserByID } = useB2BContext();

  const [overallHappinessLevel, setoverallHappinessLevel] = useState<any[]>([]);

  useEffect(() => {
    const month: any = moment().format("M");
    if (getUserByID) {
      authSVC
        .getOverallHappinesspieChartLevels(getUserByID?.company, Number(month))
        .then((res) => {
          setoverallHappinessLevel(res?.data?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [getUserByID]);

  return (
    <div className={styles.white_bg_wrapper}>
      <div className={styles.info_title}>
        <div className={styles.title_grp}>
          <Text className={styles.title}>Overall Happiness Index</Text>
        </div>
        <Text className={styles.subtitle}>
          A lower Level indicates a less confused mental state
        </Text>
      </div>
      <div className={styles.graph}>
        <PieChartHappiness chartData={overallHappinessLevel} />
      </div>
      <div className={styles.levels_wrapper}>
        {levelsData?.map((item: any, index: number) => (
          <div className={styles.level} key={index}>
            <div
              style={{ backgroundColor: item?.color }}
              className={styles.round}
            />
            <Text className={styles.title}>Level {item?.level}</Text>
          </div>
        ))}
      </div>
    </div>
  );
}
