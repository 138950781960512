import axios from 'axios'
import times from './times'
import featuredItem from './featuredItem'
import otherContents from './otherContents'
import articles from './articles'
import otherVideoContents from './otherVideoContents'
import auth from './auth'
import themes from './themes'
import * as Cookie from 'universal-cookie'
import fourm from './fourm'
import ngo from './ngo'
import ngoarticles from './ngoarticles'
import articles_blog from './articles_blog'
import Products from './products'
import categoeries from './categories'
import comments from './comments'
import soulsaraVideoContents from './soulsaraVideoContents'
import webinar from './webinar'
import oca from './oca'
const cookie = new Cookie.default()


let cachedToken: any = ''
cachedToken = cookie.get('userAuthTokenAdmin')

const jsonApi = axios.create({
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
    Authorization: `${cachedToken}`
  },
})
const formDataApi = axios.create({
  headers: {
    'Content-Type': 'multipart/form-data',
    Authorization: `${cachedToken}`
  },
})

jsonApi.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

formDataApi.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {

    return Promise.reject(error);
  }
);


const timesSVC = times(jsonApi)
const themesSVC = themes(jsonApi)
const featuredItemSVC = featuredItem(formDataApi)
const otherContentsSVC = otherContents(formDataApi)
const articlesSVC = articles(formDataApi)
const otherVideoContentsSVC = otherVideoContents(formDataApi)
const authSVC = auth(jsonApi, formDataApi, formDataApi)
const fourmSVC = fourm(jsonApi)
const ngoSVC = ngo(formDataApi)
const ngoarticlesSVC = ngoarticles(formDataApi)
const webinarSVC = webinar(formDataApi)
const ocaSVC = oca(jsonApi)
const blogshop = articles_blog(formDataApi)
const productsSVC = Products(formDataApi)
const categorySVC = categoeries(jsonApi)
const commentsSVC = comments(jsonApi)
const soulsaraVideoContentsSVC = soulsaraVideoContents(formDataApi)

export {ocaSVC, webinarSVC, soulsaraVideoContentsSVC, categorySVC, commentsSVC, fourmSVC, timesSVC, featuredItemSVC, otherContentsSVC, articlesSVC, otherVideoContentsSVC, authSVC, themesSVC, ngoSVC, ngoarticlesSVC, blogshop, productsSVC }
