// frontend/src/CheckoutButton.js (React)

import React from "react";
import { useNavigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import * as Cookie from "universal-cookie";
const cookie = new Cookie.default();

const stripe = require("stripe")(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY); // Replace with your Stripe Publishable Key

const CheckoutButton = ({ priceId }) => {
  const baseUrl = `${window.location.protocol}//${window.location.host}`;
  console.log(baseUrl);

  const navigate = useNavigate();
  const handleCheckout = async () => {
    const sessionStripe = await stripe.checkout.sessions.create({
      line_items: [
        {
          price: `${priceId}`,
          quantity: 1,
        },
      ],
      mode: "payment",
      success_url: `${baseUrl}/overview/?success=true`,
      cancel_url: `${baseUrl}/?canceled=true`,
      automatic_tax: { enabled: false },
    });

    window.location.href = sessionStripe.url;
  };

  return (
    <button className="checkout_btn" onClick={handleCheckout}>
      Checkout
    </button>
  );
};

export default CheckoutButton;
