import { AxiosPromise } from 'axios';

const API_PATH = process.env.REACT_APP_API_URL;

type API = {
  post: (url: string, payload: any) => AxiosPromise
  put: (url: string, payload: any) => AxiosPromise
  get: (url: string) => AxiosPromise
  delete: (id: string) => AxiosPromise
}

const featuredItem = (api: API) => {

  // featured iteam
  const addFeatured = (payload: any) => api.post(API_PATH + '/times/featured-items/', payload, )
  const getFeatured = () => api.get(API_PATH + '/times/featured-items/')
  const deleteFeatureds = (id: string) => api.delete(API_PATH + `/times/featured-items/${id}`)
  const editFeatureds = (id: string) => api.get(API_PATH + `/times/featured-items/${id}`)
  const updateFeatureds = (id: string, payload: any) => api.put(API_PATH + `/times/featured-items/${id}`, payload)


  return {
    addFeatured,
    getFeatured,
    deleteFeatureds,
    editFeatureds,
    updateFeatureds,
  }
}

export default featuredItem
