import React, { useEffect, useState } from "react";
import Text from "antd/lib/typography/Text";
import { Button, Image, Progress } from "antd";
import styles from "../../styles/dashboard.module.scss";

function OverallResult({ overallLevel }: any) {
  useEffect(() => {
    console.log(overallLevel?.summary_report, "summary_report");
  }, [overallLevel]);
  return (
    <div className={styles.white_bg_wrapper}>
      <div className={styles.info_title}>
        <Text className={styles.title}>Overview</Text>
        <Text className={styles.subtitle}>Based on this month result</Text>
      </div>
      <div className={styles.graph_wrapper}>
        <>
          <div className={styles.graph_info}>
            <div className={styles.info_txt}>
              <Text className={styles.title}>Wellness Index</Text>
              <div className={styles.subtitle}>
                <Image src="/img/new/wellness_icon.svg" preview={false} />
                <Text className={styles.title}>
                  {overallLevel?.wellnessLevel?.toFixed(1) ?? 0}/8
                </Text>
              </div>
            </div>
            <div className={styles.info_txt}>
              <Text className={styles.title}>Happiness Index</Text>
              <div className={styles.subtitle}>
                <Image src="/img/new/happiness_icon.svg" preview={false} />
                <Text className={styles.title}>
                  {overallLevel?.happinessLevel?.toFixed(1) ?? 0}/8
                </Text>
              </div>
            </div>
            <div className={styles.info_txt}>
              <Text className={styles.title}>WP Engagement</Text>
              <div className={styles.subtitle}>
                <Image src="/img/new/engagement_icon.svg" preview={false} />
                <Text className={styles.title}>
                  {overallLevel?.wpengagement_percentage?.toFixed(0) ?? 0}%
                </Text>
              </div>
            </div>
          </div>
          <div className={styles.Progress_graph_wrapper}>
            <div className={styles.graph}>
              <Progress
                strokeColor={"#9EC756"}
                trailColor="#FDECED"
                className={styles.circleOne}
                size={109}
                strokeWidth={10}
                type="circle"
                percent={overallLevel?.wpengagement_percentage ?? 0}
                showInfo={false}
              />
              <Progress
                strokeColor={"#2E3191"}
                trailColor="#FDECED"
                className={styles.circleOne}
                size={160}
                strokeWidth={8}
                type="circle"
                percent={
                  overallLevel?.happinessLevel
                    ? overallLevel?.happinessLevel?.toFixed(1) * 10
                    : 0
                }
                showInfo={false}
              />
              <Progress
                strokeColor={"#EB228A"}
                trailColor="#FDECED"
                className={styles.circleOne}
                strokeWidth={6}
                size={205}
                type="circle"
                percent={
                  overallLevel?.wellnessLevel
                    ? overallLevel?.wellnessLevel?.toFixed(1) * 10
                    : 0
                }
                showInfo={false}
              />
            </div>
          </div>
        </>
      </div>

      <div className={styles.summaryBox}>
        <Text className={styles.title}>Summary</Text>
        <Text className={styles.subtitle}>
          {overallLevel?.summary_report ?? "No Data"}
        </Text>
      </div>

      {/* {overallLevel?.summary_report ===
      "Users not participate for wellness on this month" ? (
        <></>
      ) : (
        
      )} */}
    </div>
  );
}

export default OverallResult;
