import React, { useEffect, useState } from "react";
import Text from "antd/lib/typography/Text";
import { Button, Image, Progress } from "antd";
import styles from "../../styles/dashboard.module.scss";
import BarChart from "../../components/StackedBar";
import { authSVC } from "../../services";
import moment from "moment";
import { useB2BContext } from "../../context/ContextB2B";

export default function WellnessBarchart() {
  const { getUserByID } = useB2BContext();

  const [departmentLevelWellness, setdepartmentLevelWellness] = useState<any[]>(
    []
  );

  useEffect(() => {
    const year: any = moment().format("yyyy");
    if (getUserByID) {
      authSVC
        .getbarChartWellnessLevels(getUserByID?.company, Number(year))
        .then((res) => {
          setdepartmentLevelWellness(res?.data?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [getUserByID]);

  return (
    <div className={styles.white_bg_wrapper}>
      <div className={styles.info_title}>
        <div className={styles.title_grp}>
          <Text className={styles.title}>Wellness Index</Text>
        </div>
        <div className={styles.title_grp1}>
          {/* <Image
            className={styles.img}
            src="/img/new/download_icon.svg"
            preview={false}
          /> */}
          {/* <Text className={styles.subtitle1}>21% from last month</Text> */}
        </div>
        <div className={styles.graph}>
          <BarChart chartData={departmentLevelWellness} />
        </div>
      </div>
    </div>
  );
}
