import React, { useEffect, useState } from "react";
import {
  Button,
  Typography,
  Modal,
  Input,
  message,
  ColorPicker,
  Tooltip,
} from "antd";
import { authSVC } from "../../services";
import DataTableDepartments from "../../components/dataTable/DataTableDepartments";
import { useB2BContext } from "../../context/ContextB2B";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import useSWR from "swr";
import * as Cookie from "universal-cookie";
const { Text } = Typography;

const cookie = new Cookie.default();
const cachedToken = cookie.get("userAuthTokenAdmin");

interface Contentdata {
  key?: string;
  id: number;
  name?: string;
  color?: string;
}

// type Color = GetProp<ColorPickerProps, 'value'>;
// type Format = GetProp<ColorPickerProps, 'format'>;

const fetcher = (url: any) =>
  axios
    .get(url, {
      headers: {
        Authorization: `Bearer ${cachedToken}`,
        "Content-Type": "application/json",
      },
    })
    .then((res) => res?.data?.data);

function Departments() {
  const { getUserByID } = useB2BContext();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const API_PATH = process.env.REACT_APP_API_URL;

  const { data: allDepartments, mutate } = useSWR(
    API_PATH + `/admin/${getUserByID?.company}/departments`,
    fetcher,
    {
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
    }
  );

  const removeDepartments = (departmentNames: any) => {
    authSVC
      .removeDepartment(getUserByID?.company, departmentNames)
      .then((res: any) => {
        message.success("Department Deleted");
        mutate();
      })
      .catch((err: any) => {
        message.error(err.message);
      });
  };

  return (
    <div className="contentBox">
      <div className="info">
        {getUserByID ? (
          <Button
            disabled={getUserByID?.is_payment === false ? true : false}
            style={{ width: "max-content" }}
            onClick={() => navigate("/adddepartments")}
            className="cn_btn"
          >
            Add Department
          </Button>
        ) : (
          <></>
        )}
      </div>
      <DataTableDepartments
        deleteDepartment={removeDepartments}
        setshowModel={setIsModalOpen}
        isOpen={isModalOpen}
        contentData={allDepartments}
      />
    </div>
  );
}

export default Departments;
