import React, { useEffect, useState } from "react";
import { useB2BContext } from "../../../context/ContextB2B";

interface ModuleItem {
  _id: string;
  moduleName: string;
  label: string;
  code: string;
  allowAccess: boolean;
  is_hidden?: boolean;
}

interface ModuleGroup {
  moduleName: string;
  moduleItem: ModuleItem[];
}

interface Props {
  preSelectPackages?: string[];
  modules: ModuleGroup[];
}

const CheckboxList: React.FC<Props> = ({ preSelectPackages = [], modules }) => {
  const { setSelectedModulesList } = useB2BContext();
  const [selectedPackages, setSelectedPackages] =
    useState<string[]>(preSelectPackages);

  const handleCheckboxChange = (id: string) => {
    console.log(id);
    setSelectedPackages((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((pkgId) => pkgId !== id)
        : [...prevSelected, id]
    );
  };

  useEffect(() => {
    setSelectedPackages(preSelectPackages);
  }, [preSelectPackages]);

  useEffect(() => {
    if (selectedPackages) {
      setSelectedModulesList(selectedPackages);
    }
  }, [selectedPackages]);

  // useEffect(() => {
  //   console.log(selectedPackages, "selectedPackages");
  //   console.log(preSelectPackages, "preSelectPackages");
  //   console.log(modules, "modules");
  // }, [selectedPackages, modules, preSelectPackages]);

  return (
    <div className="checkbox_list_wrapper">
      {modules.map((moduleGroup, index) => (
        <div key={index}>
          <h3>{moduleGroup.moduleName}</h3>
          <ul>
            {moduleGroup.moduleItem.map((item) => (
              <li key={item._id}>
                <label>{item.label}</label>
                <input
                  type="checkbox"
                  checked={selectedPackages.includes(item._id)}
                  onChange={() => handleCheckboxChange(item._id)}
                  disabled={!item.allowAccess}
                />
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default CheckboxList;
