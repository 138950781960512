import { AxiosPromise } from 'axios';

const API_PATH = process.env.REACT_APP_API_URL;


type API = {
  post: (url: string, payload: any) => AxiosPromise
  put: (url: string, payload: any) => AxiosPromise
  get: (url: string) => AxiosPromise
  delete: (id: string) => AxiosPromise
}

const articles = (api: API) => {

 
  // themeposts post
  const addThemeposts = (payload: any) => api.post(API_PATH + '/themeposts/', payload)
  const getThemeposts = () => api.get(API_PATH + '/themeposts/')
  const deleteThemeposts = (id: string) => api.delete(API_PATH + `/themeposts/${id}`)
  const editThemeposts = (id: string) => api.get(API_PATH + `/themeposts/${id}`)
  const updateThemeposts = (id: string, payload: any) => api.put(API_PATH + `/themeposts/${id}`, payload)

   
  // themes
  const addthemes = (payload: any) => api.post(API_PATH + '/themes/', payload)
  const getthemes = () => api.get(API_PATH + '/themes/')
  const deletethemes = (id: string) => api.delete(API_PATH + `/themes/${id}`)
  const editthemes = (id: string) => api.get(API_PATH + `/themes/${id}`)
  const updatethemes = (id: string, payload: any) => api.put(API_PATH + `/themes/${id}`, payload)


  return {
    addThemeposts,
    getThemeposts,
    deleteThemeposts,
    editThemeposts,
    updateThemeposts,
    addthemes,
    getthemes,
    deletethemes,
    editthemes,
    updatethemes
  }
}

export default articles
