import { AxiosPromise } from 'axios';

const API_PATH = "https://soulsara.shop"

type API = {
  post: (url: string, payload: any) => AxiosPromise
  put: (url: string, payload: any) => AxiosPromise
  get: (url: string) => AxiosPromise
  delete: (id: string) => AxiosPromise
}

const Products = (api: API) => {

  const getAllProducts = () => api.get(API_PATH + '/api/v1/admin/admin-get-products-for-meta')
  const updateMetaData = (payload: any) => api.put(API_PATH + '/api/v1/admin/update-meta-data', payload)
  // const getThemeposts = () => api.get(API_PATH + '/api/v1/allblog/')
  // const deleteThemeposts = (id: string) => api.delete(API_PATH + `/api/v1/deleteblogbyid/${id}`)
  // const editThemeposts = (id: string) => api.get(API_PATH + `/api/v1/blogbyid/${id}`)

  return {
    getAllProducts,
    updateMetaData
    // addThemeposts,
    // deleteThemeposts,
    // editThemeposts,
    // updateThemeposts,
  }
}

export default Products
