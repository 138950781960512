import { AxiosPromise } from 'axios';

const API_PATH = process.env.REACT_APP_API_URL;

type API = {
  post: (url: string, payload: any) => AxiosPromise
  put: (url: string, payload: any) => AxiosPromise
  get: (url: string) => AxiosPromise
  delete: (id: string) => AxiosPromise
}

const comments = (api: API) => {

   const getComments = () => api.get(API_PATH + '/api/comment')
   const deleteComments = (vid: string, cid:string) => api.delete(API_PATH + `/api/comment/fromVideo/${vid}/${cid}`)
 

  return {
    getComments,
    deleteComments
  }
}

export default comments
